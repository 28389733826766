import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  FormControl,
  Grid,
  Typography,
  Card,
  CardContent,
  InputLabel
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import { Redirect } from "react-router";
import { makeStyles } from "@material-ui/core/styles";
import {
  patchFormaciones,
} from "../../../../helpers/formacionHelper/formacionHelper";
import { fileHelperText, Programas } from "../../../../constants/AppConstants";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Autocomplete } from "@material-ui/lab";
const MySwal = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: "5px",
    marginTop: "20px",
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: "40px",
    marginTop: "5px",
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  textFieldRoot: {
    width: "90%",
    height: "15%",
  },
  color: {
    color: 'red'
  }
}));

const IngresarFormacion = (props, { variant = "default" }) => {
  const fecha = new Date();
  const fechaActual = fecha.toISOString().split("T")[0];
  const classes = useStyles({ variant });
  let urlFormacion = {};
  let inputSwitchVar = true;
  if (props.location.state === undefined) window.location.href = "/signin";
  if (props.location.state !== undefined && props.location.state.actualizar) {
    if (props.location.state.formacion.nombre !== undefined)
      urlFormacion.nombre = props.location.state.formacion.nombre;
    else urlFormacion.nombre = "";
    if (props.location.state.formacion.centroEducativo !== undefined)
      urlFormacion.centroEducativo = props.location.state.formacion.centroEducativo;
    else urlFormacion.centroEducativo = "";
    if (props.location.state.formacion.tipo !== undefined)
      urlFormacion.tipo = props.location.state.formacion.tipo;
    else urlFormacion.tipo = "";
    if (props.location.state.formacion.fechaCulminacion !== undefined)
      urlFormacion.fechaCulminacion = props.location.state.formacion.fechaCulminacion.replaceAll("/", "-").split("-").reverse().join("-");
    else urlFormacion.fechaCulminacion = "";
    if (props.location.state.formacion.tarjeta !== undefined)
      urlFormacion.tarjeta = props.location.state.formacion.tarjeta;
    else urlFormacion.tarjeta = "";
    if (props.location.state.formacion.fechaExpedicionTarjetaProfesional !==
      undefined && props.location.state.formacion.fechaExpedicionTarjetaProfesional !== null)
      urlFormacion.fechaExpedicionTarjetaProfesional = props.location.state.formacion.fechaExpedicionTarjetaProfesional.replaceAll("/", "-").split("-").reverse().join("-");
    else urlFormacion.fechaExpedicionTarjetaProfesional = "";
    if (props.location.state !== undefined && props.location.state.actualizar && !props.location.state.formacion.tarjeta) {
      inputSwitchVar = props.location.state.formacion.tarjeta
    }
  } else {
    urlFormacion = {
      nombre: "",
      centroEducativo: "",
      fechaCulminacion: "",
      tipo: "",
      tarjeta: false,
      fechaExpedicionTarjetaProfesional: "",
    };
  }
  const tarjProf = urlFormacion.tipo === "Pregrado" ? true : false;
  const [inputSwitch, setInputSwitch] = React.useState(inputSwitchVar);
  const [tarjetaProfesional, setTarjetaProfesional] = useState(urlFormacion.tipo === 'Pregrado' ? true : false);
  const [formacion, setFormacion] = useState(urlFormacion);

  const [error, setError] = useState({
    nombre: false,
    nombreHelper: "",
    tipo: false,
    tipoHelper: "",
    centroEducativo: false,
    centroEducativoHelper: "",
    fechaCulminacion: false,
    fechaCulminacionHelper: "",
    diploma: false,
    diplomaHelper: fileHelperText,
    tarjetaProfesional: false,
    tarjetaProfesionalHelper: fileHelperText,
    certificado: false,
    certificadoHelper: fileHelperText,
    fechaExpedicionTarjetaProfesional: false,
    fechaExpedicionTarjetaProfesionalHelper: "",
  });
  const [fileDiploma, setFileDiploma] = useState('');
  const [fileTarjetaProfesional, setFileTarjetaProfesional] = useState('');
  const [fileCertificado, setFileCertificado] = useState('');
  const [redirect, setRedirect] = useState("");
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormacion({
      ...formacion,
      [name]: value,
    });
    if (name === "tipo") {
      if (value === "Pregrado") {
        if (inputSwitch) {
          setError({
            ...error,
            tarjetaProfesional: false,
            tarjetaProfesionalHelper: fileHelperText,
            fechaExpedicionTarjetaProfesional: false,
            fechaExpedicionTarjetaProfesionalHelper: "",
            certificado: false,
            certificadoHelper: fileHelperText,
          });
          setTarjetaProfesional(true);
        } else {
          setFileCertificado('');
          setFileTarjetaProfesional('');
          setTarjetaProfesional(false);
        }
      } else {
        setFileCertificado('');
        setFileTarjetaProfesional('');
        setTarjetaProfesional(false);
      }
      setFormacion({
        ...formacion,
        tarjeta: false,
        fechaExpedicionTarjetaProfesional: "",
        [name]: value,
      });
    }
  };

  const handleSwitch = (event) => {
    setInputSwitch(event.target.checked);
  }

  useEffect(() => {
    if (inputSwitch) {
      setFileTarjetaProfesional("")
      setFileCertificado("")
    }else{
        setFormacion({...formacion, tarjeta:false})
    }
    // eslint-disable-next-line
  },[inputSwitch]);

  const OnSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    let formacionQuery = Object.assign({}, formacion);
    if (!inputSwitch) {
      formacionQuery.tarjeta = false
      formacionQuery.fechaExpedicionTarjetaProfesional = "";
    };
    if (props.location.state.actualizar)
      formacionQuery.id = props.location.state.formacion.id;
    formacionQuery.tipo = formacion.tipo.toUpperCase();
    formData.append('formacion', new Blob([JSON.stringify(formacionQuery)], { type: "application/json" }));

    if (fileDiploma !== '') formData.append('diploma', fileDiploma);
    if (fileTarjetaProfesional !== '') formData.append('tarjetaProfesional', fileTarjetaProfesional);
    if (fileCertificado !== '') formData.append('certificado', fileCertificado);

    await patchFormaciones(formData, formacionQuery.id)
      .then(function (response) {
        setRedirect(true);
      })
      .catch(function (response) {
        if (response.response.status === 401) {
          MySwal.fire({
            icon: "error",
            title: "Error de autenticación",
            text: "inicia sesión y vuelve a intentarlo",
          }).then((result) => {
            window.location.pathname = "/signin";
          });
        }
      });
  };
  const defaultAutoComplete = () => {
    const Nombres = (nombre) => nombre === formacion.nombre;
    return Programas[Programas.findIndex(Nombres)];
  };
  return (
    <Card sx={{ minWidth: 275 }}>
      <CardContent>
        <div className={classes.centerItems}>
          {redirect ? (
            <Redirect
              to={{
                pathname: "/postulacion/update",
                state: { props: props.location.state.candidato },
              }}
            />
          ) : null}
          <React.Fragment>
            <Typography variant="h1" color="textPrimary" className={classes.subtitle}>
              Editar formación
            </Typography>
            <Typography variant="body2" className={classes.subtitleObligatorio}>
              Los campos con <span className={classes.color}>*</span> son obligatorios
            </Typography>
            <form onSubmit={(e) => {
              e.preventDefault();
              if (
                formacion.nombre !== "" &&
                formacion.centroEducativo !== "" &&
                formacion.fechaCulminacion !== "" &&
                formacion.tipo !== "" &&
                formacion.tipo !== "Pregrado" &&
                !tarjProf &&
                !tarjetaProfesional
              ) {
                if (
                  !error.nombre &&
                  !error.centroEducativo &&
                  !error.fechaCulminacion &&
                  !error.tipo &&
                  !error.diploma
                ) {
                  OnSubmit(e);
                }
              }
              if (
                formacion.nombre !== "" &&
                formacion.centroEducativo !== "" &&
                formacion.fechaCulminacion !== "" &&
                formacion.tipo === "Pregrado" &&
                tarjProf &&
                !inputSwitch
              ) {
                if (
                  !error.nombre &&
                  !error.centroEducativo &&
                  !error.fechaCulminacion &&
                  !error.tipo &&
                  !error.tarjetaProfesional &&
                  !error.diploma &&
                  !error.fechaExpedicionTarjetaProfesional &&
                  !error.certificado
                ) {
                  OnSubmit(e);
                }
              }

              if (formacion.nombre !== '' && formacion.centroEducativo !== '' && formacion.fechaCulminacion !== ''
                && formacion.tipo === 'Pregrado' && inputSwitch && formacion.fechaExpedicionTarjetaProfesional !== ''
                && fileDiploma !== '' && fileCertificado !== '' && fileTarjetaProfesional !== '' && tarjProf) {
                if (!error.nombre && !error.centroEducativo && !error.fechaCulminacion
                  && !error.tipo && !error.tarjetaProfesional && !error.diploma
                  && !error.fechaExpedicionTarjetaProfesional && !error.certificado) {
                  OnSubmit(e)
                }
              }

              if (formacion.nombre === "") {
                setError({
                  ...error,
                  nombre: true,
                  nombreHelper: " Este campo es requerido",
                });
              }
              if (formacion.centroEducativo === "") {
                setError({
                  ...error,
                  centroEducativo: true,
                  centroEducativoHelper: " Este campo es requerido",
                });
              }
              if (fileDiploma === '') {
                setError({
                  ...error,
                  diploma: true,
                  diplomaHelper: 'Este campo es requerido'
                });
              }
              if (formacion.fechaCulminacion === "") {
                setError({
                  ...error,
                  fechaCulminacion: true,
                  fechaCulminacionHelper: " Este campo es requerido",
                });
              }
              if (fileTarjetaProfesional === '' && inputSwitch) {
                setError({
                  ...error,
                  fechaExpedicionTarjetaProfesional: true,
                  fechaExpedicionTarjetaProfesionalHelper: 'Este campo es requerido'
                });
              }
              if (fileCertificado === '' && inputSwitch) {
                setError({
                  ...error,
                  certificado: true,
                  certificadoHelper: 'Este campo es requerido'
                });
              }


              if (
                formacion.fechaExpedicionTarjetaProfesional === "" &&
                tarjProf
              ) {
                setError({
                  ...error,
                  fechaExpedicionTarjetaProfesional: true,
                  fechaExpedicionTarjetaProfesionalHelper:
                    " Este campo es requerido",
                });
              }
              if (
                formacion.nombre === "" &&
                formacion.centroEducativo === "" &&
                formacion.fechaCulminacion === "" &&
                formacion.tipo === "Pregrado"
              ) {
                setError({
                  ...error,
                  nombre: true,
                  nombreHelper: "Este campo es requerido",
                  centroEducativo: true,
                  centroEducativoHelper: "Este campo es requerido",
                  fechaCulminacion: true,
                  fechaCulminacionHelper: "Este campo es requerido",
                });
              }
            }}
            >
              <Grid
                className={classes.marginField}
                container
                item xs={12}
                spacing={3}
              >
                <Grid item xs={5} className={classes.left}>
                  <Typography variant="body1" className={classes.typography}>
                    Tipo de Formación<span className={classes.color}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <FormControl
                    onBlur={(event) => {
                      if (event.target.value === "") {
                        setError({
                          ...error,
                          tipo: true,
                          tipoHelper: " Este campo es requerido",
                        });
                      } else setError({ ...error, tipo: false, tipoHelper: "" });
                    }}
                    error={error.tipo}
                    defaultValue={formacion.tipo}
                    variant="outlined"
                    className={classes.textFieldRoot}
                  >
                    <TextField
                      disabled
                      fullWidth
                      value={formacion.tipo}
                      variant="outlined"
                      className={classes.inputPading}
                    />
                    <Typography
                      className={classes.errorText}
                      variant="caption"
                      color="error"
                    >
                      {" "}
                      {error.tipoHelper}{" "}
                    </Typography>
                  </FormControl>
                </Grid>
              </Grid>
              { urlFormacion.tipo !== "Bachiller" ? (
              <Grid
                className={classes.marginField}
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={5} className={classes.left}>
                  <Typography variant="body1" className={classes.typography}>
                    Nombre del programa<span className={classes.color}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  {formacion.tipo === "Pregrado" ? (
                    <FormControl
                      required
                      variant="outlined"
                      className={classes.textFieldRoot}
                      error={error.nombrePrograma}
                    >
                      <Autocomplete
                        options={Programas}
                        defaultValue={defaultAutoComplete() || ""}
                        onChange={(e, newValue) => {
                          var event = {
                            value: newValue,
                            name: "nombre",
                          };
                          handleChange({ target: event });
                        }}
                        onBlur={async (event) => {
                          if (event.target.value === "") {
                            setError({
                              ...error,
                              nombre: true,
                              nombreHelper: " Este campo es requerido",
                            });
                          } else {
                            if (event.target.value.length > 81) {
                              setError({
                                ...error,
                                nombre: true,
                                nombreHelper: " Máximo 81 caracteres",
                              });
                            } else
                              setError({ ...error, nombre: false, nombreHelper: "" });
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            defaultValue={defaultAutoComplete()}
                            variant="outlined"
                          />
                        )}
                      />
                      <Typography
                        variant="caption"
                        color="error"
                        className={classes.Errortypography}
                      >
                        {error.nombreProgramaHT}
                      </Typography>
                    </FormControl>
                  ) : (
                    <TextField
                      onBlur={async (event) => {
                        if (event.target.value === "") {
                          setError({
                            ...error,
                            nombre: true,
                            nombreHelper: " Este campo es requerido",
                          });
                        } else if (event.target.value.length > 81) {
                          setError({
                            ...error,
                            nombre: true,
                            nombreHelper: " Máximo 81 caracteres",
                          });
                        } else {
                          setError({ ...error, nombre: false, nombreHelper: "" });
                        }
                      }}
                      error={error.nombre}
                      helperText={error.nombreHelper}
                      defaultValue={formacion.nombre}
                      onChange={handleChange}
                      name="nombre"
                      variant="outlined"
                      className={classes.textFieldRoot}
                      id="nombreFormacion"
                      inputProps={{ maxLength: 30 }}
                    />
                  )}
                </Grid>
              </Grid>
              ) : (
                <></>
              )}
              <Grid
                className={classes.marginField}
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={5} className={classes.left}>
                  <Typography variant="body1" className={classes.typography}>
                    Centro educativo<span className={classes.color}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    onBlur={async (event) => {
                      if (event.target.value === "") {
                        setError({
                          ...error,
                          centroEducativo: true,
                          centroEducativoHelper: " Este campo es requerido",
                        });
                      } else if (event.target.value.length >= 40) {
                        setError({
                          ...error,
                          centroEducativo: true,
                          centroEducativoHelper: " Máximo 40 caracteres",
                        });
                      } else {
                        setError({
                          ...error,
                          centroEducativo: false,
                          centroEducativoHelper: "",
                        });
                      }
                    }}
                    error={error.centroEducativo}
                    helperText={error.centroEducativoHelper}
                    defaultValue={formacion.centroEducativo}
                    onChange={handleChange}
                    name="centroEducativo"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    id="centroEducativo"
                    inputProps={{ maxLength: 40 }}
                  />
                </Grid>
              </Grid>
              <Grid
                className={classes.marginField}
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={5} className={classes.left}>
                  <Typography variant="body1" className={classes.typography}>
                    Fecha de culminación<span className={classes.color}>*</span>
                  </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    onBlur={(event) => {
                      if (event.target.value === "") {
                        setError({
                          ...error,
                          fechaCulminacion: true,
                          fechaCulminacionHelper: " Este campo es requerido",
                        });
                      } else if (
                        Date.parse(event.target.value) > Date.parse(fechaActual)
                      ) {
                        setError({
                          ...error,
                          fechaCulminacion: true,
                          fechaCulminacionHelper:
                            " La fecha debe ser anterior a " + fechaActual,
                        });
                      } else
                        setError({
                          ...error,
                          fechaCulminacion: false,
                          fechaCulminacionHelper: "",
                        });
                    }}
                    error={error.fechaCulminacion}
                    helperText={error.fechaCulminacionHelper}
                    defaultValue={formacion.fechaCulminacion}
                    type="date"
                    onChange={handleChange}
                    name="fechaCulminacion"
                    variant="outlined"
                    className={classes.textFieldRoot}
                    InputProps={{ inputProps: { max: fechaActual } }}
                    id="fechaCulminacion"
                  />
                </Grid>
              </Grid>
              <Grid className={classes.marginField} container item xs={12} spacing={3}>
                <Grid item xs={5} className={classes.left}>
                  <Typography variant='body1' className={classes.typography}> Adjunte aquí su diploma<span className={classes.color}>*</span> </Typography>
                </Grid>
                <Grid item xs={7}>
                  <TextField
                    onBlur={event => {
                      if (props.location.state.actualizar && event.target.files[0] === undefined) {
                        setError({ ...error, diploma: true, diplomaHelper: ' Este campo es requerido' });
                      }
                      else setError({ ...error, diploma: false, diplomaHelper: fileHelperText });
                    }}
                    error={error.diploma}
                    defaultValue={fileDiploma}
                    type="file"
                    onChange={event => setFileDiploma(event.target.files[0])}
                    variant="outlined"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    className={classes.textFieldRoot}
                    id="diploma"
                    InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                    helperText={error.diplomaHelper}
                  />
                </Grid>
              </Grid>
              {tarjetaProfesional ?
                <div>
                  <Grid container item xs={12} spacing={3} className={classes.inputSwitch}>
                    <Grid item xs={5} className={classes.left}>
                      <InputLabel>Incluir información de la tarjeta profesional</InputLabel>
                    </Grid>
                    <Grid item xs={4} className={classes.center}>
                      <Grid component="label" container alignItems="center" spacing={1} className={classes.center}>
                        <Grid item>
                          No
                        </Grid>
                        <Grid item>
                          <Switch
                            checked={inputSwitch}
                            onChange={handleSwitch}
                            color="primary"
                            name="inputSwitch"
                            inputProps={{ "aria.label": "primary checkbox" }}
                            label="normal"
                          />
                        </Grid>
                        <Grid item>Sí</Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </div>
                : null
              }
              {tarjetaProfesional && inputSwitch ?
                <div>
                  <Grid
                    className={classes.marginField}
                    container
                    item
                    xs={12}
                    spacing={3}
                  >
                    <Grid item xs={5} className={classes.left}>
                      <Typography variant="body1" className={classes.typography}>
                        Expedición de tarjeta profesional
                      </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        type="date"
                        onBlur={(event) => {
                          if (event.target.value === "" && inputSwitch) {
                            setError({
                              ...error,
                              fechaExpedicionTarjetaProfesional: true,
                              fechaExpedicionTarjetaProfesionalHelper:
                                " Este campo es requerido",
                            });
                          } else if (
                            Date.parse(event.target.value) > Date.parse(fechaActual)
                          ) {
                            setError({
                              ...error,
                              fechaExpedicionTarjetaProfesional: true,
                              fechaExpedicionTarjetaProfesionalHelper:
                                " La fecha debe ser anterior a " + fechaActual,
                            });
                          } else
                            setError({
                              ...error,
                              fechaExpedicionTarjetaProfesional: false,
                              fechaExpedicionTarjetaProfesionalHelper: "",
                            });
                        }}
                        error={error.fechaExpedicionTarjetaProfesional}
                        helperText={error.fechaExpedicionTarjetaProfesionalHelper}
                        defaultValue={formacion.fechaExpedicionTarjetaProfesional}
                        onChange={handleChange}
                        name="fechaExpedicionTarjetaProfesional"
                        variant="outlined"
                        className={classes.textFieldRoot}
                        id="fechaTarjeta"
                        InputProps={{ inputProps: { max: fechaActual } }}
                      />
                    </Grid>
                  </Grid>
                  <Grid className={classes.marginField} container item xs={12} spacing={3}>
                    <Grid item xs={5} className={classes.left}>
                      <Typography variant='body1' className={classes.typography}>Adjunte la tarjeta profesional</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        onBlur={event => {
                          if (props.location.state.actualizar && event.target.files[0] === undefined) {
                            setError({ ...error, tarjetaProfesional: true, tarjetaProfesionalHelper: 'Este campo es requeriod' })
                          }
                          else setError({ ...error, tarjetaProfesional: false, tarjetaProfesionalHelper: fileHelperText })
                        }}
                        error={error.tarjetaProfesional}
                        type="file"
                        defaultValue=""
                        onChange={async event => {
                          if (fileCertificado !== "") {
                            setFormacion({ ...formacion, tarjeta: true })
                          }
                          setFileTarjetaProfesional(event.target.files[0])
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.textFieldRoot}
                        id="tarjetaProfesional"
                        InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                        helperText={error.tarjetaProfesionalHelper}
                      />
                  </Grid>       
                  </Grid>
                  <Grid className={classes.marginField} container item xs={12} spacing={3}>
                    <Grid item xs={5} className={classes.left}>
                      <Typography variant="body1" className={classes.typography}> Adjunte el certificado de tarjeta profesional</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <TextField
                        onBlur={event => {
                          if (props.location.state.actualizar && event.target.files[0] === undefined) {
                            setError({ ...error, certificado: true, certificadoHelper: ' Este campo es requerido' });
                          }
                          else setError({ ...error, certificado: false, certificadoHelper: fileHelperText });
                        }}
                        error={error.certificado}
                        type="file"
                        dafaultValue=""
                        onChange={async event => {
                          if (fileTarjetaProfesional !== "") {
                            setFormacion({ ...formacion, tarjeta: true })
                          }
                          setFileCertificado(event.target.files[0])
                        }}
                        variant="outlined"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className={classes.textFieldRoot}
                        id="certificado"
                        InputProps={{ inputProps: { accept: "image/jpeg,application/pdf" } }}
                        helperText={error.certificadoHelper}
                      />
                    </Grid>
                  </Grid>
                </div>
                : null}
              <Grid container item xs={12} spacing={3}>
                <Grid item xs={6} className={classes.button}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => setRedirect(true)}
                  >
                    Cancelar
                  </Button>
                </Grid>
                <Grid item xs={6} className={classes.button}>
                  {props.location.state.actualizar ? (
                    <Button type="submit" variant="contained" color="primary">
                      Actualizar Formación
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      Crear Formación
                    </Button>
                  )}
                </Grid>
              </Grid>
            </form>
          </React.Fragment>
        </div>
      </CardContent>
    </Card>    
  );
};

export default IngresarFormacion;
