import axios from 'axios'
import { BASE_URL } from '../baseUrl'
import { getToken } from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json'


export const downloadDocumentsCandidato = async (numeroDocumento, fileName) => {
    const config = {
        method: 'get',
        params: {
            numeroDocumento,
            fileName
        },
        url: BASE_URL + `download_documentos_candidato`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,

    }

    return await axios(config)
}

export const getDocumentoPreempleado = async ( idPostulacion, numeroDocumento, fileName) => {

    const config = {
        method: 'get',
        params: {
            numeroDocumento,
            fileName,
            idPostulacion
        },
        url: BASE_URL + `download_documentos_preempleado`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,

    }

    return await axios(config)
}


export const getDocumentosExperienciaPreempleado = async (numeroDocumento) => {

    const config = {
        method: 'GET',
        params: {
            numeroDocumento
        },
        url: BASE_URL + `download_all_experience`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }

    return await axios(config)

}

export const getFilesExtension = async (numeroIdentificacion) => {

    const config = {
        method: 'GET',
        params: {
            numeroIdentificacion
        },
        url: BASE_URL + `list_extensions`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }

    return await axios(config)

}


export const getSignedContractFilesExtensions = async (idContrato) => {

    const config = {
        method: 'GET',
        params: {
            idContrato
        },
        url: BASE_URL + `list_signed_contract_files_extensions`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }

    return await axios(config)

}


export const getSignedContractFiles = async (idContrato) => {

    const config = {
        method: 'GET',
        params: {
            idContrato
        },
        url: BASE_URL + `download_signed_contract_files`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }

    return await axios(config)

}