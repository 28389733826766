import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
/// For the CandidatosSearchTable component
import { useTable, useRowSelect, usePagination } from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Select from '@material-ui/core/Select';
import Input from '@material-ui/core/Input';
import Card from '@material-ui/core/Card';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { MenuItem, Modal, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { downloadDocumentsCandidato } from '../../../../helpers/fileHelper/downloadHelper'
import {dateFormat} from '../../../../constants/AppConstants'
import { getDocumentosExperienciaPreempleado, getFilesExtension } from '../../../../helpers/fileHelper/downloadHelper'
import JSZip from 'jszip';
import { saveAs } from 'file-saver'
import { Tooltip } from '@mui/material';
import { FORMACION_URL } from '../../../../helpers/formacionUrl';
import { EXPERIENCIA_URL } from '../../../../helpers/experienciaUrl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'

const useStyles = makeStyles(theme => ({
    internalCard: {
        margin: 25,
        padding: 15,
        overflow:'none',
    },
    centralButton: {
        margin: 15,
    },
    button:{
        justifyContent: 'right',
    },
    hoverPointer: {
        cursor: "pointer",
        color: "#0000EE"
    },
    notDataText: {
        margin: 15,
    },
    table: {
        minWidth: 700,
    },
    cell: {
        maxWidth: 120,
        overflowX: 'hidden',
        textOverflow: 'ellipsis'
    },
    cell2: {
        maxWidth: 100,
        overflowX: 'auto'
    },
    formText: {
        margin: 15,
        paddingTop: 5
    },
    inputPading: {
        padding: 5,
    },
    modal: {
        position: "absolute",
        width: "90%",
        height: "85%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        overflow: "auto",
    },
}));

// Define a default UI for filtering
const IndeterminateCheckboxAvaiable = React.forwardRef(
    ({ indeterminate, ...rest }, ref) => {
        const defaultRef = React.useRef()
        const resolvedRef = ref || defaultRef

        React.useEffect(() => {
            resolvedRef.current.indeterminate = indeterminate
        }, [resolvedRef, indeterminate])


        return (
            <div>
                <input type="checkbox" ref={resolvedRef} {...rest} />
            </div>
        )
    }
)

const VerDetalleCandidato = (row, modalOpen) => {
    const classes = useStyles();
    return (
        <div>
            <Typography 
                variant='inherit'
                color="primary"
                className={classes.hoverPointer} onClick={() => { modalOpen(row.original) }}
            >
                Ver detalle
            </Typography>
        </div>
    )
}
function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}


const Table = ({ columns, data, modalOpen2, editable = false, candidatosQuery, initialState, selectedCandidatos, setSelectedCandidatos }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false)
    const [modalStyle] = React.useState(getModalStyle);
    const [candidatoModal, setCandidatoModal] = React.useState({
        nombre: '',
        fechaNacimiento: '',
        tipoDocumento: '',
        numeroDocumento: '',
        direccion: '',
        ciudad: '',
        rh: '',
        celular: '',
        correo: '',
        formaciones: [],
        experiencias: [],
    })

    const AgregarCandidato = async (row, selectedCandidatosAgregar, setSelectedCandidatosAgregar) => {
        let repetido = false
        for (const i in selectedCandidatosAgregar) {
            if (selectedCandidatosAgregar[i].id === row.original.id)
                repetido = true
        }
        let candidatos = selectedCandidatosAgregar
        if (!repetido) {
            candidatos.push(row.original)
            await setSelectedCandidatosAgregar(candidatos)
        }
    }
    const EliminarCandidato = async (row, selectedCandidatosEliminar, setSelectedCandidatosEliminar) => {
        let existe = false; let index = 0
        for (const i in selectedCandidatosEliminar) {
            if (selectedCandidatosEliminar[i].id === row.original.id) {
                existe = true
                index = i
            }
        }
        let candidatos = selectedCandidatosEliminar
        if (existe) {
            candidatos.splice(index, 1)
            await setSelectedCandidatosEliminar(candidatos)
        }
    }
    const handleClose = () => {
        setOpen(false)
    }

    const modalOpen = (candidato) => {
        setCandidatoModal({
            ...candidatoModal,
            nombre: candidato.nombres + ' ' + candidato.apellidos,
            fechaNacimiento: candidato.fechaNacimiento,
            tipoDocumento: candidato.tipoDocumento,
            formaciones: candidato.formaciones,
            experiencias: candidato.experiencias,
            numeroDocumento: candidato.numeroDocumento,
            ciudad: candidato.ciudad,
            direccion: candidato.direccion,
            rh: candidato.rh,
            celular: candidato.celular,
            correo: candidato.correo,
        })
        setOpen(true)
    }
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        page,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            initialState: { pageSize: 10 }
        },
        usePagination,
        useRowSelect,
        hooks => {
            hooks.visibleColumns.push(columnsHooked => [
                {
                    id: 'selection',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div></div>
                    ),
                    Cell: ({ row }) => (
                        editable ? (
                            <div>
                                <IndeterminateCheckboxAvaiable {...row.getToggleRowSelectedProps()} onClick={(e) => {
                                    if (e.target.checked) AgregarCandidato(row, selectedCandidatos, setSelectedCandidatos);
                                    else EliminarCandidato(row, selectedCandidatos, setSelectedCandidatos)
                                }} />
                            </div>) : (null)
                    ),
                },
                ...columnsHooked,
                {
                    id: 'more',
                    Header: ({ getToggleAllPageRowsSelectedProps }) => (
                        <div>
                            <Typography variant="subtitle2" > Detalle </Typography>
                        </div>
                    ),
                    Cell: ({ row }) => (
                        <div>
                            {VerDetalleCandidato(row, modalOpen)}
                        </div>
                    ),
                },
            ])
        }
    )


    const handleDownload = (filename) => {
        const MySwal = withReactContent(Swal);
        downloadDocumentsCandidato(candidatoModal.numeroDocumento, filename)
            .then(res => res.data)
            .then(fileBits => {
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.setAttribute("type", "hidden");
                link.href = "data:text/plain;base64," + fileBits;
                link.download = filename;
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => MySwal.fire(
                'Error',
                'Ocurrió un error generando la consulta',
                'error'
            ))
    }

    const handleZip = () => {
        const MySwal = withReactContent(Swal);
        const zip = new JSZip();
        let variable = [];
        getFilesExtension(candidatoModal.numeroDocumento)
            .then(res => res.data)
            .then(file => {
                variable.push(file);
            })
        getDocumentosExperienciaPreempleado(candidatoModal.numeroDocumento)
            .then(res => res.data)
            .then(fileBits => {
                if (fileBits.length === 0) {
                    MySwal.fire('Error','No existen experiencias a descargar','error')
                } else {
                    for (let file = 0; file < fileBits.length && file <= variable[0].length; file++) {
                        var experiencias = zip.folder("Experiencias");
                        experiencias.file(candidatoModal.numeroDocumento + "Experiencia" + (file + 1) + variable.map(list => list[file]), fileBits[file], { base64: true })
                    }
                    zip.generateAsync({ type: "blob" })
                        .then(function (content) {
                            // see FileSaver.js
                            saveAs(content, "Experiencias.zip");
                        });
            }}) 
            
            .catch(err => MySwal.fire(
                'Error',
                'Ocurrió un error generando la consulta',
                'error'
            ))
    }

    const TablaCandidatoFormaciones = () => {
        return candidatoModal.formaciones.length > 0 ?
            candidatoModal.formaciones.map((item) => {
                return (
                    <TableRow key={item.id} className={classes.internalCard}>
                        <TableCell className={classes.cell}> {item.tipo}</TableCell>
                        <TableCell className={classes.cell}> {item.nombre}</TableCell>
                        <TableCell className={classes.cell}> {item.centroEducativo}</TableCell>
                        <TableCell className={classes.cell}> {dateFormat(item.fechaCulminacion)}</TableCell>
                        <TableCell className={classes.cell}>
                            {item.tarjetaProfesional ? dateFormat(item.fechaExpedicionTarjetaProfesional) : "---"}
                        </TableCell>
                        <TableCell>
                            <Accordion>
                                <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                    id="panel1a-header"
                                >
                                    <Typography className={classes.heading}>Descargar</Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <div>
                                        <Typography variant="h4" align="left" color="textPrimary">Diploma</Typography>
                                        <Button variant="contained" size='small' color="secondary"
                                            onClick={() => handleDownload(FORMACION_URL + item.diploma)}
                                            className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                            {item.tarjeta === true ?
                                            <div>
                                                <Typography variant="h4" align="left" color="textPrimary">Tarjeta profesional</Typography>
                                                <Button variant="contained" size='small' color="secondary"
                                                    onClick={() => handleDownload(FORMACION_URL + item.tarjetaProfesional)}
                                                    className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                                <Typography variant="h4" align="left" color="textPrimary">Certificado de vigencia</Typography>
                                                <Button variant="contained" size='small' color="secondary"
                                                    onClick={() => handleDownload(FORMACION_URL + item.certificadoVigenciaTarjetaProfesional)}
                                                    className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                            </div>
                                            : null}
                                    </div>
                                </AccordionDetails>
                            </Accordion>
                        </TableCell>
                    </TableRow>
                )
            })
            : null
    }

    const TablaCandidatoExperiencias = () => {
        return candidatoModal.experiencias.length > 0 ?
            candidatoModal.experiencias.map((item) => {
                return (
                    <TableRow key={item.id} className={classes.internalCard}>
                        <TableCell className={classes.cell}> {item.empresa}</TableCell>
                        <TableCell className={classes.cell}> {item.area}</TableCell>
                        <TableCell className={classes.cell}> {item.cargo}</TableCell>
                        <TableCell className={classes.cell}> {item.descripcion}</TableCell>
                        <TableCell className={classes.cell}> {dateFormat(item.fechaInicio)}</TableCell>
                        <TableCell className={classes.cell}> {dateFormat(item.fechaFinal)}</TableCell>
                        <TableCell className={classes.hoverPointer} onClick={() => handleDownload(EXPERIENCIA_URL + item.certificadoLaboral)}> Descargar </TableCell>
                    </TableRow>
                )
            })
            : null
    }
    return (
        <div>
            <Modal
                open={open}
                onClose={handleClose}
            >
                <div style={modalStyle} className={classes.modal}>
                    <Typography variant="h1" align="left" color="textPrimary">
                        {candidatoModal.nombre}
                    </Typography>
                    <Card className={classes.internalCard}>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Fecha de nacimiento
                        </Typography>  
                        <Typography variant="body2" align="left" color="textPrimary">
                            {dateFormat(candidatoModal.fechaNacimiento)}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Tipo de documento
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.tipoDocumento}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Número de documento
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.numeroDocumento}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Ciudad de residencia
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.ciudad}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Dirección
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.direccion}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            RH
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.rh}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Celular
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.celular}
                        </Typography>
                        <Typography variant="h4" align="left" color="textPrimary">
                            Correo
                        </Typography>
                        <Typography variant="body2" align="left" color="textPrimary">
                            {candidatoModal.correo}
                        </Typography>
                    </Card>
                    <Typography variant="h3" align="left" color="textPrimary">
                        Formaciones
                    </Typography>
                    <Card className={classes.internalCard}>
                        {candidatoModal.formaciones.length ?
                            <div style={{ overflow: 'auto' }}>
                                <MaUTable className={classes.table}>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell className={classes.cell}> <strong>Tipo de Formación </strong></TableCell>
                                            <TableCell className={classes.cell}> <strong>Nombre del Programa  </strong></TableCell>
                                            <TableCell className={classes.cell}> <strong>Centro educativo  </strong></TableCell>
                                            <TableCell className={classes.cell}> <strong>Fecha de culminación  </strong></TableCell>
                                            <TableCell className={classes.cell}> <strong>Fecha de expedición tarjeta profesional </strong></TableCell>
                                            <TableCell className={classes.cell}> <strong> Acciones </strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {TablaCandidatoFormaciones()}
                                    </TableBody>
                                </MaUTable>
                            </div> :
                            <Typography variant="h4" align="left" color="textPrimary">
                                El candidato no tiene formaciones registradas
                            </Typography>}
                    </Card>
                        <Tooltip title="Descargar todas las experiencias" followCursor>
                        <Typography variant="h3" align="left" color="textPrimary">
                            Experiencias <Button onClick={() => handleZip()} startIcon = {<FileDownloadOutlinedIcon/>} variant="outlined" className={classes.button} color="primary" disabled={candidatoModal.experiencias.length === 0 ? true : false}></Button>
                        </Typography>
                        </Tooltip>
                    <Card className={classes.internalCard}>
                            {candidatoModal.experiencias.length ?
                                <div style={{ overflow: 'auto' }}>
                                    <MaUTable className={classes.table}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell className={classes.cell}> <strong>Empresa  </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong>Área  </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong>Cargo  </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong>Descripción  </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong> Fecha de inicio </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong> Fecha de finalización </strong></TableCell>
                                                <TableCell className={classes.cell}> <strong> Acciones </strong></TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {TablaCandidatoExperiencias()}
                                        </TableBody>
                                    </MaUTable>
                                </div> :
                                <Typography variant="h4" align="left" color="textPrimary">
                                    El candidato no tiene experiencias registradas
                                </Typography>}
                    </Card>
                    <Grid item xs={12} align="center">
                        <Button onClick={() => handleClose()} variant="contained" color="secondary"> Cerrar</Button>
                    </Grid>
                </div>
            </Modal>
            <Card className={classes.internalCard} variant="outlined">
                <div style={{ 'overflow': 'auto' }}>
                    <MaUTable className={classes.table} {...getTableProps()} >
                        <TableHead>
                            {headerGroups.map(headerGroup => (
                                <TableRow {...headerGroup.getHeaderGroupProps()}>
                                    {headerGroup.headers.map(column => (
                                        <TableCell className={classes.cell2} {...column.getHeaderProps()}>
                                            <strong>
                                                {column.render('Header')}
                                            </strong>
                                            {/* Render the columns filter UI */}
                                            <div>{column.canFilter ? column.render('Filter') : null}</div>
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableHead>
                        <TableBody {...getTableBodyProps()}>
                            {page.map((row, i) => {
                                prepareRow(row)
                                return (
                                    <TableRow {...row.getRowProps()}>
                                        {row.cells.map(cell => {
                                            return <TableCell className={classes.cell} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
                                        })}
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </MaUTable>
                </div>
                <div className="pagination">
                    <Button onClick={() => { initialState({ page: 0, pageSize: candidatosQuery.size, filtros: candidatosQuery.filtros }) }}
                        disabled={candidatosQuery.first}>
                        {'<<'}
                    </Button>{' '}
                    <Button onClick={() => { initialState({ page: candidatosQuery.number - 1, pageSize: candidatosQuery.size, filtros: candidatosQuery.filtros }) }}
                        disabled={candidatosQuery.first}>
                        {'<'}
                    </Button>{' '}
                    <Button onClick={() => { initialState({ page: candidatosQuery.number + 1, pageSize: candidatosQuery.size, filtros: candidatosQuery.filtros }) }}
                        disabled={candidatosQuery.last}>
                        {'>'}
                    </Button>{' '}
                    <Button onClick={() => { initialState({ page: candidatosQuery.totalPages - 1, pageSize: candidatosQuery.size, filtros: candidatosQuery.filtros }) }}
                        disabled={candidatosQuery.last}>
                        {'>>'}
                    </Button>{' '}
                    <span>
                        Página{' '}
                        <strong>
                            {candidatosQuery.number + 1} de {candidatosQuery.totalPages}
                        </strong>{' '}
                    </span>
                    <span>
                        | Ir a la página:{' '}
                        <Input
                            type="number"
                            defaultValue={candidatosQuery.number + 1}
                            onChange={e => {
                                if (e.target.value < candidatosQuery.totalPages) {
                                    initialState({ page: Number(e.target.value), pageSize: candidatosQuery.size })
                                }
                            }}
                            style={{ width: '100px' }}
                        />
                    </span>{' '}
                    <Select
                        value={candidatosQuery.size}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                            initialState({ page: candidatosQuery.number, pageSize: Number(e.target.value) })
                        }}
                    >
                        {[10, 20, 30, 40, 50].map(pageSizeMaper => (
                            <MenuItem key={pageSizeMaper} value={pageSizeMaper}>
                                Mostrar {pageSizeMaper}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
            </Card>
            <Grid className={classes.formText} item xs={12} align="center">
                {editable ? <Button onClick={() => modalOpen2()} variant="contained" color="primary">
                    Vincular a oferta
                </Button> : null}
            </Grid>
        </div>
    )
}

export default Table