import React, { useState, useRef } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Button, Grid, Typography, TableContainer, Table, Paper, TableBody, TableRow, TableCell, CardContent, Card } from '@material-ui/core';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TablaFormacion from '../TablaFormacion';
import TablaExperiencia from '../TablaExperiencia';
import { Redirect } from 'react-router';
import { getCandidatoId } from '../../../../helpers/candidato/candidato'
import { dateFormat } from '../../../../constants/AppConstants';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    marginBottom: 15,
  },
  title:{
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '20px',
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  table: {
    maxWidth: "80%",
    margin: "0 auto",   
    marginBottom: 15,
  },
  tableCell: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)"
  },
  tableCellTitle:{
    fontWeight:"bold"
  },
  marginField: {
    marginBottom: 15,
  },
}));

const useSingleton = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const MostrarDatos = ({ variant = 'default', idCandidato, setCandidatoQuery, setInfo }) => {
  const classes = useStyles({ variant });
  const [candidatoObtenido, setCandidatoObtenido] = useState({})
  const [redirect, setRedirect] = useState(false)
  useSingleton(async () => {
		const infoCandidato = await getCandidatoId(idCandidato)
			.then(response => {
       let responseCandidato = response.data;
        responseCandidato.departamento = responseCandidato.ciudad.departamento;
        return responseCandidato}
        )
			.catch(() => setRedirect(true))
    if(infoCandidato !== undefined){
      setCandidatoQuery(infoCandidato)
      setCandidatoObtenido(infoCandidato)
    }
	});

  function createData (name,description){
    return {name, description};
  }
  const rows = [
    createData('Nombres', candidatoObtenido.nombres),
    createData('Apellidos', candidatoObtenido.apellidos),
    createData('Tipo de documento', candidatoObtenido.tipoDocumento),
    createData('Número de documento', candidatoObtenido.numeroDocumento),
    createData('Dirección', candidatoObtenido.direccion),
    createData('Celular', candidatoObtenido.celular),
    createData('Teléfono', candidatoObtenido.telefonoFijo),
    createData('Fecha de Nacimiento', dateFormat(candidatoObtenido.fechaNacimiento)),
    createData('Género', candidatoObtenido.genero),
    createData('RH', candidatoObtenido.rh),
    createData('Correo', candidatoObtenido.correo),
  ]

  const MySwal = withReactContent(Swal);
  const sweetAlertsProceso = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Candidato creado'
    })
  }

  return (
    <CardContent>
		  {redirect ? <Redirect push to={{ pathname:'/signin'}} /> : null}
      <Typography variant='h1'  className={classes.title}>Información del candidato</Typography>
      <Card className={classes.root}>
      <Grid container item xs={12} spacing={3} className={classes.marginField}>
        <Grid item xs={8}>
          <Typography variant='h6' className={classes.title}>Información básica</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" color="primary" size="small" className={classes.title} onClick={event => setInfo(2)}>
            Editar información
          </Button>
        </Grid>
      </Grid>
      <TableContainer component={Paper} className={classes.table}>
        <Table aria-label="simple table">
          <TableBody>
            {rows.map((row) => (
              <TableRow key={row.name}>
                <TableCell component="th" scope="row" size="small" className={classes.tableCellTitle}>
                  {row.name}:
                </TableCell>
                <TableCell align="left" size="small" className={classes.tableCell}>
                  {row.description}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      </Card>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={8}>
          <Typography variant='h6' className={classes.title}>Formación</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" color="primary" size="small" className={classes.title} onClick={event => setInfo(3)}>
            Añadir formación académica
          </Button>
        </Grid>
      </Grid>
      <TablaFormacion idCandidato={idCandidato}/>
      <Grid container item xs={12} spacing={3}>
        <Grid item xs={8}>
          <Typography variant='h6' className={classes.title}>Experiencia laboral</Typography>
        </Grid>
        <Grid item xs={4}>
          <Button variant="outlined" color="primary" size="small" className={classes.title} onClick={event => setInfo(4)}>
            Añadir experiencia laboral
          </Button>
        </Grid>
      </Grid>
      <TablaExperiencia idCandidato={idCandidato}/>
      <Grid container item xs={12} spacing={3} className={classes.button}>
        <Link to={"/admin_page"}>
          <Button variant="contained" color="primary" onClick={event => sweetAlertsProceso()}>
            Guardar información
          </Button>
        </Link>
      </Grid>
    </CardContent>
  )
}

export default MostrarDatos;