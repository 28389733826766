import React, { useState, useRef } from 'react'
import { Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Redirect } from 'react-router';
import { getCandidatoId } from '../../../../helpers/candidato/candidato'
import { dateFormat } from '../../../../constants/AppConstants';

const ExperienciasTable = (experiencias) => {
	const columns = [
		{
			field: 'empresa',
			headerName: 'Empresa',
			flex: 1,
		},
		{
			field: 'area',
			headerName: 'Área',
			flex: 1,
		},
		{
			field: 'cargo',
			headerName: 'Cargo',
			flex: 1,
		},
		{
			field: 'fechaInicio',
			headerName: 'Fecha de inicio',
			flex: 1,
			valueGetter: (params)=>{
				return dateFormat(params.row.fechaInicio);
			},
		},
		{
			field: 'fechaFinal',
			headerName: 'Fecha de culminación',
			flex: 1,
			valueGetter: (params)=>{
				return dateFormat(params.row.fechaFinal);
			},
		},
	]
	
	return (
		experiencias.length > 0
		? <DataGrid 
			rows={experiencias} 
			columns={columns} 
			pageSize={5} 
			autoHeight 
			disableColumnFilter 
			disableColumnMenu
			disableDensitySelector
			disableSelectionOnClick
			showCellRightBorder
			showColumnRightBorder
			rowsPerPageOptions={[5]}
			autoPageSize={false}
		/>
		: <Typography variant="h5" color="textPrimary"> No se encontraron experiencias </Typography>
	)
}


const useSingleton = (callBack = () => {
	//Blank function
 }) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const TablaExperiencia = ({idCandidato}) => {
  const [experiencias, setExperiencias] = useState([])
	const [redirect, setRedirect] = React.useState(false)
	useSingleton(async () => {
		const experienciaQuery = await getCandidatoId(idCandidato)
			.then(response => {
				return response.data.experiencias
			})
			.catch(err => {
				setRedirect(true)
				return err
			})
		setExperiencias(experienciaQuery)
	});
	return(
		<div>
		{redirect ? <Redirect push to={{ pathname:'/signin'}} /> : null}
		{ExperienciasTable(experiencias)}
		</div>
	)
}

export default TablaExperiencia