import React, { useState, useRef, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  alpha,
  TextField,
  Button,
  Select,
  FormControl,
  Typography,
  Grid,
  MenuItem,
  CardContent,
} from "@material-ui/core";
import Switch from '@material-ui/core/Switch';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Autocomplete } from "@material-ui/lab";
import validate from "validate.js";
import { IS_NOT_EMPTY, LATEST_DATE } from "../../../../constants/MensajeHelper";
import {
  postFormaciones,
} from "../../../../helpers/formacionHelper/formacionHelper";
import { getCandidatoId } from "../../../../helpers/candidato/candidato";
import { Programas } from "../../../../constants/AppConstants";
import moment from "moment";
import { Redirect } from "react-router";

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: "5px",
    marginTop: "20px",
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: "40px",
    marginTop: "5px",
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  center: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  textFieldRoot: {
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (callBack = () => {}) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const fechaActual = moment().subtract(1, "days").format("YYYY-MM-DD");

const mensajeHelper = {
  tipo: {
    presence: IS_NOT_EMPTY,
  },
  nombre: {
    presence: IS_NOT_EMPTY,
  },
  centroEducativo: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 40, message: "no puede sobrepasar los 40 caracteres" },
  },
  fechaCulminacion: {
    presence: IS_NOT_EMPTY,
    datetime: {
      latest: fechaActual,
      message: "tiene que ser una fecha antes de la actual",
    },
  },
  fileDiploma: {
    presence: IS_NOT_EMPTY,
  },
};

const mensajeHelperPregrado = {
  fechaExpedicionTarjetaProfesional: {
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  fileTarjetaProfesional: {
    presence: IS_NOT_EMPTY,
  },
  fileCertificado: {
    presence: IS_NOT_EMPTY,
  },
};

const tipoFormacion = [
  { value: "Bachiller", text: "Bachiller"},
  { value: "Tecnico", text: "Técnico"},
  { value: "Tecnologo", text: "Técnologo"},
  { value: "Pregrado", text: "Pregrado" },
  { value: "Especializacion", text: "Especialización" },
  { value: "Maestria", text: "Maestría" },
  { value: "Doctorado", text: "Doctorado" },
  { value: "Posdoctorado", text: "Posdoctorado" },
];

const CustomTypeSelect = ({
  titulo,
  name,
  handleChange,
  hasError,
  classes,
  ...props
}) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant="body1" className={classes.typography}>
          {titulo}<IsNotEmpty id={name} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <FormControl
          variant="outlined"
          className={classes.textFieldRoot}
          fullWidth
        >
          <Select
            onChange={handleChange}
            name={name}
            defaultValue=""
            error={hasError(name)}
          >
            <MenuItem value="" />
            {tipoFormacion.map(({ value, text }) => {
              return (
                <MenuItem key={value} value={value}>
                  {text}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const CustomNombre = ({
  titulo,
  classes,
  isPregrado,
  handleChange,
  name,
  hasError,
  formState,
}) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant="body1" className={classes.typography}>
          {titulo}<IsNotEmpty id={name} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        {isPregrado ? (
          <FormControl
            variant="outlined"
            fullWidth
            className={classes.textFieldRoot}
          >
            <Autocomplete
              options={Programas}
              onChange={(e, newValue) => {
                var event = {
                  value: newValue,
                  name: name,
                };
                handleChange({ target: event });
              }}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FormControl>
        ) : (
          <TextField
            defaultValue=""
            onChange={handleChange}
            name={name}
            fullWidth
            variant="outlined"
            className={classes.textFieldRoot}
            inputProps={{ maxLength: 81 }}
            error={hasError(name)}
            helperText={hasError(name) ? formState.errors.nombre[0] : null}
          />
        )}
      </Grid>
    </Grid>
  );
};

const CustomTextfield = ({
  classes,
  titulo,
  handleChange,
  name,
  hasError,
  formState,
  ...props
}) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant="body1" className={classes.typography}>
          {titulo}<IsNotEmpty id={name} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TextField
          type={props?.type ? props.type : ""}
          defaultValue={props?.defaultValue ? props.defaultValue : ""}
          onChange={handleChange}
          name={name}
          variant="outlined"
          fullWidth
          className={classes.textFieldRoot}
          inputProps={props?.inputProps ? props.inputProps : ""}
          InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : null}
          error={hasError(name)}
          helperText={hasError(name) ? formState.errors[name][0] : null}
        />
      </Grid>
    </Grid>
  );
};

function IsNotEmpty(id) {
  const functionIsEmpty = (param) => {
    if (param === "no debe estar vacío") {
      return <span style={style}>*</span>;
    }
  }

  const style={color: 'red'};
  let isEmpty;
  if (mensajeHelperPregrado[id.id] !== undefined ) {
    isEmpty = mensajeHelperPregrado[id.id]?.presence?.message;
    return functionIsEmpty(isEmpty);
  } else {
    isEmpty = mensajeHelper[id.id]?.presence?.message;
    return functionIsEmpty(isEmpty);
  }
}


const formStateInit = {
  isValid: false,
  values: {
    nombre: "",
    centroEducativo: "",
    fechaCulminacion: "",
    tipo: "",
    tarjeta: false,
    fileDiploma: "",
  },
  touched: {
    nombre: false,
    centroEducativo: false,
    fechaCulminacion: false,
    tipo: false,
    fileDiploma: false,
  },
  errors: {
    nombre: false,
    centroEducativo: false,
    fechaCulminacion: false,
    tipo: false,
    fileDiploma: false,
  },
}

const formStatePregradoInit = {
  isValid: false,
  values: {
    fechaExpedicionTarjetaProfesional: "",
    fileTarjetaProfesional: "",
    fileCertificado: "",
  },
  touched: {
    fechaExpedicionTarjetaProfesional: false,
    fileTarjetaProfesional: false,
    fileCertificado: false,
  },
  errors: {
    fechaExpedicionTarjetaProfesional: false,
    fileTarjetaProfesional: false,
    fileCertificado: false,
  },
}

const IngresarFormacion = ({
  variant = "default",
  idCandidato,
  candidatoQuery,
  setInfo,
}) => {
  const classes = useStyles({ variant });
  const MySwal = withReactContent(Swal);
  const [tarjProf, setTarjProf] = useState(false);
  const [infoTarjeta, setInfoTarjeta] = useState(false);
  const [inputSwitch, setInputSwitch] = React.useState(true);
  const [isPregrado, setIsPregrado] = React.useState(false);
  const [candidatoObtenido, setCandidatoObtenido] = useState({});
  const maxFecha = { max: fechaActual };
  const propFiles = { accept: "image/jpeg,application/pdf" };
  const labelFiles = { shrink: true };
  const [redirect, setRedirect] = React.useState(false);
  const [formState, setFormState] = useState(formStateInit);
  const [formStatePregrado, setFormStatePregrado] = useState(formStatePregradoInit);

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;
  const hasErrorPregrado = (field) =>
    formStatePregrado.touched[field] && formStatePregrado.errors[field]
      ? true
      : false;

  useEffect(() => {
    validate.extend(validate.validators.datetime, {
      parse: function (value, options) {
        return new Date(value).valueOf();
      },
      // Input is a unix timestamp
      format: function (value, options) {
        return new Date(value);
      },
    });

    const errors = validate(formState.values, mensajeHelper);

    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formState.values]);
  useEffect(() => {
    const errors = validate(formStatePregrado.values, mensajeHelperPregrado);

    setFormStatePregrado((formStatePregrado) => ({
      ...formStatePregrado,
      isValid: errors ? false : true,
      errors: errors || {},
    }));
  }, [formStatePregrado.values]);

  const botonDisable = (event) => {
    if (tarjProf && inputSwitch) {
      return formStatePregrado.isValid && formState.isValid;
    } else if (tarjProf && !inputSwitch) {
      return formState.isValid;
    } else {
      return formState.isValid;
    }
  };

  useEffect(() => {
      setFormStatePregrado(formStatePregradoInit);
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          fechaExpedicionTarjetaProfesional: "",
          fileCertificado: "",
          fileTarjetaProfesional: "",
        },
      });
      // eslint-disable-next-line 
  },[inputSwitch]); 


  useSingleton(async () => {
    const infoCandidato = await getCandidatoId(idCandidato)
      .then((response) => response.data)
      .catch(() => setRedirect(true));
    if (infoCandidato !== undefined) {
      setCandidatoObtenido(infoCandidato);
    }
  });

  const agregarEspaciosBlanco = (event) => {
    const { name, value } = event.target;

    setFormStatePregrado({
      ...formStatePregrado,
      values: {
        ...formStatePregrado.values,
        fileCertificado: "",
        fileTarjetaProfesional: "",
        fechaExpedicionTarjetaProfesional: "",
        [name]: value,
      },
    });
    setFormState({
      ...formState,
      values: { ...formState.values, tarjeta: false, [name]: value },
    });
  };

  const handleChange = (event) => {
    const { type, name, value, files } = event.target;
    const fieldValue = type === "file" ? files[0] : value;

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [name]: fieldValue,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    });
    setFormStatePregrado({
      ...formStatePregrado,
      values: {
        ...formStatePregrado.values,
        [name]: fieldValue,
      },
      touched: {
        ...formStatePregrado.touched,
        [name]: true,
      },
    });
    if (formState.values.tipo === "Bachiller" && formState.values.nombre !== "Bachiller") {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          nombre: "Bachiller",
        },
        touched: {
          ...formState.touched,
          nombre: true,
        },
      });
    };
    if (name === "tipo" || name === "inputSwitch") {
      if (value !== "Pregrado" && value !== "") {
        agregarEspaciosBlanco(event);
        setIsPregrado(false);
        setTarjProf(false);
        setInfoTarjeta(false);
      } else if ( value === "Pregrado" && !inputSwitch ) {
        agregarEspaciosBlanco(event);
        setIsPregrado(true);
        setTarjProf(false);
        setInfoTarjeta(false);
      } else {
        if (
          formStatePregrado.values.fileCertificado !== "" &&
          formStatePregrado.values.fileTarjetaProfesional !== ""
        ) {
          setFormState({
            ...formState,
            values: { ...formState.values, tarjeta: true, [name]: value },
          });
        }
        setInfoTarjeta(true);
        setTarjProf(true);
        setIsPregrado(true);
      }
    }
  };

  const handleSwitch = (event) => {
    setInputSwitch(event.target.checked);
    handleChange(event);
  };
  
  const OnSubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();
    const formacion = {
      nombre: formState.values.tipo !== "Bachiller" ? formState.values.nombre : "Bachiller",
      centroEducativo: formState.values.centroEducativo,
      fechaCulminacion: formState.values.fechaCulminacion,
      tipo: formState.values.tipo,
      tarjeta: inputSwitch && infoTarjeta? true: false,
      fechaExpedicionTarjetaProfesional:
        formStatePregrado.values.fechaExpedicionTarjetaProfesional,
      idCandidato: idCandidato,
    };
    let formacionQuery = formacion;
    formacionQuery.tipo = formacion.tipo.toUpperCase();
    formData.append(
      "formacion",
      new Blob([JSON.stringify(formacionQuery)], { type: "application/json" })
    );
    formData.append("diploma", formState.values.fileDiploma);
    formData.append(
      "tarjetaProfesional",
      formState.values.fileTarjetaProfesional
    );
    formData.append("certificado", formState.values.fileCertificado);

    await postFormaciones(formData)
      .then(function (response) {
        MySwal.fire({
          icon: "success",
          title: "Formación creada",
        });
        setInfo(1);
      })
      .catch(function (response) {
        if (
          response.response.data.detail ===
          "That id doesn't belongs to any candidato"
        ) {
          MySwal.fire({
            icon: "error",
            title: "Ocurrió un error",
            text: "Ocurrió un error al procesar la solicitud, por favor intente más tarde",
          });
        }
      });
  };
  return (
    <CardContent>
      {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
      <Typography variant="h1" className={classes.subtitle}>
        Crear Formación para {candidatoObtenido.nombres}{" "}
        {candidatoObtenido.apellidos}
      </Typography>
      <Typography variant="body2" className={classes.subtitleObligatorio}>
        Los campos con <span className={classes.color}>*</span> son obligatorios
      </Typography>
      <form noValidate autoComplete="off" onSubmit={OnSubmit}>
        <CustomTypeSelect
          titulo="Tipo de Formación"
          name="tipo"
          handleChange={handleChange}
          hasError={hasError}
          classes={classes}
        />
        {formState.values.tipo !== "Bachiller" ? (
        <CustomNombre
          titulo="Nombre del programa"
          classes={classes}
          isPregrado={isPregrado}
          handleChange={handleChange}
          name="nombre"
          hasError={hasError}
          formState={formState}
        />
        ) : (
          <></>
        )}
        <CustomTextfield
          classes={classes}
          titulo="Centro educativo"
          handleChange={handleChange}
          name="centroEducativo"
          hasError={hasError}
          formState={formState}
          inputProps={{ maxLength: 40 }}
        />
        <CustomTextfield
          classes={classes}
          titulo="Fecha de Culminación"
          handleChange={handleChange}
          name="fechaCulminacion"
          hasError={hasError}
          formState={formState}
          inputProps={maxFecha}
          type="date"
          max={maxFecha}
        />
        <CustomTextfield
          classes={classes}
          titulo="Adjunte aquí su Diploma"
          handleChange={handleChange}
          name="fileDiploma"
          hasError={hasError}
          formState={formState}
          inputProps={propFiles}
          type="file"
          InputLabelProps={labelFiles}
        />
        {isPregrado ? (
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant="body1" className={classes.typography}>
                Tarjeta Profesional
              </Typography>
            </Grid>
            <Grid item xs={7} className={classes.center}>
              <Grid component="label" container alignItems="center" spacing={1} className={classes.center}>
                <Grid item>No</Grid>
                <Grid item>
                  <Switch
                    checked={inputSwitch}
                    onChange={handleSwitch}
                    color="primary"
                    name="inputSwitch"
                    inputProps={{ "aria-label": "primary checkbox" }}
                    label="normal"
                  />
                </Grid>
                <Grid item>Sí</Grid>
              </Grid>
            </Grid>
          </Grid>
        ) : (
          <></>
        )}
        {inputSwitch && infoTarjeta ? (
          <React.Fragment>
            <CustomTextfield
              classes={classes}
              titulo="Expedición de Tarjeta Profesional"
              handleChange={handleChange}
              name="fechaExpedicionTarjetaProfesional"
              hasError={hasErrorPregrado}
              formState={formStatePregrado}
              inputProps={maxFecha}
              type="date"
            
            />
            <CustomTextfield
              classes={classes}
              titulo="Adjunte la Tarjeta Profesional"
              handleChange={handleChange}
              name="fileTarjetaProfesional"
              hasError={hasErrorPregrado}
              formState={formStatePregrado}
              inputProps={propFiles}
              type="file"
              InputLabelProps={labelFiles}
            />
            <CustomTextfield
              classes={classes}
              titulo="Adjunte el Certificado de Tarjeta Profesional"
              handleChange={handleChange}
              name="fileCertificado"
              hasError={hasErrorPregrado}
              formState={formStatePregrado}
              inputProps={propFiles}
              type="file"
              InputLabelProps={labelFiles}
            />
          </React.Fragment>
        ) : (
          <></>
        )}
        <Grid container item xs={12} spacing={3}>
          <Grid item xs={6} className={classes.button}>
            <Button
              variant="contained"
              color="secondary"
              onClick={(event) => setInfo(1)}
            >
              Cancelar
            </Button>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!botonDisable()}
            >
              Crear Formación
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  );
};

export default IngresarFormacion;
