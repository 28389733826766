import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const getPerfilesFromOferta = (id) =>{
    let config = {
        method: 'get',
        url: `${BASE_URL}perfil_contratacion/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const savePerfil = (data)=>{
    let config = {
        method : 'POST',
        url: BASE_URL + 'perfils',
        headers:{
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data:data
    }
    return axios(config)
}
