import React, { useEffect } from "react";
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, Select, alpha } from '@material-ui/core';
import validate from 'validate.js';

const useStyles = makeStyles(theme => ({
  textFieldFullWidth: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: alpha(theme.palette.common.dark, 0.23),
		},
	},
  textFieldRoot: {
    width: "100%",
    height: "15%",
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: alpha(theme.palette.common.dark, 0.23),
		},
  },
}));

let mensajeHelper = {}

export default function TypeForm ({ children, id, formState, setFormState, helperText, ...props }) {

  const classes = useStyles();
  mensajeHelper = helperText

  const hasError = (field) =>
    formState.touched[field] && formState.errors[field] ? true : false;

  useEffect(() => {
    const errors = validate(formState.values, mensajeHelper);
  
    setFormState((formState) => ({
    ...formState,
    isValid: errors ? false : true,
    errors: errors || {},
    }));
    // eslint-disable-next-line
  }, [formState.values]);

  const handleChange = (event) => {
    event.persist();

    const { type, name, value, files } = event.target;
    const fieldValue = type === 'file' ? files[0] : value;

    setFormState({
      ...formState,
      values: {
        ...formState.values,
        [name]: fieldValue,
      },
      touched: {
        ...formState.touched,
        [name]: true,
      },
    });
  };

  return (
    <FormControl
      fullWidth={props?.fullWidth ? true : false}
      variant="outlined"
      margin="normal"
      className={props?.fullWidth ? classes.textFieldFullWidth : classes.textFieldRoot}
    >
      <Select
        defaultValue={props?.defaultValue ? props.defaultValue : ""}
        onChange={handleChange} name={id}
        error={hasError(id)}
      >
        {children}
      </Select>
    </FormControl>
  );
};
