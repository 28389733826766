import React from 'react';
import GridContainer from '../../../components/GridContainer';
import PageContainer from '../../../components/PageComponents/layouts/PageContainer';
import Grid from '@material-ui/core/Grid';
import { Box, Typography } from "@material-ui/core";
import { NavLink, Redirect } from 'react-router-dom';

const MainPage = (props) => {

  const auth = !!sessionStorage.getItem('token');

  return (
    <PageContainer heading='Bienvenido al Sistema de gestión de Grupo IS Colombia'>
      {auth ? <Redirect push to={{ pathname:'/admin_page'}} /> : null}
      <GridContainer>
        <Grid item xs={12}>
          <Typography>Por favor inicia sesión con tu usuario de Grupo IS para acceder a las funcionalidades del sistema</Typography>
          <br></br>
          <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
            <NavLink to="/signin">
                  Iniciar sesión
            </NavLink> 
          </Box>        
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default MainPage;