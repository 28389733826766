import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Link } from 'react-router-dom'
import Button from '@material-ui/core/Button';
/// For the CandidatosSearchTable component
import { useTable, useFilters, useGlobalFilter, useRowSelect, useAsyncDebounce, usePagination,} from 'react-table'
import MaUTable from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import Card from '@material-ui/core/Card';
import TableRow from '@material-ui/core/TableRow'
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { MenuItem, Select, TextField } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import { getOfertasPage } from '../../../../helpers/oferta/ofertaHelper';
import { dateFormat } from "../../../../constants/AppConstants";

const useStyles = makeStyles(theme => ({
	internalCard: {
		padding: 15,
	},
	table: {
		minWidth: 700,
		boxShadow: theme.shadows[5],
	},
	cell: {
		maxWidth: 100,
		overflowWrap: 'anywhere',
	},
	paper: {
		marginTop: 15,
	},
	tableContent: {
		height: 350,
		boxShadow: theme.shadows[5],
	},
	pagination: {
		marginTop: 15,
		alignItems: 'center',
		justifyContent: 'center',
	},
	textFieldRoot: {
		width: "305px",
	},
	formText: {
		margin: 15,
		paddingTop: 5,
	},
	inputs: {
		display: 'flex',
		flexWrap: 'wrap'
	}
}));

// Define a default UI for filtering
function GlobalFilter({
	preGlobalFilteredRows,
	globalFilter,
	setGlobalFilter,
	id
}) {
	const classes = useStyles();
	const [value, setValue] = React.useState(globalFilter)
	const onChange = useAsyncDebounce(newValue => {
		setGlobalFilter(newValue || undefined)
	}, 200)

	return (
		<TextField
	  id={id}
      variant="outlined"
      className={classes.textFieldRoot}
      value={value || ""}
      placeholder={' Inserta la oferta a buscar'}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
    />
	);
}

const DetailsLink = (row) => {
	let state = row.row.original
	return (
		<Link color="inherit" to={{ pathname: '/detalle_oferta', state: { oferta: state } }}>
			Ver detalle
		</Link>
	)
}
function Table({ props, onReloadTable, columns, data, initialState, dataQuery, editable = false, }) {
	const classes = useStyles();
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		setPageSize,
		preGlobalFilteredRows,
		globalFilter,
		setGlobalFilter,
	} = useTable(
		{
			columns,
			data,
			initialState: { pageSize: 10 }

		},
		useFilters, // useFilters!
		useGlobalFilter, // useGlobalFilter!
		usePagination,
		useRowSelect,
		hooks => {
			hooks.visibleColumns.push(columnsHooked => [
				// Let's make a column for selection
				...columnsHooked,
				{
					id: 'selection',
					// The header can use the table's getToggleAllRowsSelectedProps method
					// to render a checkbox
					Header: ({ getToggleAllPageRowsSelectedProps }) => (
						<div>
							<Typography variant="subtitle2" > Detalle </Typography>
						</div>
					),
					// The cell can use the individual row's getToggleRowSelectedProps method
					// to the render a checkbox
					Cell: ({ row }) => (
						<div>
							<DetailsLink row={row} />
						</div>
					),
				},
			])
		}
	)

	return (
		<div>
			<div className={classes.inputs}>
            	<Typography className={classes.formText} color="textPrimary">
					<label htmlFor="oferta">
						Visualiza y crea nuevas ofertas
						</label>
            		</Typography>
            		<GlobalFilter
             		preGlobalFilteredRows={preGlobalFilteredRows}
              		globalFilter={globalFilter}
              		setGlobalFilter={setGlobalFilter}
					id="oferta"
            		/>
        	</div>
			<div className={classes.paper}>
				<div style={{ overflow: "auto", margin: "20px 0" }}>
					<MaUTable className={classes.table} {...getTableProps()}>
						<TableHead>
							{headerGroups.map(headerGroup => (
								<TableRow {...headerGroup.getHeaderGroupProps()}>
									{headerGroup.headers.map(column => (
										<TableCell className={classes.cell} {...column.getHeaderProps()}>
											<strong>{column.render('Header')}</strong>
											{/* Render the columns filter UI */}
											{/* <div>{column.canFilter ? column.render('Filter') : null}</div> */}
										</TableCell>
									))}
								</TableRow>
							))}
						</TableHead>
						<TableBody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row)
								return (
									<TableRow {...row.getRowProps()}>
										{row.cells.map(cell => {
											return <TableCell className={classes.cell} {...cell.getCellProps()}>{cell.render('Cell')}</TableCell>
										})}
									</TableRow>
							)})}
						</TableBody>
					</MaUTable>
				</div>
				<div className={classes.pagination}>
					<Button onClick={() => { initialState({ page: 0 }) }} disabled={dataQuery.first}>
						{'<<'}
					</Button>{' '}
					<Button onClick={() => { initialState({ page: dataQuery.number - 1 }) }} disabled={dataQuery.first}>
						{'<'}
					</Button>{' '}
					<Button onClick={() => { initialState({ page: dataQuery.number + 1 }) }} disabled={dataQuery.last}>
						{'>'}
					</Button>{' '}
					<Button onClick={() => { initialState({ page: dataQuery.totalPages - 1 }) }} disabled={dataQuery.last}>
						{'>>'}
					</Button>{' '}
					<span>
						Página{' '}
						<strong>
							{dataQuery.number + 1} de {dataQuery.totalPages}
						</strong>{' '}
					</span>
					<span>
						| Ir a la página:{' '}
						<Input
							type="number"
							defaultValue={dataQuery.number + 1}
							onChange={e => {
								if (e.target.value < dataQuery.totalPages) {
									initialState({ page: Number(e.target.value) })
								}}}
							style={{ width: '100px' }}
						/>
					</span>{' '}
					<Select
						value={dataQuery.size}
						onChange={e => {
							setPageSize(Number(e.target.value))
							initialState({ page: dataQuery.number, pageSize: Number(e.target.value) })
						}}>
						{[10, 20, 30, 40, 50].map(pageSizeMaper => (
						<MenuItem key={pageSizeMaper} value={pageSizeMaper}>
							Mostrar {pageSizeMaper}
						</MenuItem>
					))}
					</Select>
				</div>
			</div>
		</div>
	)
}
const OfertasTable = (props, ofertas, dataQuery, initialState) => {
	const classes = useStyles();
	const columns = [
		{
			Header: 'Nombre de la oferta',
			accessor: 'nombre',
		},
		{
			Header: 'Descripción de la oferta',
			accessor: 'descripcionCorta',
		},
		{
			Header: 'Proyecto',
			accessor: 'proyecto',
		},
		{
			Header: 'Fecha de cierre de la oferta',
   			accessor: 'fechaCierre',
		},
		{
			Header: 'Ciudad de la oferta',
			accessor: 'ciudad',
		},
	]
	if (ofertas.length > 0) {
		for (const i in ofertas) {
			if (ofertas[i].ciudad.nombre === "Todas las ciudades") {
				const departamento = ofertas[i].ciudad.departamento.nombre;
				ofertas[i].ciudad = "Todas las ciudades de ".concat(departamento)
			}
			if (ofertas[i].ciudad.nombre !== undefined)
				ofertas[i].ciudad = ofertas[i].ciudad.nombre;
			if (ofertas[i].proyecto.nombre !== undefined)
				ofertas[i].proyecto = ofertas[i].proyecto.nombre;
			ofertas[i].descripcion = ofertas[i].descripcion.replace(/<(.|\n)*?>/g, ' ').trim()
			if (ofertas[i].descripcion.length >= 100) {
				if (
					ofertas[i].descripcion.charAt(100) === "," ||
					ofertas[i].descripcion.charAt(100) === "."
				) {
					ofertas[i].descripcionCorta =
						ofertas[i].descripcion.substring(0, 99) + "...";
				}
				if (
					ofertas[i].descripcion.charAt(100) === " "
				) {
					ofertas[i].descripcionCorta =
						ofertas[i].descripcion.substring(0, 99) + "...";
				} else {
					ofertas[i].descripcionCorta =
						ofertas[i].descripcion.substring(0, 100) + "...";
				}

			} else {
				ofertas[i].descripcionCorta = ofertas[i].descripcion;
			}
		}
	}
	return (
		<div>
			<Breadcrumbs className={classes.margin} aria-label="breadcrumb">
				<Link color="inherit" to="/tabla_oferta">
					Ofertas
				</Link>
			</Breadcrumbs>
			<Card className={classes.internalCard}>
				<Grid container direction="row" justifyContent="flex-start" alignItems="flex-start">
					<Grid item xs={false}/>
						<Typography variant="h1" fontSize={20} color="textPrimary">Ofertas</Typography>
					<Grid  container justifyContent="flex-end"  wrap="nowrap">
						<Link to={"crear_oferta"}>
							<Button type="button" variant='contained' color='primary'>
								Crear oferta	  	
							</Button>
						</Link>
					</Grid>
				</Grid>
				{ofertas.length > 0 ? 
					<Table
						props={props}
						columns={columns}
						data={ofertas}
						dataQuery={dataQuery}
						initialState={initialState}

					/>
				: <Typography variant="h2" color="textPrimary"> No se encontraron ofertas </Typography>}
			</Card>
		</div>

	)
}


const useSingleton = (callBack = () => {
	//Empty callback
}) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}


const TablaOfertas = (props) => {
	const [ofertas, setOfertas] = React.useState({ content: [] })
	const [redirect, setRedirect] = React.useState(false)
	const initialState = async ({ page = 0, pageSize = 10 }) => {
		const ofertaQuery = await getOfertasPage({ page: page, pageSize: pageSize })
			.then(response => {
				response.data.content = response.data.content.map(oferta => {
					oferta.fechaCierre = dateFormat(oferta.fechaCierre)
					oferta.descripcionFormato = oferta.descripcion;
					oferta.ubicacion = oferta.ciudad;
					oferta.proyectoId = oferta.proyecto.id;
					return oferta
				})
				return response.data
			})
			.catch(err => setRedirect(true))
		setOfertas(ofertaQuery)
	}
	useSingleton(async () => {
		await initialState({})
	});
	
	return (
		<div>
			{redirect ? <Redirect push to={{ pathname: '/signin', state: { goBack: '/tabla_oferta' } }} /> : null}
			{OfertasTable(props, ofertas.content, ofertas, initialState)}
		</div>
	)
}

export default TablaOfertas

