import React, { useState, useRef } from 'react'
import { Typography } from '@material-ui/core';
import { DataGrid } from '@mui/x-data-grid';
import { Redirect } from 'react-router';
import { getCandidatoId } from '../../../../helpers/candidato/candidato'
import { dateFormat } from '../../../../constants/AppConstants';

const FormacionesTable = (formaciones) => {
	const columns = [
		{
			field: 'nombre',
			headerName: 'Nombre del programa',
			flex: 1,
		},
		{
			field: 'centroEducativo',
			headerName: 'Centro Educativo',
			flex: 1,
		},
		{
			field: 'fechaCulminacion',
			headerName: 'Fecha de culminación',
			valueGetter: (params)=>{
				return dateFormat(params.row.fechaCulminacion);
			},
			flex: 1,
		},
		{
			field: 'fechaExpedicionTarjetaProfesional',
			headerName: 'Fecha expedición tarjeta profesional',
			valueGetter: (params)=>{
				return params.row.tarjetaProfesional ? dateFormat(params.row.fechaExpedicionTarjetaProfesional) : "---";
			},
			flex: 1,
		},
	]
	
	return (
		formaciones.length > 0
		? <DataGrid 
			rows={formaciones} 
			columns={columns} 
			pageSize={5} 
			autoHeight 
			disableColumnFilter 
			disableColumnMenu
			disableDensitySelector
			disableSelectionOnClick
			showCellRightBorder
			showColumnRightBorder
			rowsPerPageOptions={[5]}
			autoPageSize={false}
		/>
		: <Typography variant="h5" color="textPrimary"> No se encontraron formaciones </Typography>
	)
}


const useSingleton = (callBack = () => {
	//Blank function
}) => {
    const hasBeenCalled = useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const TablaFormacion = ({idCandidato}) => {
  const [formaciones, setFormaciones] = useState([])
	const [redirect, setRedirect] = React.useState(false)
	useSingleton(async () => {
		const formacionQuery = await getCandidatoId(idCandidato)
			.then(response => {
				return response.data.formaciones
			})
			.catch(err => {
				setRedirect(true)
				return err
			})
		setFormaciones(formacionQuery)
	});
	return(
		<div>
		{redirect ? <Redirect push to={{ pathname:'/signin'}} /> : null}
		{FormacionesTable(formaciones)}
		</div>
	)
}

export default TablaFormacion