import React, { useState } from 'react';
import { Button, Modal, Card, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import CuentaCobroInfoRef from './CuentaCobroInfoRef';
import withReactContent from 'sweetalert2-react-content';
import {
	preAprobarCuetaCobro, aprobarCuentaCobro, getCuentaCobroFinanciero, rechazoTecnicoCuentaCobro,
	rechazoFinancieroCuentaCobro, getCuentaCobroTecnico, cuentaCobroByContratoTecnico, cuentasCobroByContratoFinanciero
} from '../../../../helpers/cuentaCobro/cuentaCobroHelper';
import { errorAlert } from '../../../../helpers/swalHelper/swalHelper';
import { getAllPreempleados, getPreempleadoByNumeroDocumento } from '../../../../helpers/preempleado/preempleadoHelper';
import Swal from "sweetalert2";
import { Redirect } from 'react-router';
import FiltroCuentaCobro from '../../../../components/FiltroCuentaCobro';
import CuentaCobroTable from './tablaCuentaCobro';
import CuentaCobroRejectionRef from './CuentaCobroRejectionRef';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: "100%",
		minWidth: "100%",
		margin: '0 auto',
		backgroundColor: lighten(theme.palette.background.paper, 0.1),

	},
	table: {
		width: "100%",
		minWidth: 650,
		display: "block"
	},
	textFieldRoot: {
		width: "70%",
		height: "15%",
	},
	title: {
		textAlign: 'center',
	},
	subtitle: {
		marginLeft: theme.spacing(3),
		marginTop: '20px'
	},
	form: {
		paddingLeft: '25%',
		paddingTop: 15
	},
	alerta: {
		width: '100%',
		padding: 40,
		'& > * + *': {
			marginTop: theme.spacing(2),
		},
	},
	button: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-evenly',
		marginBottom: '20px',
		marginTop: '30px',
	},
	formText: {
		margin: 15,
		paddingTop: 5,
		paddingLeft: theme.spacing(8),
		width: "70%",
		height: "15%",
	},
	inputPading: {
		padding: 5,
	},
	left: {
		display: 'flex',
		alignItems: 'center',
	},
	typography: {
		paddingLeft: theme.spacing(8),
	},
	ol: {
		paddingLeft: theme.spacing(8),
		marginBottom: '40px',
	}
}));

const useSingleton = (callBack = () => {
	//Blank function no need to do anything
}) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}

const AprobarCuentaCobro = refactorAprobe()

const ModalReject = ({ cuentaCobro, handleClose, motivo, setMotivo, open, rechazar }) => (
	<Modal
		open={open}
		onClose={handleClose}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description">
		<CuentaCobroRejectionRef cuentaCobro={cuentaCobro} handleClose={handleClose} motivo={motivo} setMotivo={setMotivo} rechazar={rechazar} />
	</Modal>
)

const ModalInfo = ({ cuentaCobro, handleClose, open, aprobar }) => (
	<Modal
		open={open}
		onClose={handleClose}
		aria-labelledby="modal-modal-title"
		aria-describedby="modal-modal-description"
	>
		<CuentaCobroInfoRef cuentaCobro={cuentaCobro} handleClose={handleClose} aprobar={aprobar} />
	</Modal>
)
export default AprobarCuentaCobro
function refactorAprobe() {
	return (props) => {

		const classes = useStyles();
		const [mostrarTabla, setMostrarTabla] = useState(false);
		const [detailsCuentaCobro, setDetailsCuentaCobro] = useState(null);
		const [detailsCuentaCobroReject, setDetailsCuentaCobroReject] = useState(null);
		const [redirect, setRedirect] = useState(false);
		const [cuentasCobro, setCuentasCobro] = useState([]);
		const [preempleados, setPreempleados] = useState([]);
		const [open, setOpen] = useState(false);
		const [openReject, setOpenReject] = useState(false);
		const [filter, setFilter] = React.useState({
			numeroDocumento: ''
		});
		const [motivo, setMotivo] = React.useState({
			motivo: '',
		});
		const toggleCuentaCobro = () => setOpen(false);
		const toggleCuentaCobroReject = () => setOpenReject(false);
		const roles = JSON.parse(sessionStorage.getItem('authorities'));
		const initialState = async () => {
			if(!roles) return setRedirect(true)
			const preempleadoQuery = await getAllPreempleados();
			if (roles.some(rol => rol === "ROLE_TECNICO")) {
				const cuentaCobroQuery = await getCuentaCobroTecnico()
					.then(response => response.data)
					.catch(err => {
						setRedirect(true);
					});
				setPreempleados(preempleadoQuery);
				setCuentasCobro(cuentaCobroQuery);
			}
			if (roles.some(rol => rol === "ROLE_FINANCIERO")) {
				const cuentaCobroQuery = await getCuentaCobroFinanciero()
					.then(response => response.data)
					.catch(err => {
						setRedirect(true);
					});
				setPreempleados(preempleadoQuery);
				setCuentasCobro(cuentaCobroQuery);
			}
		};

		useSingleton(async () => {
			await initialState();
		});

		const handleOpen = (cuentaCobro) => {
			setDetailsCuentaCobro(cuentaCobro);
			setOpen(true);
		};

		const handleReject = (cuentaCobro) => {
			setDetailsCuentaCobroReject(cuentaCobro);
			setOpenReject(true);
		};

		const handleClose = () => setOpen(false);
		const handleCloseReject = () => setOpenReject(false);

		const handleSubmit = async () => {
			const preempleados = await getPreempleadoByNumeroDocumento({ numeroDocumento: filter.numeroDocumento })
				.then(res => res.data)
				.catch(err => console.log(err));
			console.log(preempleados)
			let cuentasCobro = [];	
			if (roles.some(rol => rol === "ROLE_TECNICO")) {
				for (let preempleado of preempleados) {
					let cuenta = await cuentaCobroByContratoTecnico({ id: preempleado.contrato.id })
						.then(res => res.data)
						.catch(err => console.log(err));
					cuentasCobro.push(cuenta)
				}
			}			
			if (roles.some(rol => rol === "ROLE_FINANCIERO")) {
				for (let preempleado of preempleados) {
					let cuenta = await cuentasCobroByContratoFinanciero({id: preempleado.contrato.id})
						.then(res => res.data)
						.catch(err => console.log(err));
					cuentasCobro.push(cuenta);
				}
			}
			setCuentasCobro(cuentasCobro.flat());
			setMostrarTabla(true);
		};

		const aprobar = (data) => {
			toggleCuentaCobro();
			Swal.fire({
				title: '¿Aprobar cuenta de cobro?',
				showCancelButton: true,
				reverseButtons: true,
				cancelButtonColor: '#f50057',
				confirmButtonText: 'Aprobar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					if (roles.some(rol => rol === "ROLE_TECNICO")) {
						preAprobarCuetaCobro({ id: data })
							.then(res => {
								MySwal.fire('La cuenta de cobro ha sido aprobada', '', 'success');
								initialState();
							})
							.catch(errorAlert);
					}
					if (roles.some(rol => rol === "ROLE_FINANCIERO")) {
						aprobarCuentaCobro({ id: data })
							.then(res => {
								MySwal.fire('La cuenta de cobro ha sido aprobada', '', 'success');
								initialState();
							})
							.catch(errorAlert);
					}

				}
			});
		};

		const rechazar = (id, motivo) => {
			toggleCuentaCobroReject();
			Swal.fire({
				title: '¿Rechazar cuenta de cobro?',
				showCancelButton: true,
				reverseButtons: true,
				cancelButtonColor: '#f50057',
				confirmButtonText: 'Rechazar',
				cancelButtonText: 'Cancelar'
			}).then((result) => {
				if (result.isConfirmed) {
					if (roles.some(rol => rol === "ROLE_TECNICO")) {
						rechazoTecnicoCuentaCobro({ id: id, motivo: motivo })
							.then(res => {
								MySwal.fire('La cuenta de cobro ha sido rechazada', '', 'success');
								initialState();
							})
							.catch(errorAlert);
					}
					if (roles.some(rol => rol === "ROLE_FINANCIERO")) {
						rechazoFinancieroCuentaCobro({ id: id, motivo: motivo })
							.then(res => {
								MySwal.fire('La cuenta de cobro ha sido rechazada', '', 'success');
								initialState();
							})
							.catch(errorAlert);
					}

				}
			});
		};
		const validacion = () => {
			return filter.numeroDocumento === '';
		}

		return (
			<div>

				<Card className={classes.root}>
					{redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
					<CardContent>
						<Typography variant='h1'>Gestionar cuentas de cobro</Typography>
						<Typography variant='subtitle1' align="left" color="textPrimary">Búsca y aprueba las cuentas de cobro,
							recuerda que para que la cuenta de cobro sea totalmente aprobada se requiere
							del visto bueno técnico y financiero</Typography>
						<FiltroCuentaCobro props={props} filter={filter} setFilter={setFilter} setMostrarTabla={setMostrarTabla} mostrarTabla={mostrarTabla} />
						<Grid container item xs={12} spacing={3} className={classes.button}>
							<Button
								variant="contained"
								color="primary"
								onClick={handleSubmit}
								disabled={validacion()}
							>
								Buscar
							</Button>
							<Button className={classes.marginButtons} size="small" variant="text"
              				onClick={() => {
                  				initialState();
								setFilter({
									numeroDocumento: ''
								})
							}} > 
								Limpiar
            				</Button>
						</Grid>
						<CuentaCobroTable cuentasCobro={cuentasCobro} handleOpen={handleOpen} handleReject={handleReject} preempleado={preempleados} />
					</CardContent>
				</Card>
				<ModalInfo cuentaCobro={detailsCuentaCobro} handleClose={handleClose} open={open} aprobar={aprobar} />
				<ModalReject cuentaCobro={detailsCuentaCobroReject} handleClose={handleCloseReject} motivo={motivo} setMotivo={setMotivo} open={openReject} rechazar={rechazar} />
			</div>
		);
	};
}

