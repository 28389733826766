import React, { forwardRef, useState } from 'react';
import { Redirect } from 'react-router';
import { getPreempleadosContratados } from '../../../../helpers/preempleado/preempleadoHelper';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { lighten, MuiThemeProvider, Typography, createTheme, Card, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import BotonDesvincular from './BotonDesvincular';

//Iconos
import ArrowDownward from '@material-ui/icons/ArrowDownward';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import Search from '@material-ui/icons/Search';
import ViewColumn from '@material-ui/icons/ViewColumn';
import { getContratoById } from '../../../../helpers/contratoHelper/contratoHelper';

const useStyles = makeStyles(theme => ({
	root: {
		maxWidth: "100%",
		minWidth: "100%",
		margin: 25,
		backgroundColor: lighten(theme.palette.background.paper, 0.1),
	},
	responsive: {
	  display:'flex',
	  flexWrap: 'wrap',
	  justifyContent: 'space-between',
	  paddingLeft: '16px',
	  paddingRight: '8px',
	  alignItems:'center',
	  "@media (max-width: 515px)":{
		justifyContent: 'center',
		paddingLeft: '0'
	  }
	},

	search: {
	  minWidth: '133px',
	},
	subtitle: {
		marginLeft: theme.spacing(3),
		marginTop: '20px',
		marginBottom: '20px' 
	},
	table: {
		width: "100%",
		overflowX: 'auto',
		alignContent: 'center',
		justifyContent: 'center',
	},
  }));

  const tableIcons = {
	DetailPanel: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
	Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
	Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
	FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
	LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
	NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
	PreviousPage: forwardRef((props, ref) => <ChevronLeft {...props} ref={ref} />),
	ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
	Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
	SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
	ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
	ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

const useSingleton = (callBack = () => {
	//Empty callback
}) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}

//Definicion de la tabla para entidad Empresas
function ContratadosTable({ title, items }) {
	//Hooks
	//Hook estilos
	const classes = useStyles();
	//Definimos la estructura de la tabla (columnas)
	const [state, setState] = React.useState({
	  columns: [
		{
			title: 'Número De Documento',
			field: 'numeroDocumento'
		},
		{
		  title: 'Nombres',
		  field: 'nombres',
		},
		{
		  title: 'Apellidos',
		  field: 'apellidos',
		},
		{
			title: 'Número De Contrato',
			field: 'contrato',
			emptyValue: 'Sin Contrato',
		},
		{
			title: 'Perfil',
			field: 'perfil',
		},
		{
			title: 'Acciones',
			field: 'desvincular',
			render: row => <BotonDesvincular id={row.id} />
		}
	  ],
	  data: []
	});

	//Definimos las tipografia para el titulo
	const MyNewTitle = () => (
	  <Typography
		variant={'subtitle2'}
		style={{
		  fontSize: '1.4rem',
		  fontWeight: 'bold',
		}}>
		{title}
	  </Typography>
	);
  
	//Hook para Listar
	React.useEffect(() => {
	  // vamos a listar cada vez que el item sufra camios
	  //arreglo de datos nuevos
	  const newData = [];

	  //Verificamos que no venga vacio el arreglo de elementos del backend
	  //y luego definimos la estructura del elemento para listarlo
	  if (items && items.length) {
		//itermos poniendo el elemento a el arreglo y hacemos el set
		items.forEach(myItem => {
		  //definimos constantes para construir el elemento a listar
		  const { id, numeroDocumento, nombres, apellidos, perfil } = myItem;
		  let contrato = myItem.contrato.numeroContrato;

		  //Agregamos a el arreglo de datos nuevo
		  newData.push({ id, numeroDocumento, nombres, apellidos, perfil, contrato });
  
		  setState(state => ({
			...state,
			data: [...newData],
		  }));
		});
	  }
	}, [items]);
  
	//Estilos de la tabla
	const theme = createTheme({
	  palette: {
		primary: {
		  main: '#1C4C96',
		},
		secondary: {
		  main: '#C3C4CA',
		},
	  },
	});
  
	//Devolvemos toda la tabla construida
	return (
	  <MuiThemeProvider theme={theme}>
		<MaterialTable
		  //Clase de estilos
		  className={classes.root}
		  //Iconos
		  icons={tableIcons}
		  //Titulo de tabla
		  title={<MyNewTitle />}
		  //Columnas de tabla
		  columns={state.columns}
		  //Informacion de la tabla
		  data={state.data}
		  
		  components={{
			Toolbar: props => {
			const propsCopy = { ...props };
			propsCopy.showTitle = false;
			  return (
				  <div className={classes.responsive}>
					  <div>
						  <MyNewTitle />
					  </div>
					  <div>
						  <MTableToolbar {...propsCopy} />
					  </div>
				  </div>
				  );
				}
			  }}
		  //Opciones de estructura y elementos
		  options={{
			//indice columna acciones
			actionsColumnIndex: 0,
			//la fila que se agrega queda de ultima
			addRowPosition: 'last',
			//estilo titulo columnas
			headerStyle: {
			  backgroundColor: theme.palette.common.white,
			  color: theme.palette.common.black,
			  fontWeight: 'bold',
			},
			//estilo fila
			rowStyle: {
			  backgroundColor: theme.palette.common.white,
			},
			pageSizeOptions: [5,10,20,30,40,50],
			
		  }}
		  //Opcion para localizar elementos y alterar propiedades
		  localization={{
			//titulo de columnas
			header: {
			  actions: 'Acciones',
			},
			//buscador de tabla
			toolbar: {
			  searchTooltip: 'Buscar',
			  searchPlaceholder: 'Buscar',
			},
			//cuerpo de la tabla
			body: {
			  emptyDataSourceMessage: 'No hay contratados para mostrar',
			},
			//Paginacion
			pagination: {
			  labelRowsSelect: 'Candidatos',
			  labelDisplayedRows: 'Candidatos {from} a {to} de {count}',
			  firstTooltip: 'Primera Página',
			  previousTooltip: 'Página Anterior',
			  nextTooltip: 'Siguiente Página',
			  lastTooltip: 'Última página',
			},
		  }}
		/>
	  </MuiThemeProvider>
	);
};

const DesvincularContratado = (props) => {
	const [contratados, setContratados] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const classes = useStyles();
	const initialState = async () => {
		//Llamado al back para traer todos los contratados
		const contratadoQuery = await getPreempleadosContratados()
			.then(response => {return response.data})
			.catch(err => setRedirect(true));
		for (let i in contratadoQuery){
			contratadoQuery[i].perfil = await getContratoById(contratadoQuery[i].contrato.id)
				.then((res) => res.data.perfil.nombre)
				.catch((err) => console.log(err));
		}
		setContratados(contratadoQuery);
	}
	
	useSingleton(async () => {
		await initialState()
	});
	return (
		<div>
			<Card className={classes.root}>
				<CardContent>
					<Typography variant='h1'>Desvincular candidatos contratados</Typography>
          			<Typography  className={classes.subtitle}>Busca y desvincula los candidatos de un contrato en Grupo IS</Typography>
					{redirect ? <Redirect push to={{ pathname:'/signin', state:{goBack:'/desvincular_contratado'}}} /> : null}
					
					<ContratadosTable title={'Candidatos contratados'} items={contratados} />
					
				</CardContent>
			</Card>
		</div>
	)
}

export default DesvincularContratado;
