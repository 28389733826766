import React from 'react'
import { Typography, Grid, Button, MenuItem, Select } from '@material-ui/core';
import Swal from "sweetalert2"
import MaterialTable from '@material-table/core';
import Input from '@material-ui/core/Input';
import {spanishTextTable} from '../../../constants/AppConstants'

const PostuladosTable = ({
	token,
	idOferta,
	candidatos,
	dataQuery,
	handleSubmit,
	classes,
	selectedCandidatos,
	setSelectedCandidatos,
	handleCreate,
	handleRechazar,
	loading,
	setLoading,
	handleOpen}) => {
	const [pageNumer, setPageNumber] = React.useState(dataQuery.number + 1)
	const confirmSelected = () => {
		Swal.fire({
			title: '¿Aprobar los candidatos seleccionados?',
			showCancelButton: true,
			confirmButtonText: 'Aprobar',
		}).then((result) => {
			if (result.isConfirmed) {
				handleCreate();
				setLoading(true);
			}
		})
	}

	const rejectSelected = () => {
		Swal.fire({
			title: '¿Rechazar los candidatos seleccionados?',
			showCancelButton: true,
			confirmButtonText: 'Rechazar',
		}).then((result) => {
			if (result.isConfirmed) {
				handleRechazar();
				setLoading(true);
			}
		})
	}

	console.log(candidatos)

	const columns = [
		{ title: "Cédula", field: "numeroDocumento" },
		{
			title: "Nombre", field: "nombres", render: rowData => {
				return (
					`${rowData.nombres} ${rowData.apellidos}`
				)
			}
		},
		{ title: "Formación inicial", render: rowData => {
			let formacionInicial = rowData.formaciones.filter(e=>e.tipo === 'Bachiller' 
			|| e.tipo === 'Tecnico' || e.tipo === 'Tecnologo')
			
			return formacionInicial.length > 0 ? formacionInicial[0].tipo : '---'
		}},
		{ title: "Nombre formación inicial", render: rowData => {
			let nombreFormacionInicial = rowData.formaciones.filter(e=>e.tipo === 'Bachiller' 
			|| e.tipo === 'Tecnico' || e.tipo === 'Tecnologo')
			
			return nombreFormacionInicial.length > 0 ? nombreFormacionInicial[0].nombre : '---'
		}},
		{ title: "Pregrado", render: rowData => {
			let nombrePregrado = rowData.formaciones.filter(e=>e.tipo === 'Pregrado')
			
			return nombrePregrado.length > 0 ? nombrePregrado[0].nombre : '---'
		}},
		{ title: "Posgrado", render: rowData => {
			let nombrePosgrado = rowData.formaciones.filter(e=>e.tipo === 'Especializacion' || 
			e.tipo === 'Maestria' ||  e.tipo === 'Doctorado' || e.tipo === 'Posdoctorado')
			
			return nombrePosgrado.length > 0 ? nombrePosgrado[0].tipo : '---'
		}},
		{ title: "Posgrado", render: rowData => {
			let nombrePosgrado = rowData.formaciones.filter(e=>e.tipo === 'Especializacion' || 
			e.tipo === 'Maestria' ||  e.tipo === 'Doctorado' || e.tipo === 'Posdoctorado')
			
			return nombrePosgrado.length > 0 ? nombrePosgrado[0].nombre : '---'
		}},
		{ title: "Fecha de expedición tarjeta profesional", render: rowData => {
			let pregrado = rowData.formaciones.filter(e=>e.tipo === 'Pregrado')
			
			return pregrado.length > 0 && pregrado[0].tarjeta ? pregrado[0].fechaExpedicionTarjetaProfesional : '---'
		}},
		{
			title: "Detalle", render: rowData => {
				return (
					<div>
						<Typography 
							variant='inherit'
							color="primary"
							className={classes.hoverPointer} onClick={() => handleOpen(rowData)}
						>
							Ver detalle
						</Typography>
					</div>
				)
			}
		}
	]
	return (
		<div>
			{candidatos.length > 0
				? <div>
					<MaterialTable
						title=""
						data={candidatos}
						columns={columns}
						isLoading={loading}
						onSelectionChange={
							(rows) => {
								let ids = []
								rows.forEach(candidato => {
									ids.push(candidato.id)
								})
								setSelectedCandidatos(ids)
							}}
						options={{ 
							selection: true, 
							paging: false,
							search: false,
							headerStyle: {
								fontWeight: 'bold'
							}
						}}
						localization={spanishTextTable}/>
					<div className="pagination">
						<Button onClick={() => { handleSubmit({ page: 0 }) }} disabled={dataQuery.first}>
							{'<<'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.number - 1 }) }} disabled={dataQuery.first}>
							{'<'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.number + 1 }) }} disabled={dataQuery.last}>
							{'>'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.totalPages - 1 }) }} disabled={dataQuery.last}>
							{'>>'}
						</Button>{' '}
						<span>
							Página{' '}
							<strong>
								{pageNumer} de {dataQuery.totalPages}
							</strong>{' '}
						</span>
						<span>
							| Ir a la página:{' '}
							<Input
								type="number"
								value={pageNumer}
								onChange={e => {
									if (e.target.value < dataQuery.totalPages && e.target.value > 0) {
										setPageNumber(e.target.value)
										handleSubmit({ page: Number(e.target.value) })
									}
								}}
								style={{ width: '100px' }}
								inputProps={{ min: 0 }}
								error={pageNumer<0}
							/>
						</span>{' '}
						<Select
							value={dataQuery.size}
							onChange={e => {
								handleSubmit({ page: dataQuery.number, pageSize: Number(e.target.value) })
							}}
						>
							{[10, 20, 30, 40, 50].map(pageSizeMaper => (
								<MenuItem key={pageSizeMaper} value={pageSizeMaper}>
									Mostrar {pageSizeMaper}
								</MenuItem>
							))}
						</Select>
					</div>

					<Grid container item xs={12} spacing={3} className={classes.button}>
						<Button
							variant="contained"
							onClick={rejectSelected}
							disabled={loading || (selectedCandidatos.length < 1)}
							color="secondary">
							Rechazar candidatos
						</Button>
						<Button
							variant="contained"
							onClick={confirmSelected}
							disabled={loading || (selectedCandidatos.length < 1)}
							color="primary">
							Preseleccionar candidatos
						</Button>
					</Grid>
				</div>
				: <Typography variant="h5" color="textPrimary"> No se encontraron candidatos </Typography>
			}
		</div>
	)
}




export default PostuladosTable