export const getToken =()=>{
  const tokenSession = sessionStorage.getItem('token');
  var token;
  if (tokenSession !== null) {
    var CryptoJS = require("crypto-js");
    var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_4DM1N');
    token = 'Bearer ' + JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } else {
    token = '';
  }
  return token;
}
