import React, { useState, useEffect } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Button, Modal, Card, CardContent, Grid, Typography, Collapse, Breadcrumbs, IconButton, Popover, Box, FormControl, Select, MenuItem, TextField, Paper, Tooltip, Chip } from '@material-ui/core';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CloseIcon from '@material-ui/icons/Close';
import PostuladosTable from './TablaPostuladosComponent'
import { getContratantes } from '../../../helpers/contratante/contratanteHelper';
import { getPostulacionesConDocumentosActualizados, rechazarCandidato } from '../../../helpers/postulacion/postulacionHelper';
import { confirmarPreseleccion } from '../../../helpers/preempleado/preempleadoHelper';
import { FiltroOferta } from '../../../components/FiltroOferta';
import Swal from "sweetalert2"
import CandidatoInfoRef from './CandidatoInfo';
import { Redirect } from 'react-router';
import { getDepartamentos, getDepartamentosId } from '../../../helpers/departamentos/departamentosHelper';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../utils/orderHelper';
import PopupState, { bindPopover, bindTrigger } from 'material-ui-popup-state';
import { Programas } from '../../../constants/AppConstants';
import { Autocomplete } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: 25,
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    width: "100%",
    minWidth: 650,
    display: "block"
  },
  textFieldRoot: {
    width: "70%",
    height: "15%",
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginTop: '20px'
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
    marginTop: '30px',
  },
  formText: {
      margin: 15,
      paddingTop: 5,
      paddingLeft: theme.spacing(8),
      height: "15%",
  },
  inputPading: {
      padding: 5,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  ol: {
    paddingLeft: theme.spacing(8),
    marginBottom: '40px',
  },
  hoverPointer: {
    cursor: "pointer",
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginTop: "12px",
  },
  breadcrumbsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '10px',
    border: '1px solid #758e9e',
    borderRadius: '5px'
  },
  breadcrumbs: {
    display: 'flex',
    justifyContent: 'center'
  },
  color: {
    color: 'red'
  },
  rootChip: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: theme.spacing(0.5),
    margin: 0,
  },
  chip: {
      margin: theme.spacing(0.5),
  },
  container: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
  },
  containerFilter: {
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
  },
  filter: {
      display: 'flex',
      flexDirection: 'column',
      flexWrap: 'wrap',
      alignItems: 'center',
      width: '32%',
  },
  containerButton: {
      height: '80px',
      display: 'flex',
      alignItems: 'center'
  },
  titleCenter: {
      margin: 15,
      paddingTop: 5,
      textAlign: 'center'
  }
}));


const tokenSession = sessionStorage.getItem('token');
var token;
if (tokenSession !== null) {
  var CryptoJS = require("crypto-js");
  var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_4DM1N');
  token = 'Bearer ' + JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
} else {
  token = '';
}

const useWindowDimensions = () => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
      function handleResize() {
          setWidth(window.innerWidth);
      }
      window.addEventListener("resize", handleResize);
      return () => window.removeEventListener("resize", handleResize);
  }, [])

  return width;
}

export default function Preseleccion(props) {
  const [redirect, setRedirect] = React.useState(false)
  useEffect(() => {
    getContratantes(token)
      .then(res => res.data)
      .then(setRedirect(false))
      .catch(() => setRedirect(true))
  }, [])

  const classes = useStyles();
  const [oferta, setOferta] = useState(null)
  const [mostrarTabla, setMostrarTabla] = useState(false)
  const [candidatos, setCandidatos] = useState([])
  const [candidatosTabla, setCandidatosTabla] = useState({ content: [], size: 10, number:0 })
  const [selectedCandidatos, setSelectedCandidatos] = useState([])
  const [detailsCandidato, setDetailsCandidato] = useState(null);
  const [loading, setLoading] = useState(false);
  const [texto, setTexto] = useState({
    entidad: '',
    proyecto: '',
    oferta: '',
  });
  const [ciudadList, setCiudadList] = React.useState({ nombre: [], id: [] });
  const [departamentoList, setDepartamentoList] = React.useState({ nombre: [], id: [] });
  const [filter, setFilter] = React.useState({
    formacionInicial: 'Pregrado',
    nombrePregrado: '',
    nombrePosgrado: '',
  })
  const [anios, setAnios] = useState("");
  const [ciudad, setCiudad] = useState({
      id: "",
      nombre: ""
  })
  const [departamento, setDepartamento] = useState("");
  const [chipUbicaciones, setChipUbicaciones] = React.useState([]);
  const [chipFormaciones, setChipFormaciones] = React.useState([]);
  const [chipAnio, setChipAnio] = React.useState([]);
  const width = useWindowDimensions();
  const filtroUbicaciones = (popupState) => {
    popupState.close()
    if (!chipUbicaciones.find(u => u.key === ciudad.id)) {
        setChipUbicaciones([...chipUbicaciones,
        { key: ciudad.id, label: departamento + " - " + ciudad.nombre, id: ciudad.id }])
        setCiudad({
            id: "",
            nombre: ""
        });
        setDepartamento("");
    }
  }

  const handleChange = (e) => {
    setFilter({
        ...filter,
        [e.target.name]: e.target.value
    })
    if (e.target.name === "ciudad") {
        setCiudad({ id: e.target.value, nombre: e.target.nameCity })
    }
  }

  const DepartamentoAutoComplete = () => {

    return departamentoList.nombre.length > 0 ?
        <Autocomplete
            options={departamentoList.nombre}
            onChange={async (e, newValue) => { onChangeDepartamentoAutoComplete(e, newValue) }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
        : <Typography value="" className={classes.formText} variant="h5" color="textPrimary">
            No se encontrarton departamentos
        </Typography>
  }

  const buscarCandidatos = async () => {
        let ubicaciones =  chipUbicaciones.map(ubicacion => ubicacion.id);
        let formaciones = chipFormaciones.map(formacion => formacion.label);

      getPostulacionesConDocumentosActualizados({ idOferta: oferta.id, formaciones, ubicaciones, anios })
        .then(res => res.data)
        .then(data => {
          let candidatosAux = []
          data.content.map(postulacion => candidatosAux.push(postulacion.candidato))
          setCandidatos(candidatosAux);
          setCandidatosTabla(data)

        })
        .catch(err => {
          Swal.fire(
            'Error',
            'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
            'error'
          )
        })
      setMostrarTabla(true);
  }

  const onChangeDepartamentoAutoComplete = async (e, newValue) => {
      if (newValue !== null) {
          let index = 1;
          for (const i in departamentoList.nombre) {
              if (newValue === departamentoList.nombre[i]) {
                  index = departamentoList.id[i];
                  setDepartamento(departamentoList.nombre[i])
              }
          }
          const ciudades = await getDepartamentosId(index)
              .then(res => sortCiudadesAlphabetically(res.data.ciudades, false))
              .catch(err => { console.log(err); })
          let nombre = []; let id = [];
          for (const i in ciudades) {
              nombre.push(ciudades[i].nombre)
              id.push(ciudades[i].id)
          }
          let ciudadesQuery = { nombre: nombre, id: id }
          setCiudadList(ciudadesQuery);
          setFilter({
              ...filter,
              departamento: false
          })
      } else {
          setFilter({
              ...filter,
              departamento: true
          })
          setCiudadList({ nombre: [], id: [] })
      }
  }

  const handleAnios = (e) => {
    setAnios(e.target.value)
  }

  const CiudadAutoComplete = () => {
    return ciudadList.nombre.length > 0 ?
        <Autocomplete
            options={ciudadList.nombre}
            disabled={filter.departamento}
            defaultValue=""
            onChange={(e, newValue) => { onChangeCiudadAutoComplete(e, newValue); }}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
        />
        : <Typography value="" className={classes.formText} variant="caption" color="textPrimary">
            Selecciona un departamento para poder ver las ciudades
        </Typography>
  }

  const onChangeCiudadAutoComplete = (e, newValue) => {
      var event = {
          value: '',
          name: "ciudad",
          nameCity: ""
      }
      if (newValue !== null) {
          let index = 1;
          for (const i in ciudadList.nombre) {
              if (newValue === ciudadList.nombre[i]) {
                  index = ciudadList.id[i];
                  event.nameCity = ciudadList.nombre[i];
              }
          }
          event.value = index
      }
      handleChange({ target: event })

  }

  const FiltroFormacionInicial = () => {
    const programa = filter.formacionInicial === "Tecnico" ? "Técnico" : filter.formacionInicial
        === "Tecnologo" ? "Tecnólogo" : filter.formacionInicial;
    if (filter.formacionInicial === 'Bachiller') {
        filter.nombrePosgrado = "Bachiller"
        return <></>
    } else if (filter.formacionInicial === 'Pregrado') {
        return <Grid container item xs={12}>
            <Grid item xs={4}>
                <Typography className={classes.formText} variant="h5" color="textPrimary">
                    Nombre de pregrado
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <FormControl
                    fullWidth
                    className={classes.inputPading}
                    variant="outlined"
                >
                    <Autocomplete
                        options={Programas}
                        onChange={(e, newValue) => {
                            var event = {
                                value: newValue,
                                name: "nombrePregrado"
                            }
                            handleChange({ target: event })
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                    />
                </FormControl>
            </Grid>
        </Grid>
    } else {
        return <Grid container item xs={12}>
            <Grid item xs={4}>
                <Typography className={classes.formText} variant="h5" color="textPrimary">
                    Nombre del {programa}
                </Typography>
            </Grid>
            <Grid item xs={8}>
                <TextField
                    fullWidth
                    type="text"
                    defaultValue=""
                    onChange={handleChange}
                    name='nombrePosgrado'
                    variant="outlined"
                    className={classes.inputPading}
                    id="nombrePosgrado"
                />
            </Grid>
        </Grid>
    }

  };


  const filtroAnios = (popupState) =>{
      popupState.close()
      setChipAnio([{key: anios, label: anios + " años de experiencia"}])
  }

  const filtroFormaciones = (popupState) => {
      popupState.close()
      let id = new Date().getTime();
      let label = filter.formacionInicial === "Pregrado"
          ? filter.nombrePregrado
          : filter.nombrePosgrado.trim();
      if (!chipFormaciones.find(f => f.label === filter.formacionInicial + "-" + label)) {
          setChipFormaciones([
              ...chipFormaciones,
              {
                  key: id,
                  label: filter.formacionInicial + "-" + label
                  ,
              },
          ]);
          setFilter({
              formacionInicial: 'Pregrado',
              tipoPosgrado: '',
              nombrePregrado: '',
              tarjetaProfesional: null,
              fechaExpedicionTarjetaProfesional: '',
              nombrePosgrado: '',
              departamento: true,
              ciudad: ''
          })
      }
  }

  const handleClosePopoverFormacion = (popupState) => {
      popupState.close()
      setFilter({
          formacionInicial: 'Pregrado',
          nombrePregrado: '',
          nombrePosgrado: ''
      });
  }

  const handleClosePopoverUbicacion = (popupState) => {
      popupState.close()
      setCiudad({
          id: "",
          nombre: ""
      });
      setDepartamento("");
  }

  const handleClosePopoverAnios = (popupState) => {
      popupState.close()
  }

  const ubicacionesInvalidas = () => {
      if (ciudad.nombre !== "") {
          return false
      }
      return true
  }

  const aniosInvalidos = () => {
      if (anios === '' || anios < 1 || anios > 50){
          return true;
      }
      return false;
  }

  const formacionesInvalidas = () => {
      if (filter.formacionInicial === "Pregrado" && filter.nombrePregrado && filter.nombrePregrado !== "") {
          return false
      }
      if (filter.formacionInicial !== "Pregrado" && filter.nombrePosgrado.trim() !== "") {
          return false
      }
      return true
  }

  const handleDeleteUbicacion = (chipToDelete) => () => {
      setChipUbicaciones((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const handleDeleteFormacion = (chipToDelete) => () => {
      setChipFormaciones((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
  };

  const handleDeleteAnio = (chipToDelete) => () => {
      setChipAnio((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
      setAnios("");
  };
  const [open, setOpen] = useState(false);
  const handleOpen = (candidato) => { setDetailsCandidato(candidato); setOpen(true) };
  const handleClose = () => setOpen(false);
  
  const handleSubmit =  async ({page=0, pageSize=10}) => {
    if (oferta) {
          const departamentos = await getDepartamentos()
          .then(response => sortDepartamentosAlphabetically(response.data))
          .catch(err => err)
      if (departamentos.length > 0 && departamentos.length !== undefined) {
          let nombre = []; let id = [];
          for (const i in departamentos) {
              nombre.push(departamentos[i].nombre)
              id.push(departamentos[i].id)
          }
          let departamentosQuery = { nombre: nombre, id: id }
          setDepartamentoList(departamentosQuery);
      }

        let ubicaciones =  chipUbicaciones.map(ubicacion => ubicacion.id);
        let formaciones = chipFormaciones.map(formacion => formacion.label);

      getPostulacionesConDocumentosActualizados({ idOferta: oferta.id, page, pageSize, formaciones, ubicaciones, anios })
        .then(res => res.data)
        .then(data => {
          let candidatosAux = []
          data.content.map(postulacion => candidatosAux.push(postulacion.candidato))
          setCandidatos(candidatosAux);
          setCandidatosTabla(data)

        })
        .catch(err => {
          Swal.fire(
            'Error',
            'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
            'error'
          )
        })
      setMostrarTabla(true);

    }

  }

  const handleCreate = () => {
    if (selectedCandidatos.length > 0 && oferta) {
      const data = {
        idsCandidatos: selectedCandidatos,
        idOferta: oferta.id
      }
      confirmarPreseleccion(token, data)
        .then(res => res.data)
        .then(data => {
          setLoading(false);
          Swal.fire(
            {
              icon: 'success',
              title: 'Candidatos preseleccionados'
            }
          )
          handleSubmit({})
        })
        .catch(err => {
          setLoading(false);
          Swal.fire(
            'Error',
            'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
            'error'
          )
        })

    }
  }

  const handleRechazar = () => {
    if (selectedCandidatos.length > 0 && oferta) {
      const data = {
        idsCandidatos: selectedCandidatos,
        idOferta: oferta.id
      }
      rechazarCandidato(data)
        .then(res => res.data)
        .then(data => {
          setLoading(false);
          Swal.fire(
            {
              icon: 'success',
              title: 'Candidatos rechazados'
            }
          )

          handleSubmit({});
        })
        .catch(err => {
          setLoading(false);
          Swal.fire(
            'Error',
            'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
            'error'
          )
        })

    }
  }
  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant='h1'>Preseleccionar candidatos</Typography>
          <br></br>
          <Collapse in={!mostrarTabla}>
            <Typography variant="body1" className={classes.subtitleObligatorio}>
            Los campos con <span className={classes.color}>*</span> son obligatorios
            </Typography>
            <Typography variant='body1' className={classes.subtitle}>En esta página usted podrá seleccionar candidatos que hayan aplicado a ofertas por entidad. Deberá seleccionar la entidad, el proyecto y la oferta para poder aprobar o rechazar candidatos:</Typography>
            {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
            <br></br>
            <FiltroOferta setOferta={setOferta} setMostrarTabla={setMostrarTabla} setTexto={setTexto}/>
            <Grid container item xs={12} spacing={3} className={classes.button}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSubmit}
                disabled={!oferta}>
                Buscar
              </Button>
            </Grid>
          </Collapse>
          {mostrarTabla ? 
          <div>
            <div className={classes.breadcrumbsContainer}>
              <Breadcrumbs  separator={<NavigateNextIcon fontSize="small" />} aria-label="breadcrumb" className={classes.breadcrumbs}>
                <Typography color="textPrimary">{texto.entidad}</Typography>
                <Typography color="textPrimary">{texto.proyecto}</Typography>
                <Typography color="textPrimary">{texto.oferta}</Typography>
              </Breadcrumbs> 
              <IconButton aria-label="delete" className={classes.margin} onClick={()=>setMostrarTabla(false)}>
                <CloseIcon fontSize="small" />
              </IconButton>
            </div>
            <div className={classes.container} >
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button className={classes.button} variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Nivel educativo
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverFormacion(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Formación inicial
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.inputPading}
                                                    >
                                                        <Select
                                                            onChange={handleChange}
                                                            defaultValue="Pregrado"
                                                            name="formacionInicial"
                                                            value={filter.formacionInicial}
                                                        >
                                                            <MenuItem value={"Bachiller"}>Bachiller</MenuItem>
                                                            <MenuItem value={"Tecnico"}>Tecnico</MenuItem>
                                                            <MenuItem value={"Tecnologo"}>Tecnologo</MenuItem>
                                                            <MenuItem value={"Pregrado"}>Pregrado</MenuItem>
                                                            <MenuItem value={"Especializacion"}>Especializacion</MenuItem>
                                                            <MenuItem value={"Maestria"}>Maestria</MenuItem>
                                                            <MenuItem value={"Doctorado"}>Doctorado</MenuItem>
                                                            <MenuItem value={"Posdoctorado"}>Posdoctorado</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {FiltroFormacionInicial()}
                                            <Button disabled={formacionesInvalidas()} onClick={() => filtroFormaciones(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <div className={classes.containerFilter}>
                        <Paper component="ul" className={classes.rootChip}>
                            {chipFormaciones.map((data) => {
                                if (width > 960 && width < 1800) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "250px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 690 && width < 960) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "190px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 490 && width < 690) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "150px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width < 490) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "100px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                    style={{width: '280px'}}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                }
                            })}
                        </Paper>
                    </div>
                </div>
                
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button className={classes.button} variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Ubicación
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverUbicacion(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Departamento
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                                                        {DepartamentoAutoComplete()}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Ciudad
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                                                        {CiudadAutoComplete()}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Button disabled={ubicacionesInvalidas()} onClick={() => filtroUbicaciones(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <div className={classes.containerFilter}>
                        <Paper component="ul" className={classes.rootChip}>
                            {chipUbicaciones.map((data) => {
                                if (width > 960 && width < 1800) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "250px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteUbicacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 690 && width < 960) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "190px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteUbicacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 490 && width < 690) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "150px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteUbicacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width < 490) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "100px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteUbicacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    label={data.label}
                                                    onDelete={handleDeleteUbicacion(data)}
                                                    className={classes.chip}
                                                    style={{width: '280px'}}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                }
                            })}
                        </Paper>
                    </div>
                </div>
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button className={classes.button} variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Experiencia
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverAnios(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.titleCenter} variant="h5" color="textPrimary">
                                                        Años de experiencia
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.titleCenter} variant="h5" color="textPrimary">
                                                        Los años de experiencia se calcularán a partir de la fecha de 
                                                        expedición de la tarjeta profesional
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        defaultValue={anios}
                                                        onChange={handleAnios}
                                                        name='anios'
                                                        variant="outlined"
                                                        className={classes.inputPading}
                                                        id="anios"
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button disabled={aniosInvalidos()} onClick={() => filtroAnios(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <div className={classes.containerFilter}>
                        <Paper component="ul" className={classes.rootChip}>
                            {chipAnio.map((data) => {
                                if (width > 960 && width < 1800) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "250px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteAnio(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 690 && width < 960) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "190px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteAnio(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 490 && width < 690) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "150px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteAnio(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width < 490) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "100px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteAnio(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    label={data.label}
                                                    onDelete={handleDeleteAnio(data)}
                                                    className={classes.chip}
                                                    style={{maxWidth: '300px'}}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                }
                            })}
                        </Paper>
                    </div>
                </div>
            </div>
            <Grid className={classes.formText} container item xs={12}>
                <Grid item xs={5} align="right">
                    <Button onClick={() => buscarCandidatos()} variant="contained" color="primary">
                        Aplicar filtros
                    </Button>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5} align="left">
                    <Button size="small" variant="text"
                        onClick={() => {
                            setFilter({
                                formacionInicial: 'Pregrado',
                                nombrePregrado: '',
                                nombrePosgrado: ''
                            });
                            setAnios("")
                            setChipFormaciones([]);
                            setChipUbicaciones([]);
                            setChipAnio([])
                            buscarCandidatos()
                        }} > Limpiar filtros</Button>
                      </Grid>
                  </Grid>
          </div>
          : null }
          <Grid container className={classes.table}>
            <Collapse in={mostrarTabla}>
              {mostrarTabla &&
                < PostuladosTable
                  token={token}
                  idOferta={oferta.id}
                  candidatos={candidatos}
                  dataQuery={candidatosTabla}
                  selectedCandidatos={selectedCandidatos}
                  handleSubmit={handleSubmit}
                  classes={classes}
                  setSelectedCandidatos={setSelectedCandidatos}
                  handleCreate={handleCreate}
                  handleRechazar={handleRechazar}
                  setLoading={setLoading}
                  loading={loading}
                  handleOpen={handleOpen}
                  anios={anios}
                  formaciones={chipFormaciones}
                  ubicaciones={chipUbicaciones}
                  />}
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
      <ModalInfo candidato={detailsCandidato} handleClose={handleClose} open={open} />
    </div>
  );
}


const ModalInfo = ({ candidato, handleClose, open }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >

    <CandidatoInfoRef candidato={candidato} token={token} handleClose={handleClose} />


  </Modal>
)

