import React, { useState, useEffect } from 'react';
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Button, Modal, Card, CardContent, Grid, Typography, Collapse, TextField } from '@material-ui/core';
import PreseleccionadosTable from './PreseleccionadosTable'
import { getContratantes } from '../../../helpers/contratante/contratanteHelper';
import { getPostulacionesConPreselecion } from '../../../helpers/postulacion/postulacionHelper';
import { aprobarPreseleccion, rechazarPreseleccion } from '../../../helpers/preempleado/preempleadoHelper';
import { FiltroOferta } from '../../../components/FiltroOferta';
import Swal from "sweetalert2"
import CandidatoInfoRef from './CandidatoInfoRef';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),

  },
  table: {
    width: "100%",
    minWidth: 650,
    display: "block"
  },
  textFieldRoot: {
    width: "70%",
    height: "15%",
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginTop: '20px'
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
    marginTop: '30px',
  },
  formText: {
    margin: 15,
    paddingTop: 5,
    paddingLeft: theme.spacing(8),
    width: "70%",
    height: "15%",
  },
  inputPading: {
    padding: 5,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  ol: {
    paddingLeft: theme.spacing(8),
    marginBottom: '40px',
  }
}));


const tokenSession = sessionStorage.getItem('token');
var token;
if (tokenSession !== null) {
  var CryptoJS = require("crypto-js");
  var bytes = CryptoJS.AES.decrypt(tokenSession, 'S661S-CR1PT0_S3CUR1T1_4DM1N');
  token = 'Bearer ' + JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
} else {
  token = '';
}

const ModalInfo = ({ candidato, handleClose, open }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >

    <CandidatoInfoRef candidato={candidato} token={token} handleClose={handleClose} />


  </Modal>
)
const swalErr = (err) => {
  Swal.fire(
    'Error',
    'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
    'error'
  )
}

export default function Preseleccion(props) {
  const [redirect, setRedirect] = React.useState(false)
  useEffect(() => {
    getContratantes(token)
      .then(res => res.data)
      .then(setRedirect(false))
      .catch(err => {
        setRedirect(true)
      })
  }, [])

  const classes = useStyles();
  const [oferta, setOferta] = useState(null)
  const [mostrarTabla, setMostrarTabla] = useState(false)
  const [candidatos, setCandidatos] = useState([])
  const [candidatosTabla, setCandidatosTabla] = useState({ content: [], size: 10 })
  const [selectedCandidatos, setSelectedCandidatos] = useState([])
  const [detailsCandidato, setDetailsCandidato] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const handleOpen = (preseleccionado) => {
    setDetailsCandidato(preseleccionado);
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const handleSubmit = ({ page = 0, pageSize = 10 }) => {
    if (oferta) {
      getPostulacionesConPreselecion({ idOferta: oferta.id, page, pageSize })
        .then(res => res.data)
        .then(data => {
          let candidatosAux = []
          data.content.map(preempĺeado => {
            preempĺeado.ciudad = preempĺeado.candidato.ciudad
            delete preempĺeado.candidato;
            candidatosAux.push(preempĺeado)
            return null
          })
          setCandidatos(candidatosAux);
          setCandidatosTabla(data)

        })
        .catch(err => swalErr(err))
      setMostrarTabla(true);

    }

  }

  const handleCreate = () => {
    if (selectedCandidatos.length > 0 && oferta) {
      const data = {
        idsPreempleados: selectedCandidatos,
      }
      aprobarPreseleccion(token, data)
        .then(res => res.data)
        .then(dataQuery => {
          setLoading(false);
          Swal.fire(
            {
              icon: 'success',
              title: 'Preseleccionados Aprobados'
            }
          )
          handleSubmit({})
        })
        .catch(err => {
          setLoading(false);
          swalErr(err)
        })

    }
  }

  const handleRechazar = () => {
    if (selectedCandidatos.length > 0 && oferta) {
      const data = {
        idsPreempleados: selectedCandidatos,
      }
      rechazarPreseleccion(token, data)
        .then(res => res.data)
        .then(dataQuery => {
          setLoading(false);
          Swal.fire(
            {
              icon: 'success',
              title: 'Preseleccionados rechazados'
            }
          )

          handleSubmit({});
        })
        .catch(err => {
          setLoading(false);
          swalErr(err)
        })
    }
  }
  return (
    <div>
      <Card className={classes.root}>
        <CardContent>
          <Typography variant='h1'>Aprobar candidatos Preseleccionados</Typography>
          <Typography variant='h6' className={classes.subtitle}>
            En esta página usted podrá seleccionar candidatos que hayan aplicado a ofertas por entidad, para esto usted debe:
          </Typography>
          <ol className={classes.ol}>
            <li>Seleccionar entidad</li>
            <li>Seleccionar proyecto</li>
            <li>Seleccionar oferta</li>
            <li>Presionar el botón buscar</li>
            <li>Seleccionar los preseleccionados a aprobar o rechazar</li>
          </ol>
          {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
          <FiltroOferta setOferta={setOferta} />
          <Grid container item xs={12}>
            <Grid item xs={4}>
              <Typography className={classes.formText} variant="body1" color="textPrimary">Ciudad</Typography>
            </Grid>
            <Grid item xs={6}>
              <TextField
                disabled
                fullWidth
                value={oferta ? oferta.ciudad.nombre + " - " + oferta.ciudad.departamento.nombre : ""}
                variant="outlined"
                className={classes.inputPading}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!oferta}>
              Buscar
            </Button>
          </Grid>
          <Grid container className={classes.table}>
            <Collapse in={mostrarTabla}>
              {mostrarTabla &&
                < PreseleccionadosTable
                  candidatos={candidatos}
                  dataQuery={candidatosTabla}
                  selectedCandidatos={selectedCandidatos}
                  handleSubmit={handleSubmit}
                  classes={classes}
                  setSelectedCandidatos={setSelectedCandidatos}
                  handleCreate={handleCreate}
                  handleRechazar={handleRechazar}
                  setLoading={setLoading}
                  loading={loading}
                  handleOpen={handleOpen} />}
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
      <ModalInfo candidato={detailsCandidato} handleClose={handleClose} open={open} />
    </div>
  );
}




