import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, TextField } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    internalCard: {
        margin: 15,
        padding: 15,
    },
    centralButton: {
        margin: 15,
    },
    hoverPointer: {
        cursor: "pointer",
    },
    notDataText: {
        margin: 15,
    },
    table: {
        minWidth: 700,
    },
    cell: {
        maxWidth: 100,
        overflowX: 'auto',
    },
    textFieldRoot: {
		marginTop: "13px",
	},
    menuItem:{
        width: '100%'
    },
    formText: {
        margin: 15,
        paddingTop: 5,
        paddingLeft: theme.spacing(8),
        width: "70%",
        height: "15%",
    },
    inputPading: {
        marginTop: 10,
    },
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
    },
}));

const FiltroCuentaCobro = ({ filter, setFilter }) => {


    const classes = useStyles();

    const handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value,
        })
    }

    return (
        <Grid container item xs={12}>
            <Grid container item xs={12} spacing={2}>
                <Grid item xs={4}>
                    <Typography className={classes.formText} variant="body1" color="textPrimary">
                        Número de documento
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <TextField
                        fullWidth
                        type="number"
                        value={filter.numeroDocumento}
                        onChange={handleChange}
                        name="numeroDocumento"
                        variant="outlined"
                        className={classes.inputPading}
                        id="numeroDocumento"
                    />
                </Grid>
            </Grid>
        </Grid>

    )
}
export default FiltroCuentaCobro

