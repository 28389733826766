import React, { useState, useEffect } from 'react';
import { Button, Typography, Grid, Collapse, IconButton, MenuItem, CardContent, Card } from '@material-ui/core';
import { makeStyles, lighten } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import CloseIcon from '@material-ui/icons/Close';
import Swal from 'sweetalert2';
import TypeForm from '../../../../components/Campos/TypeForm'
import withReactContent from 'sweetalert2-react-content';
import TypeText from '../../../../components/Campos/TypeText';
import { IS_NOT_EMPTY } from '../../../../constants/MensajeHelper';
import validate from 'validate.js';
import { savePerfil } from '../../../../helpers/perfilHelper/perfilHelper';
import { getOfertas } from '../../../../helpers/oferta/ofertaHelper';
import { Redirect, useHistory } from 'react-router';

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: 25,
    backgroundColor: lighten(theme.palette.background.paper, 0.1),

  },
  centerItem: {
    justifyContent: "center"
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    width: "50%",
    height: "15%",
  },
  hideline: {
    backgroundColor: 'red'
  },
  errorHelper: {
    marginLeft: '14px',
    marginRight: '14px'
  },
  color: {
    color: 'red'
  }
}));

const length = { maximum: 150, message: "no puede sobrepasar los 150 caracteres" }

const mensajeHelper = {
  nombre: {
    presence: IS_NOT_EMPTY,
    length: length,
  },
  fechaInicioContrato: {
    presence: IS_NOT_EMPTY,
  },
  fechaInicioActividades: {
    presence: IS_NOT_EMPTY,
  },
  objeto: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 2500, message: "no puede sobrepasar los 2500 caracteres" }
  },
  descripcionObligacion: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 22500, message: "no puede sobrepasar los 22500 caracteres" },
  },
  idOferta:{
    presence: IS_NOT_EMPTY,
    exclusion:{
      within: ["No se encontraron Ofertas"],
      message: "No se encontraron Ofertas"
    }
  },

}

const useSingleton = (callback = ()=>{ })=>{
  const hasBeenCalled = React.useRef(false);
  if(hasBeenCalled.current) return;
  callback();
  hasBeenCalled.current = true;
}

const PerfilContrato = (props, { variant = 'default',  }) => {
  const [ofertas, setOfertas] = useState([]);
  const fechaMax = "2500-01-01";
  const [open, setOpen] = useState(false);
  const classes = useStyles({ variant });
  const [redirect, setRedirect] = useState(false)
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      nombre: '',
      fechaInicioContrato: '',
      fechaInicioActividades: '',
      objeto: '',
      descripcionObligacion: '',
      idOferta: ''
    },
    touched: {
      nombre: false,
      fechaInicioContrato: false,
      fechaInicioActividades: false,
      objeto: false,
      descripcionObligacion: false,
      idOferta: false
    },
    errors: {
      nombre: false,
      fechaInicioContrato: false,
      fechaInicioActividades: false,
      objeto: false,
      descripcionObligacion: false,
      idOferta: false
    }
  });
  const history = useHistory();

  const perfil = {
    nombre: formState.values.nombre,
    fechaInicioContrato: formState.values.fechaInicioContrato,
    fechaInicioActividades: formState.values.fechaInicioActividades,
    objeto: formState.values.objeto,
    descripcionObligacion: formState.values.descripcionObligacion,
    idOferta: formState.values.idOferta
  }

  useEffect(() => {
    const errors = validate(formState.values, mensajeHelper);
    setFormState((formState) => ({
      ...formState,
      isValid: errors ? false : true,
      errors: errors || {},
    }));

  }, [formState.values]);

  useSingleton(async () => {
    await getOfertas()
      .then((response) => response.data)
      .then((data) => setOfertas(data))
      .catch(()=> setRedirect(true));
  });

  const MySwal = withReactContent(Swal);

  const sweetAlerts = () => {
    MySwal.fire({
      icon: 'success',
      title: 'Perfil del contrato creado'
    });
  }

  const sweetAlertsError = () => {
    MySwal.fire({
      icon: 'Error',
      title: 'Ocurrio un error',
      text: 'Ocurrió un error al procesar la solicitud, por favor intente más tarde'
    });
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();

    formData.append('perfil', new Blob([JSON.stringify(perfil)], { type: "application/json" }));
    
    await savePerfil(formData)
      .then(function (response) {
        sweetAlerts();
        history.push("/admin_page")
      })
      .catch(function (response) {
        sweetAlertsError();
      }); 
  }

  useEffect(()=>{
    let fechaInicioActividades = new Date(formState.values.fechaInicioActividades).getTime();
    let fechaInicioContrato = new Date(formState.values.fechaInicioContrato).getTime();

    if(fechaInicioActividades > fechaInicioContrato){
      setFormState({...formState, values:{...formState.values, fechaInicioContrato: ''}})
    }
    
  },[formState.values.fechaInicioActividades, formState.values.fechaInicioContrato, formState ])

  return (
    <div>
      {redirect ? <Redirect push to={{ pathname:'/signin'}} /> : null}
      <Card className={classes.root}>
        <CardContent>
          <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Typography variant='h1' > Crear un perfil del contrato</Typography>
          <Typography variant='body1' > Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
          <Typography variant='body1' > Define el perfil del contrato.</Typography>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left} >
              <Typography variant='body1' className={classes.typography}>Nombre del cargo<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText id='nombre' formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ maxLength: 151 }} multiline fullWidth />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Fecha de firma del contrato<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7} className={classes.left}>
              <TypeText id='fechaInicioActividades' formState={formState} setFormState={setFormState} helperText={mensajeHelper} type="date" inputProps={{ max: fechaMax }} fullWidth />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Fecha de inicio del contrato<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText id='fechaInicioContrato' formState={formState} setFormState={setFormState} helperText={mensajeHelper} type="date" inputProps={{ max: fechaMax }} fullWidth />
              
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Objeto<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText id='objeto' formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ maxLength: 2501 }} multiline fullWidth/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Obligación<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText
                id="descripcionObligacion"
                formState={formState}
                setFormState={setFormState}
                helperText={mensajeHelper}
                inputProps={{ maxLength: 22501 }}
                multiline
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Oferta<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeForm id='idOferta' formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{ maxLength: 201 }} fullWidth >
                <MenuItem value = ""/>
                {ofertas.length > 0 ?
                  ofertas.map((oferta)=>{
                    return (<MenuItem key={oferta.id} value={oferta.id}>{oferta.nombre}</MenuItem>)
                  })
                  : <MenuItem value="No se encontraron Ofertas">No se encontraron ofertas</MenuItem>
                }
              </TypeForm>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Button variant="contained" color="primary" type="submit" disabled={!formState.isValid}>
              Crear Perfil
            </Button>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Collapse in={open}>
              <Alert
                action={
                  <IconButton
                    arial-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      setOpen(false);
                    }
                    }>
                    <CloseIcon fontSize="inherit" />
                  </IconButton>
                }
                variant="filled"
                severity="error"
              >
                ¡Aún hay campos en rojo!
              </Alert>
            </Collapse>
          </Grid>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default PerfilContrato;