import React from 'react';
import { Redirect, Route, Switch, } from 'react-router';
import MainPage from './Pages/MainPage';
import Error404 from './Pages/404';
import Login from './Auth/Login';
import ForgotPasswordPage from './Auth/ForgotPassword';
import Validate from './Auth/ValidateUserForm'; 
import VerDetalleOferta from './Pages/Oferta/VerDetalleOferta';
import TablaOferta from './Pages/Oferta/TablaOfertas'; 
import CrearOfertas from './Pages/Oferta/CrearOfertas'; 
import IngresarFormacion from './Pages/Candidato/IngresarFormacion';
import { useLocation } from 'react-router-dom';
import IngresarCandidato from './Pages/Candidato';
import PreseleccionarCandidato from './Pages/Preseleccion';
import IngresarExperiencia from './Pages/Candidato/IngresarExperiencia';
import ActualizarDatos from './Pages/Candidato/ActualizarDatos';
import ActualizarFormacion from './Pages/Candidato/EditarFormacion';
import ActualizarExperiencia from './Pages/Candidato/EditarExperiencia';
import FormularioEjemplo from '../constants/Formularios'
import { useSelector } from 'react-redux';
import Contratacion from './Pages/Contratacion';
import PerfilContrato from './Pages/Contrato/perfilContrato';
import BuscarYVincular from './Pages/Candidato/BuscarYVincular';
import ConsultaCandidatos from './Pages/Candidato/ConsultaCandidatos';
import PreseleccionAprobar from './Pages/PreseleccionAprobar';
import DesvincularContratado from './Pages/Candidato/DesvincularContratado';
import CandidatosSearchTable from './Pages/Candidato/BuscarYVincular';
import AprobarCuentaCobro from './Pages/Contratado/TablaCuentaCobro'
import BlackList from './Pages/BlackList'
import MostrarDatos from './Pages/Candidato/MostrarDatos';
import CrearCandidato from './Pages/Candidato/CrearCandidato';
import EditarOfertas from './Pages/Oferta/EditarOfertas';

const RestrictedRoute = ({ component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const location = useLocation();
  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/main_page'} />;
  }
  return (
    <React.Fragment>
      <Switch>
        <Route path="/buscar_y_vincular_candidato" component={BuscarYVincular}/>
        <Route path="/actualizar_formacion" component={ActualizarFormacion} /> 
        <Route path="/postulacion/update" component={ActualizarDatos} /> 
        <Route path="/aprobar_preseleccionados" component={PreseleccionAprobar}/>
        <Route path="/consulta_candidatos" component={ConsultaCandidatos} /> 
        <Route path="/actualizar_experiencia" component={ActualizarExperiencia} />
        <Route path="/detalle_oferta" component={VerDetalleOferta} />
        <Route path="/main_page" component={MainPage} />
        <Route path="/signin" component={Login} />
        <Route path="/account/reset/finish" component={Validate} /> 
        <Route path="/forgot_password" component={ForgotPasswordPage} />
        <Route path="/tabla_oferta" component={TablaOferta} /> 
        <Route path="/crear_oferta" component={CrearOfertas} /> 
        <Route path="/editar_oferta" component={EditarOfertas} /> 
        <Route path="/admin_page" component={TablaOferta} />
        <Route path="/preseleccionar_candidato" component={PreseleccionarCandidato} /> 
        <Route path="/ingresar_candidato" component={IngresarCandidato} />
        <Route path="/ingresar_formacion" component={IngresarFormacion} />
        <Route path="/ingresar_experiencia" component={IngresarExperiencia} />
        <Route path="/mostrar_datos" component={MostrarDatos} />
        <Route path="/crear_candidato" component={CrearCandidato} />
        <Route path="/perfil_contrato" component={PerfilContrato}/>
        <RestrictedRoute path="/contratacion" component={Contratacion} />
        <Route path="/form_ejemplo" component={FormularioEjemplo} />
        <Route path="/desvincular_contratado" component={DesvincularContratado} />
        <Route path="/buscar_y_vincular_candidato" component={CandidatosSearchTable} />
        <Route path="/aprobar_cuenta_cobro" component={AprobarCuentaCobro}/>
        <Route path="/lista_restrictiva" component={BlackList}/>
        <Route component={Error404} />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
