import React from 'react';
import { Typography, Button, Grid, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { errorAlert } from '../../../../helpers/swalHelper/swalHelper'
import { getDocumentosCuentaCobro } from '../../../../helpers/cuentaCobro/cuentaCobroHelper';

const useStyles = makeStyles((theme) => ({
    boxInside: {
        width: "95%",
        border: '2px solid #3f51b5',
        marginTop: 20,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10
    },
    buttonDownload: {
      marginTop: '9px',
      marginBottom: '9px'
    },
    button: {
        margin: theme.spacing(4),
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: '20px',
        marginTop: '30px',
    },
    modal: {
        position: "absolute",
        width: "90%",
        height: "90%",
        maxHeight: "750px",
        maxWidth: "1500px",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        overflowY: 'auto',
        overflowX: 'hidden'
    },
    internalCard: {
        margin: 25,
        padding: 15,
    },
    typography: {
        marginTop: 10
    },
    hoverPointer: {
        cursor: "pointer",
        color: "#0000EE"
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const CuentaCobroInfo = ({ cuentaCobro, handleClose, aprobar }) => {
    const numberFormat = new Intl.NumberFormat('es-CO');
    const [modalStyle] = React.useState(getModalStyle);
    const handleDownload = (filename) => {
        getDocumentosCuentaCobro({idContrato:cuentaCobro.contrato.id, fileName:filename})
            .then(res => res.data)
            .then(data => {
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.setAttribute("type", "hidden");
                link.href = "data:text/plain;base64," + data;
                link.download = filename;
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => errorAlert())
    }

    const handleAprobar = () => {
        aprobar(cuentaCobro.id)
    }

    const classes = useStyles();

    return (
        <div style={modalStyle} className={classes.modal}>
            <Typography variant="h1" align="left" color="textPrimary" className={classes.typography}>
              Aprobar cuenta de cobro {cuentaCobro.id} de {cuentaCobro.contrato.preempleado.nombres + " " + cuentaCobro.contrato.preempleado.apellidos}
            </Typography>
            <Typography style={{marginTop: 10}}>
                            ¿Deseas aprobar la cuenta número {cuentaCobro.id} ? 
            </Typography>
            <Card className={classes.internalCard}>
              <Typography variant="h4" align="left" color="textPrimary">
                Tipo de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.contrato.preempleado.tipoDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Número de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.contrato.preempleado.numeroDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Número de contrato:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.contrato.numeroContrato}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Nombre:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
              {cuentaCobro.contrato.preempleado.nombres + " " + cuentaCobro.contrato.preempleado.apellidos}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Perfil:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.contrato.perfil.nombre}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Mes a cobrar:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.mesCobro}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Valor:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                $ {numberFormat.format(cuentaCobro.valorCobro)}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Concepto:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {cuentaCobro.concepto}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Obligación:
              </Typography>
              <Typography style={{wordBreak: 'break-all'}} variant="body2" align="left" color="textPrimary">
                {cuentaCobro.obligacionesMes}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Evidencia Obligación:
              </Typography>
              <Button variant="contained" size='small' color="secondary"
                  className={classes.buttonDownload}
                  onClick={() => handleDownload(cuentaCobro.evidenciaObligacion)}
                > Descargar</Button>
              <Typography variant="h4" align="left" color="textPrimary">
                Planilla de Seguridad Social:
              </Typography>
              <Button variant="contained" size='small' color="secondary"
                  className={classes.buttonDownload}
                  onClick={() => handleDownload(cuentaCobro.planillaSeguridadSocial)}
                >Descargar</Button>
            </Card>
            <Grid container item xs={12} spacing={3} className={classes.button}>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                {" "}
                Cancelar
              </Button>
              <Button
                onClick={handleAprobar}
                variant="contained"
                color="primary"
              >
                {" "}
                Aprobar
              </Button>
            </Grid>
        </div>
    )
}
const CuentaCobroInfoRef = React.forwardRef((props, ref) => (<CuentaCobroInfo innerRef={ref} {...props} />))
export default CuentaCobroInfoRef;
