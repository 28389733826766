import React, { useState } from "react";
import { makeStyles, lighten } from '@material-ui/core/styles';
import { Box, Card } from '@material-ui/core';
import CrearCandidato from './CrearCandidato'
import EditarCandidato from './EditarCandidato';
import CrearFormacion from './IngresarFormacion';
import CrearExperiencia from './IngresarExperiencia';
import MostrarDatos from './MostrarDatos';
const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  authWrap: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    padding: 20,
    [theme.breakpoints.up('sm')]: {
      padding: 40,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 0,
      zIndex: 1,
      width: '100%',
      height: '100%',
    },
  },
  authCard: {
    position: 'relative',
    zIndex: 3,
    maxWidth: props => (props.variant === 'default' ? '850px' : '550px'),
    width: '100%',
    boxShadow: '0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.12), 0px 1px 5px rgba(0, 0, 0, 0.2)',
    borderRadius: 4,
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.secondary,
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
    [theme.breakpoints.up('xl')]: {
      maxWidth: props => (props.variant === 'default' ? '1050px' : '750px'),
    },
  },
}));

const Candidato = ({variant = 'standard'}) => {
  const classes = useStyles({variant});

  const [info, setInfo] = useState(0)         //Cambiar a Formación y Experiencia
  const [idCandidato, setIdCandidato] = useState()
  const [candidatoQuery, setCandidatoQuery] = useState({});
  function cambiarInfo(param) {
    switch(param){
      case 0:
        return <CrearCandidato setIdCandidato={setIdCandidato} setInfo={setInfo} />;
      case 1:
        return <MostrarDatos idCandidato={idCandidato} setCandidatoQuery={setCandidatoQuery} setInfo={setInfo} />;
      case 2:
        return <EditarCandidato idCandidato={idCandidato} candidatoQuery={candidatoQuery} setInfo={setInfo} />;
      case 3:
        return <CrearFormacion idCandidato={idCandidato} setInfo={setInfo}/>;
      case 4:
        return <CrearExperiencia idCandidato={idCandidato} setInfo={setInfo}/>;
      default:
        return null;
    }
  }
  if(info === 0){
    return(
    <Box className={classes.authWrap}>
      <Box className={classes.authCard}>
        <Card className={classes.root}>
          {cambiarInfo(info)}
        </Card>
      </Box>
    </Box>
    );
  } else {
    return(
      <Card className={classes.root}>
          {cambiarInfo(info)}
      </Card>
    );
  }
  
}

export default Candidato;