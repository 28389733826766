import React from 'react'
import { Typography, Grid, Button, MenuItem, Select } from '@material-ui/core';
import Swal from "sweetalert2"
import MaterialTable from '@material-table/core';
import Input from '@material-ui/core/Input';
import { spanishTextTable } from '../../../constants/AppConstants';
const listCandidatos = (candidatos, selectedCandidatos) => {
	let html = ""
	for (const i in candidatos) {
		for (const j in selectedCandidatos) {
			if (candidatos[i].id === selectedCandidatos[j]) {
				html = html + " · " + candidatos[i].nombres + ' ' + candidatos[i].apellidos + ".<br/>"
			}
		}
	}
	return html
}
const confirmSelected = (handleCreate, setLoading, candidatos, selectedCandidatos) => {
	const html = listCandidatos(candidatos, selectedCandidatos)
	Swal.fire({
		title: '¿Aprobar los preseleccionados?',
		html: "Los candidatos que seleccionó son: <br/>" + html,
		showCancelButton: true,
		confirmButtonText: 'Aprobar',
	}).then((result) => {
		if (result.isConfirmed) {
			handleCreate();
			setLoading(true);
		}
	})
}

const rejectSelected = (handleRechazar, setLoading, candidatos, selectedCandidatos) => {
	const html = listCandidatos(candidatos, selectedCandidatos)
	Swal.fire({
		title: '¿Rechazar los preseleccionados?',
		html: 'Los candidatos que seleccionó son: <br />' + html,
		showCancelButton: true,
		confirmButtonText: 'Rechazar',
	}).then((result) => {
		if (result.isConfirmed) {
			handleRechazar();
			setLoading(true);
		}
	})
}


const PreseleccionadosTable = ({
	candidatos,
	dataQuery,
	handleSubmit,
	classes,
	selectedCandidatos,
	setSelectedCandidatos,
	handleCreate,
	handleRechazar,
	loading,
	setLoading,
	handleOpen }) => {
	const [pageNumer, setPageNumber] = React.useState(dataQuery.number + 1)
	const columns = [
		{ title: "Cédula", field: "numeroDocumento" },
		{
			title: "Nombre", field: "nombres", render: rowData => {
				return (
					`${rowData.nombres} ${rowData.apellidos}`
				)
			}
		},
		{ title: "Telefono", field: 'telefonoFijo' },
		{ title: "Correo", field: 'correo' },
		{ title: "Ciudad", field: 'ciudad.nombre' },
		{
			title: "Detalle", render: rowData => {
				return (
					<Button color="secondary" onClick={() => handleOpen(rowData)}>Ver detalle</Button>
				)
			}
		}
	]
	return (
		<div>
			{candidatos.length > 0
				? <div>
					<MaterialTable
						title="Escoger Preseleccionados"
						data={candidatos}
						columns={columns}
						isLoading={loading}
						onSelectionChange={
							(rows) => {
								let ids = []
								rows.forEach(candidato => {
									ids.push(candidato.id)
								})
								setSelectedCandidatos(ids)
							}}
						options={{ selection: true, paging: false }}
						localization={spanishTextTable}
					/>
					<div className="pagination">
						<Button onClick={() => { handleSubmit({ page: 0 }) }} disabled={dataQuery.first}>
							{'<<'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.number - 1 }) }} disabled={dataQuery.first}>
							{'<'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.number + 1 }) }} disabled={dataQuery.last}>
							{'>'}
						</Button>{' '}
						<Button onClick={() => { handleSubmit({ page: dataQuery.totalPages - 1 }) }} disabled={dataQuery.last}>
							{'>>'}
						</Button>{' '}
						<span>
							Página{' '}
							<strong>
								{dataQuery.number + 1} de {dataQuery.totalPages}
							</strong>{' '}
						</span>
						<span>
							| Ir a la página:{' '}
							<Input
								type="number"
								value={pageNumer}
								onChange={e => {
									if (e.target.value < dataQuery.totalPages && e.target.value > 0) {
										setPageNumber(e.target.value)
										handleSubmit({ page: Number(e.target.value) })
									}
								}}
								style={{ width: '100px' }}
								inputProps={{ min: 0 }}
								error={pageNumer < 0}
							/>
						</span>{' '}
						<Select
							value={dataQuery.size}
							onChange={e => {
								handleSubmit({ page: dataQuery.number, pageSize: Number(e.target.value) })
							}}
						>
							{[10, 20, 30, 40, 50].map(pageSizeMaper => (
								<MenuItem key={pageSizeMaper} value={pageSizeMaper}>
									Mostrar {pageSizeMaper}
								</MenuItem>
							))}
						</Select>
					</div>

					<Grid container item xs={12} spacing={3} className={classes.button}>
						<Button
							variant="contained"
							onClick={() => rejectSelected(handleRechazar, setLoading, candidatos, selectedCandidatos)}
							disabled={loading || (selectedCandidatos.length < 1)}
							color="secondary">
							Rechazar preseleccionados
						</Button>
						<Button
							variant="contained"
							onClick={() => confirmSelected(handleCreate, setLoading, candidatos, selectedCandidatos)}
							disabled={loading || (selectedCandidatos.length < 1)}
							color="primary">
							Aprobar preseleccionados
						</Button>
					</Grid>
				</div>
				: <Typography variant="h5" color="textPrimary"> No se encontraron candidatos </Typography>
			}
		</div>
	)
}




export default PreseleccionadosTable
