import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Select from '@material-ui/core/Select';
import Card from '@material-ui/core/Card';
import { Programas } from '../../../../constants/AppConstants';
import { Autocomplete, Alert } from '@material-ui/lab';
import { Redirect } from 'react-router'
import { makeStyles } from '@material-ui/core/styles';
import { FormControl, MenuItem, Modal, TextField, Tooltip } from '@material-ui/core';
import { FiltroOferta } from '../../../../components/FiltroOferta'
import Table from './Tabla'
import { getDepartamentos, getDepartamentosId } from '../../../../helpers/departamentos/departamentosHelper';
import { postPostulacion } from '../../../../helpers/postulacion/postulacionHelper';
import { filtro } from '../../../../helpers/filtros/filtroBuscarYVincular';
import Box from '@material-ui/core/Box';
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Chip from '@material-ui/core/Chip';
import Paper from '@material-ui/core/Paper';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../../utils/orderHelper';
import { dateFormat } from "../../../../constants/AppConstants";
import { Link } from "react-router-dom";

const useStyles = makeStyles(theme => ({
    internalCard: {
        margin: 25,
        padding: 15,
    },
    centralButton: {
        margin: 15,
    },
    hoverPointer: {
        cursor: "pointer",
    },
    notDataText: {
        margin: 15,
    },
    cell: {
        maxWidth: 100,
        overflowX: 'auto',
    },
    formText: {
        margin: 15,
        paddingTop: 5
    },
    inputPading: {
        padding: 5,
    },
    modal: {
        position: 'absolute',
        width: '90%',
        height: "85%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        overflow: 'auto',
    },
    flex: {
        display: "flex",
        alignItems: "center",
        flexWrap: "wrap"
    },
    rootChip: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        listStyle: 'none',
        padding: theme.spacing(0.5),
        margin: 0,
    },
    chip: {
        margin: theme.spacing(0.5),
    },
    container: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-between',
    },
    containerFilter: {
        display: 'flex',
        flexWrap: 'wrap',
        alignItems: 'center',
    },
    filter: {
        display: 'flex',
        flexDirection: 'column',
        flexWrap: 'wrap',
        alignItems: 'center',
        width: '32%',
    },
    button: {
        '@media screen and (max-width: 500px)': {
            maxWidth: '120px'
        }
    },
    containerButton: {
        height: '80px',
        display: 'flex',
        alignItems: 'center'
    },
    titleCenter: {
        margin: 15,
        paddingTop: 5,
        textAlign: 'center'
    }
}));

function getModalStyle() {
    const top = 50
    const left = 50

    return {
        top: `${top}%`,
        left: `${left}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useSingleton = (callBack = () => {
    //Blank function no need to do anything
}) => {
    const hasBeenCalled = React.useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const useWindowDimensions = () => {
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        function handleResize() {
            setWidth(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [])

    return width;
}

const CandidatosSearchTable = (props) => {
    const classes = useStyles();
    const [candidatos, setCandidatos] = React.useState([])
    const [selectedCandidatos, setSelectedCandidatos] = React.useState([])
    const [alertModal, setAlertModal] = React.useState('')
    const [open2, setOpen2] = React.useState(false)
    const [ciudadList, setCiudadList] = React.useState({ nombre: [], id: [] });
    const [departamentoList, setDepartamentoList] = React.useState({ nombre: [], id: [] });
    const [redirect, setRedirect] = React.useState(false);
    const [modalStyle] = React.useState(getModalStyle);
    const [alertError, setAlertError] = React.useState([])
    const [alertSuccess, setAlertSuccess] = React.useState([])
    const [filter, setFilter] = React.useState({
        formacionInicial: 'Pregrado',
        nombrePregrado: '',
        nombrePosgrado: '',
    })
    const [anios, setAnios] = useState("");
    const [ciudad, setCiudad] = useState({
        id: "",
        nombre: ""
    })
    const [departamento, setDepartamento] = useState("");
    const [chipUbicaciones, setChipUbicaciones] = React.useState([]);
    const [chipFormaciones, setChipFormaciones] = React.useState([]);
    const [chipAnio, setChipAnio] = React.useState([]);
    const width = useWindowDimensions();
    const [oferta, setOferta] = React.useState('')
    const initialState = async ({ page = 0, pageSize = 10, filtros }) => {
        let formaciones = filtros ? filtros.formaciones : [];
        let ubicaciones = filtros ? filtros.ubicaciones : [];
        let anios = filtros ? filtros.anios : [];
        const candidatoQuery = await filtro({ formaciones, ubicaciones, anios ,page: page, pageSize: pageSize })
            .then(response => response.data)
            .catch(err => {
                setRedirect(true)
            })
        candidatoQuery.filtros = filtros;
        setCandidatos(candidatoQuery)
        const departamentos = await getDepartamentos()
            .then(response => sortDepartamentosAlphabetically(response.data))
            .catch(err => err)
        if (departamentos.length > 0 && departamentos.length !== undefined) {
            let nombre = []; let id = [];
            for (const i in departamentos) {
                nombre.push(departamentos[i].nombre)
                id.push(departamentos[i].id)
            }
            let departamentosQuery = { nombre: nombre, id: id }
            setDepartamentoList(departamentosQuery);
        }
    }
    useSingleton(async () => {
        await initialState({})
    });
    const columns = [
        {
            Header: 'Nombre',
            accessor: 'nombreCompleto',
        },
        {
            Header: 'Formación Inicial',
            accessor: 'formacionInicial',
        },
        {
            Header: 'Nombre Formación Inicial',
            accessor: 'nombreFormacionInicial',
        },
        {
            Header: 'Pregrado',
            accessor: 'pregrado',
        },
        {
            Header: 'Posgrado',
            accessor: 'posgrado',
        },
        {
            Header: 'Nombre Posgrado',
            accessor: 'nombrePosgrado',
        },
        {
            Header: 'Tarjeta Profesional',
            accessor: 'tarjetaProfesional',
        },
        {
            Header: 'Fecha de expedición tarjeta profesional',
            accessor: 'expedicionTarjeta',
        },
        {
            Header: 'Ciudad',
            accessor: 'ciudad',
        },
    ]
    if (candidatos.content !== undefined) {
        if (candidatos.content.length > 0) {
            for (const i in candidatos.content) {
                if (candidatos.content[i].ciudad !== undefined && candidatos.content[i].ciudad.nombre !== undefined)
                    candidatos.content[i].ciudad = candidatos.content[i].ciudad.nombre;
                candidatos.content[i].nombreCompleto = candidatos.content[i].nombres + ' ' + candidatos.content[i].apellidos
                var formacion = { tarjetaProfesional: 'No', formacionInicial: '---', nombreFormacionInicial: '---', pregrado: '---', posgrado: '---', nombrePosgrado: '---', expedicionTarjeta: '---' }
                if (candidatos.content[i].formaciones.length > 0) {
                    for (const j in candidatos.content[i].formaciones) {
                        if (candidatos.content[i].formaciones[j].tipo === 'Bachiller' && formacion.formacionInicial !== 'Pregrado'
                            && formacion.formacionInicial !== 'Tecnico' && formacion.formacionInicial !== 'Tecnologo') {
                            formacion.formacionInicial = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombreFormacionInicial = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Tecnico' && formacion.formacionInicial !== 'Pregrado'
                            && formacion.formacionInicial !== 'Tecnologo') {
                            formacion.formacionInicial = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombreFormacionInicial = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Tecnologo' && formacion.formacionInicial !== 'Pregrado') {
                            formacion.formacionInicial = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombreFormacionInicial = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Pregrado') {
                            formacion.pregrado = candidatos.content[i].formaciones[j].nombre;

                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Especializacion' && formacion.posgrado !== 'Maestria'
                            && formacion.posgrado !== 'Doctorado' && formacion.posgrado !== 'Posdoctorado') {
                            formacion.posgrado = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombrePosgrado = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Maestria' && formacion.posgrado !== 'Doctorado'
                            && formacion.posgrado !== 'Posdoctorado') {
                            formacion.posgrado = candidatos.content[i].formaciones[j].tipo
                            formacion.nombrePosgrado = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Doctorado' && formacion.posgrado !== 'Posdoctorado') {
                            formacion.posgrado = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombrePosgrado = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tipo === 'Posdoctorado') {
                            formacion.posgrado = candidatos.content[i].formaciones[j].tipo;
                            formacion.nombrePosgrado = candidatos.content[i].formaciones[j].nombre;
                        }
                        if (candidatos.content[i].formaciones[j].tarjeta) formacion.tarjetaProfesional = 'Si';
                        if (candidatos.content[i].formaciones[j].fechaExpedicionTarjetaProfesional !== null)
                            formacion.expedicionTarjeta = dateFormat(candidatos.content[i].formaciones[j].fechaExpedicionTarjetaProfesional);
                    }
                }
                candidatos.content[i].formacionInicial = formacion.formacionInicial;
                candidatos.content[i].nombreFormacionInicial = formacion.nombreFormacionInicial;
                candidatos.content[i].pregrado = formacion.pregrado;
                candidatos.content[i].posgrado = formacion.posgrado;
                candidatos.content[i].nombrePosgrado = formacion.nombrePosgrado;
                candidatos.content[i].tarjetaProfesional = formacion.tarjetaProfesional;
                candidatos.content[i].expedicionTarjeta = formacion.expedicionTarjeta;

            }
        }
    }
    const handleChange = (e) => {
        setFilter({
            ...filter,
            [e.target.name]: e.target.value
        })
        if (e.target.name === "ciudad") {
            setCiudad({ id: e.target.value, nombre: e.target.nameCity })
        }
    }

    const handleAnios = (e) => {
        setAnios(e.target.value)
    }
    const handleClose2 = async () => {
        await initialState({})
        await setSelectedCandidatos([])
        setOferta('')
        setAlertModal('')
        setOpen2(false)
    }
    const vincular = async () => {
        for (const i in selectedCandidatos) {
            let body = {
                idOferta: oferta.id,
                idCandidato: selectedCandidatos[i].id,
                estado: 'Postulado'
            }
            await postPostulacion(body)
                .then(async response => {
                    if (response.status === 201) {
                        let candidato = 'Se vinculó exitosamente a: ' + selectedCandidatos[i].nombres + ' ' + selectedCandidatos[i].apellidos
                        let success = alertSuccess
                        success.push(candidato)
                        await setAlertSuccess(success)
                    }
                })
                .catch(async err => {
                    console.log(err.response)
                    let candidato;
                    if (err.response.status === 500) {
                        if (err.response.data.detail === "You have already applied to this offer") {
                            candidato = (
                                <span>
                                    {'El candidato ' +
                                        selectedCandidatos[i].nombres +
                                        ' ' +
                                        selectedCandidatos[i].apellidos +
                                        ' ya se vinculó previamente a la oferta, es necesario '}
                                    <Link to={"/crear_oferta"}>crear una oferta</Link> nueva
                                </span>
                            );
                        } else {
                            candidato = 'Ocurrió un error al vincular a: ' + selectedCandidatos[i].nombres + ' ' + selectedCandidatos[i].apellidos
                        }
                    }
                    let error = alertError
                    error.push(candidato)
                    await setAlertError(error)
                })
        }
        handleClose2()
    }
    const modalOpen2 = () => {
        if (selectedCandidatos.length > 0)
            setOpen2(true);
        else {
            let alert = ['Debes seleccionar candidatos para vincularlos a una oferta']
            setAlertError(alert)
        }
    }

    const filtroUbicaciones = (popupState) => {
        popupState.close()
        if (!chipUbicaciones.find(u => u.key === ciudad.id)) {
            setChipUbicaciones([...chipUbicaciones,
            { key: ciudad.id, label: departamento + " - " + ciudad.nombre, id: ciudad.id }])
            setCiudad({
                id: "",
                nombre: ""
            });
            setDepartamento("");
        }
    }

    const filtroAnios = (popupState) =>{
        popupState.close()
        setChipAnio([{key: anios, label: anios + " años de experiencia"}])
    }

    const filtroFormaciones = (popupState) => {
        popupState.close()
        let id = new Date().getTime();
        let label = filter.formacionInicial === "Pregrado"
            ? filter.nombrePregrado
            : filter.nombrePosgrado.trim();
        if (!chipFormaciones.find(f => f.label === filter.formacionInicial + "-" + label)) {
            setChipFormaciones([
                ...chipFormaciones,
                {
                    key: id,
                    label: filter.formacionInicial + "-" + label
                    ,
                },
            ]);
            setFilter({
                formacionInicial: 'Pregrado',
                tipoPosgrado: '',
                nombrePregrado: '',
                tarjetaProfesional: null,
                fechaExpedicionTarjetaProfesional: '',
                nombrePosgrado: '',
                departamento: true,
                ciudad: ''
            })
        }
    }

    const handleClosePopoverFormacion = (popupState) => {
        popupState.close()
        setFilter({
            formacionInicial: 'Pregrado',
            nombrePregrado: '',
            nombrePosgrado: ''
        });
    }

    const handleClosePopoverUbicacion = (popupState) => {
        popupState.close()
        setCiudad({
            id: "",
            nombre: ""
        });
        setDepartamento("");
    }

    const handleClosePopoverAnios = (popupState) => {
        popupState.close()
    }

    const ubicacionesInvalidas = () => {
        if (ciudad.nombre !== "") {
            return false
        }
        return true
    }

    const aniosInvalidos = () => {
        if (anios === '' || anios < 1 || anios > 50 ){
            return true;
        }
        return false;
    }

    const formacionesInvalidas = () => {
        if (filter.formacionInicial === "Pregrado" && filter.nombrePregrado && filter.nombrePregrado !== "") {
            return false
        }
        if (filter.formacionInicial !== "Pregrado" && filter.nombrePosgrado.trim() !== "") {
            return false
        }
        return true
    }

    const handleDeleteUbicacion = (chipToDelete) => () => {
        setChipUbicaciones((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };
    
    const handleDeleteFormacion = (chipToDelete) => () => {
        setChipFormaciones((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
    };

    const handleDeleteAnio = (chipToDelete) => () => {
        setChipAnio((chips) => chips.filter((chip) => chip.key !== chipToDelete.key));
        setAnios("");
    };

    const buscarCandidatos = async () => {
        let filtros = {
            ubicaciones: chipUbicaciones.map(ubicacion => ubicacion.id),
            formaciones: chipFormaciones.map(formacion => formacion.label),
            anios: anios
        }
        const candidatoQuery = await filtro(filtros)
            .then(async response => response.data)
            .catch(err => {
                let alert = ['Ocurrió un error en la busqueda']
                setAlertError(alert)
            })
        candidatoQuery.filtros = filtros;
        initialState({filtros})
    }
    const onChangeDepartamentoAutoComplete = async (e, newValue) => {
        if (newValue !== null) {
            let index = 1;
            for (const i in departamentoList.nombre) {
                if (newValue === departamentoList.nombre[i]) {
                    index = departamentoList.id[i];
                    setDepartamento(departamentoList.nombre[i])
                }
            }
            const ciudades = await getDepartamentosId(index)
                .then(res => sortCiudadesAlphabetically(res.data.ciudades, false))
                .catch(err => { console.log(err); })
            let nombre = []; let id = [];
            for (const i in ciudades) {
                nombre.push(ciudades[i].nombre)
                id.push(ciudades[i].id)
            }
            let ciudadesQuery = { nombre: nombre, id: id }
            setCiudadList(ciudadesQuery);
            setFilter({
                ...filter,
                departamento: false
            })
        } else {
            setFilter({
                ...filter,
                departamento: true
            })
            setCiudadList({ nombre: [], id: [] })
        }
    }
    const onChangeCiudadAutoComplete = (e, newValue) => {
        var event = {
            value: '',
            name: "ciudad",
            nameCity: ""
        }
        if (newValue !== null) {
            let index = 1;
            for (const i in ciudadList.nombre) {
                if (newValue === ciudadList.nombre[i]) {
                    index = ciudadList.id[i];
                    event.nameCity = ciudadList.nombre[i];
                }
            }
            event.value = index
        }
        handleChange({ target: event })

    }
    const TableFunction = () => {
        if (candidatos.content) {
            if (candidatos.content.length > 0) {
                return (
                    <Table
                        columns={columns}
                        data={candidatos.content}
                        modalOpen2={modalOpen2}
                        editable={true}
                        candidatosQuery={candidatos}
                        initialState={initialState}
                        selectedCandidatos={selectedCandidatos}
                        setSelectedCandidatos={setSelectedCandidatos}
                    />
                )
            } else {
                return (
                    <Typography value="" className={classes.notDataText} variant="h2" align="center"
                        color="textPrimary"> No se encontraron candidatos </Typography>)
            }
        } else {
            return (<Typography className={classes.notDataText} variant="h2" align="center"
                color="textPrimary"> Ocurrió un error al solicitar candidatos </Typography>)
        }
    }
    const DepartamentoAutoComplete = () => {

        return departamentoList.nombre.length > 0 ?
            <Autocomplete
                options={departamentoList.nombre}
                onChange={async (e, newValue) => { onChangeDepartamentoAutoComplete(e, newValue) }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            : <Typography value="" className={classes.formText} variant="h5" color="textPrimary">
                No se encontrarton departamentos
            </Typography>
    }
    const CiudadAutoComplete = () => {
        return ciudadList.nombre.length > 0 ?
            <Autocomplete
                options={ciudadList.nombre}
                disabled={filter.departamento}
                defaultValue=""
                onChange={(e, newValue) => { onChangeCiudadAutoComplete(e, newValue); }}
                renderInput={(params) => <TextField {...params} variant="outlined" />}
            />
            : <Typography value="" className={classes.formText} variant="caption" color="textPrimary">
                Selecciona un departamento para poder ver las ciudades
            </Typography>
    }

    const FiltroFormacionInicial = () => {
        const programa = filter.formacionInicial === "Tecnico" ? "Técnico" : filter.formacionInicial
            === "Tecnologo" ? "Tecnólogo" : filter.formacionInicial;
        if (filter.formacionInicial === 'Bachiller') {
            filter.nombrePosgrado = "Bachiller"
            return <></>
        } else if (filter.formacionInicial === 'Pregrado') {
            return <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                        Nombre de pregrado
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <FormControl
                        fullWidth
                        className={classes.inputPading}
                        variant="outlined"
                    >
                        <Autocomplete
                            options={Programas}
                            onChange={(e, newValue) => {
                                var event = {
                                    value: newValue,
                                    name: "nombrePregrado"
                                }
                                handleChange({ target: event })
                            }}
                            renderInput={(params) => <TextField {...params} variant="outlined" />}
                        />
                    </FormControl>
                </Grid>
            </Grid>
        } else {
            return <Grid container item xs={12}>
                <Grid item xs={4}>
                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                        Nombre del {programa}
                    </Typography>
                </Grid>
                <Grid item xs={8}>
                    <TextField
                        fullWidth
                        type="text"
                        defaultValue=""
                        onChange={handleChange}
                        name='nombrePosgrado'
                        variant="outlined"
                        className={classes.inputPading}
                        id="nombrePosgrado"
                    />
                </Grid>
            </Grid>
        }

    };
    return (
        <Card className={classes.internalCard} variant="outlined">
            {redirect ? <Redirect to={{ pathname: '/signin', state: { goBack: 'buscar_y_vincular_candidato' } }} /> : null}
            <Modal
                open={open2}
                onClose={handleClose2}
            >
                <div style={modalStyle} className={classes.modal}>
                    <Typography variant="h1" align="left" color="textPrimary">
                        Vincular a oferta
                    </Typography>
                    <FiltroOferta setOferta={setOferta} setAlertModal={setAlertModal} />
                    <Table columns={columns} data={selectedCandidatos} modalOpen2={modalOpen2} editable={false} candidatosQuery={candidatos} initialState={initialState} />
                    <Grid align="center" item xs={12}>
                        {alertModal === '' ? null : <Alert severity="error" > {alertModal} </Alert>}
                        <Button className={classes.centralButton} onClick={() => handleClose2()} variant="contained" color="secondary"> Cerrar</Button>
                        <Button className={classes.centralButton} onClick={async () => {
                            if (oferta !== '') vincular();
                            else setAlertModal('Debes escoger una oferta')
                        }} variant="contained" color="primary"> Vincular</Button>
                    </Grid>
                </div>
            </Modal>
            <Typography variant="h1" align="left" color="textPrimary">
                Buscar y vincular candidatos
            </Typography>
            <Typography variant="subtitle1" align="left" color="textPrimary">
                Selecciona y vincula los candidatos a una oferta
            </Typography>
            <div className={classes.container} >
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button className={classes.button} variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Nivel educativo
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverFormacion(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Formación inicial
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        className={classes.inputPading}
                                                    >
                                                        <Select
                                                            onChange={handleChange}
                                                            defaultValue="Pregrado"
                                                            name="formacionInicial"
                                                            value={filter.formacionInicial}
                                                        >
                                                            <MenuItem value={"Bachiller"}>Bachiller</MenuItem>
                                                            <MenuItem value={"Tecnico"}>Tecnico</MenuItem>
                                                            <MenuItem value={"Tecnologo"}>Tecnologo</MenuItem>
                                                            <MenuItem value={"Pregrado"}>Pregrado</MenuItem>
                                                            <MenuItem value={"Especializacion"}>Especializacion</MenuItem>
                                                            <MenuItem value={"Maestria"}>Maestria</MenuItem>
                                                            <MenuItem value={"Doctorado"}>Doctorado</MenuItem>
                                                            <MenuItem value={"Posdoctorado"}>Posdoctorado</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            {FiltroFormacionInicial()}
                                            <Button disabled={formacionesInvalidas()} onClick={() => filtroFormaciones(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <div className={classes.containerFilter}>
                        <Paper component="ul" className={classes.rootChip}>
                            {chipFormaciones.map((data) => {
                                if (width > 960 && width < 1800) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "250px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 690 && width < 960) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "190px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width > 490 && width < 690) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "150px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else if (width < 490) {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    style={{ maxWidth: "100px" }}
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                } else {
                                    return (
                                        <Tooltip title={data.label}>
                                            <li key={data.key}>
                                                <Chip
                                                    label={data.label}
                                                    onDelete={handleDeleteFormacion(data)}
                                                    className={classes.chip}
                                                    style={{width: '280px'}}
                                                />
                                            </li>
                                        </Tooltip>
                                    );
                                }
                            })}
                        </Paper>
                    </div>
                </div>
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Ubicación
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverUbicacion(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Departamento
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                                                        {DepartamentoAutoComplete()}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={4}>
                                                    <Typography className={classes.formText} variant="h5" color="textPrimary">
                                                        Ciudad
                                                    </Typography>
                                                </Grid>
                                                <Grid item xs={8}>
                                                    <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                                                        {CiudadAutoComplete()}
                                                    </FormControl>
                                                </Grid>
                                            </Grid>
                                            <Button disabled={ubicacionesInvalidas()} onClick={() => filtroUbicaciones(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <Paper component="ul" className={classes.rootChip}>
                        {chipUbicaciones.map((data) => {
                            if (width > 960 && width < 1800) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "250px" }}
                                                label={data.label}
                                                onDelete={handleDeleteUbicacion(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width > 690 && width < 960) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "190px" }}
                                                label={data.label}
                                                onDelete={handleDeleteUbicacion(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width > 490 && width < 690) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "150px" }}
                                                label={data.label}
                                                onDelete={handleDeleteUbicacion(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width < 490) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "100px" }}
                                                label={data.label}
                                                onDelete={handleDeleteUbicacion(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                label={data.label}
                                                onDelete={handleDeleteUbicacion(data)}
                                                className={classes.chip}
                                                style={{width: '280px'}}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            }
                        })}
                    </Paper>
                </div>
                <div className={classes.filter}>
                    <div className={classes.containerFilter}>
                        <Typography className={classes.formText} variant="h5" color="textPrimary">
                            Filtros
                        </Typography>
                        <PopupState variant="popover" popupId="demo-popup-popover">
                            {(popupState) => (
                                <div className={classes.containerButton}>
                                    <Button variant="contained" color="primary" {...bindTrigger(popupState)}>
                                        Experiencia
                                    </Button>
                                    <Popover
                                        {...bindPopover(popupState)}
                                        anchorOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                        }}
                                        transformOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }}
                                        PaperProps={{
                                            style: { width: '30%', minWidth: "320px" },
                                        }}
                                        onClose={() => handleClosePopoverAnios(popupState)}
                                    >
                                        <Box p={2}>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.titleCenter} variant="h5" color="textPrimary">
                                                        Años de experiencia
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <Typography className={classes.titleCenter} variant="h5" color="textPrimary">
                                                        Los años de experiencia se calcularán a partir de la fecha de 
                                                        expedición de la tarjeta profesional
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                            <Grid container item xs={12}>
                                                <Grid item xs={12}>
                                                    <TextField
                                                        fullWidth
                                                        type="number"
                                                        defaultValue={anios}
                                                        onChange={handleAnios}
                                                        name='anios'
                                                        variant="outlined"
                                                        className={classes.inputPading}
                                                        id="anios"
                                                        max
                                                    />
                                                </Grid>
                                            </Grid>
                                            <Button disabled={aniosInvalidos()} onClick={() => filtroAnios(popupState)} variant="contained" color="primary">
                                                Agregar filtro
                                            </Button>
                                        </Box>
                                    </Popover>
                                </div>
                            )}
                        </PopupState>
                    </div>
                    <Paper component="ul" className={classes.rootChip}>
                        {chipAnio.map((data) => {
                            if (width > 960 && width < 1800) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "250px" }}
                                                label={data.label}
                                                onDelete={handleDeleteAnio(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width > 690 && width < 960) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "190px" }}
                                                label={data.label}
                                                onDelete={handleDeleteAnio(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width > 490 && width < 690) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "150px" }}
                                                label={data.label}
                                                onDelete={handleDeleteAnio(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else if (width < 490) {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                style={{ maxWidth: "100px" }}
                                                label={data.label}
                                                onDelete={handleDeleteAnio(data)}
                                                className={classes.chip}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            } else {
                                return (
                                    <Tooltip title={data.label}>
                                        <li key={data.key}>
                                            <Chip
                                                label={data.label}
                                                onDelete={handleDeleteAnio(data)}
                                                className={classes.chip}
                                                style={{maxWidth: '300px'}}
                                            />
                                        </li>
                                    </Tooltip>
                                );
                            }
                        })}
                    </Paper>
                </div>
            </div>
            <Grid className={classes.formText} container item xs={12}>
                <Grid item xs={5} align="right">
                    <Button onClick={() => buscarCandidatos()} variant="contained" color="primary">
                        Aplicar filtros
                    </Button>
                </Grid>
                <Grid item xs={1} />
                <Grid item xs={5} align="left">
                    <Button size="small" variant="text"
                        onClick={() => {
                            initialState({});
                            setFilter({
                                formacionInicial: 'Pregrado',
                                nombrePregrado: '',
                                nombrePosgrado: ''
                            });
                            setAnios("")
                            setChipFormaciones([]);
                            setChipUbicaciones([]);
                            setChipAnio([])
                        }} > Limpiar filtros</Button>
                </Grid>
            </Grid>
            {alertSuccess.length > 0 ? (alertSuccess.map((item, index) => {
                return (
                    <Alert severity="success" onClose={async (e) => {
                        let alerts = alertSuccess
                        alerts.splice(index, 1)
                        await setAlertSuccess(alerts)
                        initialState({})
                    }} key={index}> {item} </Alert>
                )

            })) : null}
            {alertError.length > 0 ? (alertError.map((item, index) => {
                return (
                    <Alert severity="error" onClose={async (e) => {
                        let alerts = alertError
                        alerts.splice(index, 1)
                        await setAlertError(alerts)
                        initialState({})
                    }} key={index}> {item} </Alert>
                )

            })) : null}
            {TableFunction()}
        </Card>
    )
}

export default CandidatosSearchTable
