import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'
const token = getToken()
const contentType = 'application/json'

export const getCandidato = () =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'candidato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getCandidatoPageNumeroDocumento = ({numeroDocumento="",page=0, pageSize=10}) =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'filtroCandidatos',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            numeroDocumento: numeroDocumento,
            pageNumber : page,
            pageSize: pageSize,
        }
    }
    return axios(config)
}

export const getCandidatoNumeroDocumento = (numeroDocumento) =>{
    let config = {
        method: 'get',
        url: BASE_URL + `candidato/${numeroDocumento}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const putAgregarCandidatoListaNegra = (id, data) =>{
    let config = {
        method: 'put',
        url: BASE_URL + `agregarCandidatoListaNegra/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data: data
    }
    return axios(config)
}

export const putRemoverCandidatoListaNegra = (id, data) =>{
    let config = {
        method: 'put',
        url: BASE_URL + `removerCandidatoListaNegra/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data: data
    }
    return axios(config)
}


export const getCandidatoId = (id) =>{
    let config = {
        method: 'get',
        url: BASE_URL + `candidatoes/${id}`,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getCandidatoPage = ({page=0, pageSize=10}) =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'candidatoes',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            pageNumber : page,
            pageSize: pageSize,
        }
    }
    return axios(config)
}

export const getCandidatoBlackListPage = ({numeroDocumento = "", page=0, pageSize=10}) =>{
    let config = {
        method: 'get',
        url: BASE_URL + 'candidatoesListaNegra',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            numeroDocumento: numeroDocumento,
            pageNumber : page,
            pageSize: pageSize,
        }
    }
    return axios(config)
}

export const candidatoAdmin = (data) =>{
    let config = {
        method: 'post',
        url: BASE_URL + 'candidatoAdmin',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        data:data
    }
    return axios(config)
}

export const patchCandidato = (data, idCandidato) =>{
    let config = {
        method: 'patch',
        url: BASE_URL + 'candidatoes/'+idCandidato,
        headers: {
            'Authorization': token,
        },
        withCredentials: true,
        data:data
    }
    return axios(config)
}

export const deleteFormacionExperiencia = (route,id) =>{
    let config = {
        method: 'delete',
        url: BASE_URL + route + id,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

