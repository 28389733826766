import React from 'react'
import { Typography} from '@material-ui/core';
import MaterialTable from '@material-table/core';

const CuentaCobroTable = ({
    cuentasCobro,
    loading,
    handleReject,
    handleOpen
}) => {
    const numberFormat = new Intl.NumberFormat('es-CO');
    const columns = [   
        { title: "Número de cuenta", field: "contrato.numeroCuenta" },
        { title: "Tipo de documento", field: "contrato.preempleado.tipoDocumento" },
        { title: "Número de documento", field: "contrato.preempleado.numeroDocumento" },
        {
            title: "Nombre", field: "contrato.preempleado.nombres", render: rowData => {
                console.log(rowData)
                return (
                `${rowData.contrato.preempleado.nombres} ${rowData.contrato.preempleado.apellidos}`
                )
            }
        },
        { title: "Perfil", field: "contrato.perfil.nombre" },
        { title: "Mes a cobrar", field: "mesCobro" },
        { title: "Valor", field: "valorCobro", render:rowData => {
            return ("$ " + numberFormat.format(rowData.valorCobro))
            } 
        },
    ]
    return (
        <div> 
            {  
                cuentasCobro.length > 0
                    ?
                    <MaterialTable
                        title="Aprobar Cuenta de Cobro"
                        data={cuentasCobro}
                        columns={columns}
                        isLoading={loading}
                        actions={[
                            {
                                icon: 'close',
                                tooltip: 'Rechazar',
                                onClick: (event, rowData) => handleReject(rowData)
                            },
                            {
                                icon: 'done',
                                tooltip: "Aprobar",
                                onClick: (event, rowData) => {handleOpen(rowData)}
                            }
                        ]}
                        options={{
                            actionsColumnIndex: -1,
                            search: false,
                            headerStyle: {
                                fontWeight: 'bold',
                            }
                        }}
                        localization={{
                            header: {
                                actions: 'Acciones',
                            },
                            pagination: {
                                labelDisplayedRows: '{from}-{to} de {count}',
                                labelRowsSelect: 'registros',
                            },
                            body: {
                                emptyDataSourceMessage: "No hay datos para mostrar",
                            },
                        }}
                    />
                    :
                    <Typography variant="h5" color="textPrimary">No hay cuentas de cobro por aprobar</Typography>
            }
        </div>
    )
}

export default CuentaCobroTable
