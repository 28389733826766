import React, { useEffect, useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { useDispatch, useSelector } from 'react-redux';
import Button from '@material-ui/core/Button';
import { Box, alpha } from '@material-ui/core';
import { AuhMethods } from '../../services/auth';
import ContentLoader from '../ContentLoader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import CmtImage from '../../@coremat/CmtImage';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { CurrentAuthMethod } from '../../constants/AppConstants';
import { NavLink, useHistory } from 'react-router-dom';
import AuthWrapper from './AuthWrapper';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: props => (props.variant === 'default' ? '50%' : '100%'),
      order: 1,
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  formcontrolLabelRoot: {
    '& .MuiFormControlLabel-label': {
      [theme.breakpoints.down('xs')]: {
        fontSize: 12,
      },
    },
  },
}));

const SignIn = ({ props ,method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);
  const [email, setEmail] = useState(sessionStorage.getItem('username')? 
  (()=> {var CryptoJS = require("crypto-js");  var bytes = CryptoJS.AES.decrypt(sessionStorage.getItem('username'), 'S661S-CR1PT0_S3CUR1T1_4DM1N');
  var tokenSecure = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  return tokenSecure})
  :'');
  const [password, setPassword] = useState(sessionStorage.getItem('password')? sessionStorage.getItem('password'):'');
  const [remember, setRemember] = useState(false);
  const [error, setError] = useState({
    email: false, emailHelper: '',
    password: false, passwordHelper: '',
  });

  useEffect(()=>{
    if(authUser !== null) history.push("/admin_page")
    //eslint-disable-next-line react-hooks/exhaustive-deps
  },[])
  
  const dispatch = useDispatch();
  const classes = useStyles({ variant });

  const onSubmit = async() => {
    if(remember){
      let CryptoJS = require("crypto-js");
      let ciphertext = CryptoJS.AES.encrypt(JSON.stringify(email), 'S661S-CR1PT0_S3CUR1T1_4DM1N').toString();
      sessionStorage.setItem('username',ciphertext)
    }
    else{
      sessionStorage.removeItem('username')
    }
    await dispatch(AuhMethods[method].onLogin({ props,email, password }));
    
    
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      <Box className={classes.authContent}>
        <Box mb={7}>
          <CmtImage src={'/images/grupois.png'} />
        </Box>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Iniciar sesión
        </Typography>
        <form onSubmit={(e) => {
          e.preventDefault()
          if(email !== '' && password !== ''){
            if(!error.email && !error.password){
              onSubmit()
            }
          }else{
            if(error.email ) setError({ ...error, email:true, emailHelper: ' Este campo es requerido'})
            if(error.password ) setError({ ...error, password:true, passwordHelper: ' Este campo es requerido'})
            if(email === '' && password === ''){
              setError({ ...error,
                email: true, emailHelper: ' Este campo es requerido' ,
                password: true, passwordHelper: ' Este campo es requerido' ,
                });
            }
          }
        }}>
          <Box mb={2}>
            <TextField
              onBlur={async event => {
                // var regex = /^[-\w.%+]{1,64}@(?:[A-Z0-9-]{1,63}\.){1,125}[A-Z]{2,63}$/i;
                if (event.target.value === '') {
                  setError({ ...error, email: true, emailHelper: ' Este campo es requerido' });
                // }else
                // if(!regex.test(email)){
                //   setError({ ...error, email: true, emailHelper: ' Ingrese un email válido' });
                }
                else setError({ ...error, email: false, emailHelper: '' });
              }}
              error={error.email}
              helperText={error.emailHelper}
              label="Email:"
              fullWidth
              onChange={event => setEmail(event.target.value)}
              defaultValue={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </Box>
          <Box mb={2}>
            <TextField
              onBlur={async event => {
                if (event.target.value === '') {
                  setError({ ...error, password: true, passwordHelper: ' Este campo es requerido' });
                }
                else if (event.target.value.length < 4 ) {
                  setError({ ...error, password: true, passwordHelper: ' Este campo debe tener al menos 4 caracteres' });
                }
                else setError({ ...error, password: false, passwordHelper: '' });
              }}
              error={error.password}
              helperText={error.passwordHelper}
              type="password"
              label="Contraseña:"
              fullWidth
              onChange={event => setPassword(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              inputProps={{ maxLength: 100 }}
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
            <FormControlLabel
              className={classes.formcontrolLabelRoot}
              control={<Checkbox onChange={()=> setRemember(!remember)} name="checkedA" />}
              label="Recordar datos"
            />
            <Box component="p" fontSize={{ xs: 12, sm: 16 }}>
            <Button type="submit" variant="contained" color="primary">
              Iniciar sesión
            </Button>
            </Box>
          </Box>

          <Box display="flex" alignItems="center" justifyContent="space-between" mb={5}>
          <NavLink to="/forgot_password">
                ¿Olvidaste tu contraseña?
              </NavLink>

            
          </Box>
        </form>

        {dispatch(AuhMethods[method].getSocialMediaIcons())}

        <ContentLoader />
      </Box>
    </AuthWrapper>
  );
};

export default SignIn;
