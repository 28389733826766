import React, { useState, useEffect } from 'react';
import { Box, FormControl, Select, MenuItem, Grid, alpha, Backdrop, CircularProgress, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import validate from 'validate.js';
import AuthWrapper from '../../../../components/authComponents/AuthWrapper'
import ContentLoader from '../../../../components/ContentLoader';
import CmtImage from '../../../../@coremat/CmtImage';
import TypeText from '../../../../components/Campos/TypeText'
import TypeForm from '../../../../components/Campos/TypeForm'
import { IS_NOT_EMPTY, EARLIEST_DATE } from '../../../../constants/MensajeHelper'
import { Redirect } from 'react-router';
import { getDepartamentos, getDepartamentosId } from '../../../../helpers/departamentos/departamentosHelper';
import { getProyectos } from '../../../../helpers/proyecto/proyectoHelper';
import { postOfertas } from '../../../../helpers/oferta/ofertaHelper';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../../utils/orderHelper';
import ReactQuill from 'react-quill';
import QuillToolbar, {modules, formats} from '../../../../utils/toolbar';
import 'react-quill/dist/quill.snow.css';

const useStyles = makeStyles(theme => ({
	authThumb: {
		backgroundColor: alpha(theme.palette.primary.main, 0.12),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		padding: 20,
		[theme.breakpoints.up('md')]: {
			width: '50%',
			order: 2,
		},
	},
	authContent: {
		padding: 30,
		color: theme.palette.text.primary,
		[theme.breakpoints.up('md')]: {
			width: props => (props.variant === 'default' ? '50%' : '100%'),
			order: 1,
		},
		[theme.breakpoints.up('xl')]: {
			padding: 50,
		},
	},
	titleRoot: {
		marginBottom: 16,
		marginTop: -30,
		color: theme.palette.text.primary,
	},
	subtitleObligatorio: {
		marginBottom: '10px',
		marginTop: '10px'
	  },
	textFieldRoot: {
		'& .MuiOutlinedInput-notchedOutline': {
		},
	},
	textCapital: {
		textTransform: 'capitalize',
	},
	textAcc: {
		textAlign: 'center',
		'& a': {
			marginLeft: 4,
		},
	},
	alrTextRoot: {
		textAlign: 'center',
		[theme.breakpoints.up('sm')]: {
			textAlign: 'right',
		},
	},
	errorHelper: {
		marginLeft: '14px',
		marginRight: '14px'
	},
	button: {
		margin: 14
	},
	descripcionError: {
		marginLeft: '14px',
		marginRight: '14px',
		marginTop: '3px',
		fontSize: '0.75rem',
		color: 'red'
	},
	descripcionInput: {
		marginTop: '16px',
		marginBottom: '8px'
	},
	color: {
		color: 'red'
	},
}));

const useSingleton = (callBack = () => {
	//Empty function no need to call anything
	}) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}

const mensajeHelper = {
	nombre: {
		presence: IS_NOT_EMPTY,
		length: { maximum: 80, message: "no puede superar los 80 caracteres"},
	},
	descripcion: {
		presence: IS_NOT_EMPTY,
		length: { maximum: 1500, message: "no puede superar los 1500 caracteres"},
	},
	fechaCierre: {
		presence: IS_NOT_EMPTY,
		datetime: EARLIEST_DATE,
	},
	ciudad: {
		presence: IS_NOT_EMPTY,
	},
	proyecto: {
		presence: IS_NOT_EMPTY,
	},
	departamento: {
		presence: IS_NOT_EMPTY,
	},
}

var date = new Date().toISOString().split('T')[0];

 const CustomTypeText = ({ titulo, id, formState, setFormState, classes, ...props }) => {
	return(
		<Box mb={2}>
			<Grid container item xs={12}>
				<Typography>{titulo}<IsNotEmpty id={id} />
				</Typography>
			</Grid>
			<FormControl
				fullWidth
				className={classes.textFieldRoot}
			>
				<TypeText
					type={props?.type ? props.type : ""}
					id={id}
					formState={formState}
					setFormState={setFormState}
					helperText={mensajeHelper}
					fullWidth
					multiline={props?.multiline ? true : false}
					inputProps={props?.inputProps ? props.inputProps : ""}
				/>
			</FormControl>
		</Box>
	)
}

const CustomDepartamento = ({ classes, hasError, formState, onChange, handleDepartamento, departamentoList, id}) => { 
	return(
		<Box mb={2}>
			<Grid container item xs={12}>
				<Typography > Departamento<IsNotEmpty id={id} />
				</Typography>
			</Grid>
			<FormControl
				fullWidth
				margin="normal"
				variant="outlined"
				className={classes.textFieldRoot}
				
				>	
				<Select
					error={hasError("departamento")}
					name='departamento'
					value={formState.values.departamento}
					onChange={event => {
						onChange(event);
						handleDepartamento(event);
					}}
				>
					
					<MenuItem value="" />
					{departamentoList.length > 0 ?
						departamentoList.map((departamento) => {
						const departamentoCapitalized = departamento.nombre[0].toUpperCase() + departamento.nombre.slice(1);
						return (<MenuItem key={departamento.id} value={departamento.id}> {departamentoCapitalized} </MenuItem>)
						})
						: <MenuItem value="No se encontraron departamentos" />}
				</Select>

			</FormControl>
		</Box>
	)
}

const CustomTypeForm = ({ titulo, id, formState, setFormState, list}) => {
	return(
		<Box mb={2}>
			<Grid container item xs={12}>
				<Typography > {titulo}<IsNotEmpty id={id} />
				</Typography>
			</Grid>
			<TypeForm id={id} formState={formState} setFormState={setFormState} helperText={mensajeHelper} defaultValue={formState.values.ciudad} fullWidth>
				<MenuItem value="" />
					{list.length > 0 ?
						list.map((ciudad) => {
							return (<MenuItem key={ciudad.id} value={ciudad.id}> {ciudad.nombre} </MenuItem>)
						})
						: <Typography variant="subtitle1" color="textPrimary">No se encontraron ciudades</Typography>}
			</TypeForm>
		</Box>
	)
}

const CustomTypeTextEditor = ({titulo, id, formState, setFormState, classes, onChange}) => {
  return (
    <Box mb={2}>
      <Grid container item xs={12}>
        <Typography>
          {titulo}
          <IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <div className={classes.descripcionInput}>
        <QuillToolbar />
        <ReactQuill
          className={classes.toolbar}
          modules={modules}
          formats={formats}
          value={formState.values.descripcion}
          onBlur={(e) => {
            setFormState({
              ...formState,
              touched: {
                ...formState.touched,
                descripcion: true,
              },
            });
          }}
          onChange={onChange}
        ></ReactQuill>
        <p className={classes.descripcionError}>
          {formState.touched.descripcion ? formState.errors.descripcion : ""}
        </p>
      </div>
    </Box>
  );
};

function IsNotEmpty(id) {
	const isEmpty = mensajeHelper[id.id]?.presence?.message;
	const style={color: 'red'};
	if (isEmpty === "no debe estar vacío") {
	  return <span style={style}>*</span>;
	}
	return <></>;
}

const CrearOfertas = ({ props, variant = 'standart', wrapperVariant = 'standard', handleClose, initialState }) => {
	const [formState, setFormState] = useState({
		isValid: false,
		values: {
			nombre: '',
			descripcion: '',
			fechaCierre: '',
			fechaCreacion: date,
			ciudad: '',
			proyecto: '',
			departamento: '',
		},
		touched: {
			nombre: false,
			descripcion: false,
			fechaCierre: false,
			fechaCreacion: false,
			ciudad: false,
			proyecto: false,
			departamento: false,
		},
		errors: {
			nombre: false,
			descripcion: false,
			fechaCierre: false,
			fechaCreacion: false,
			ciudad: false,
			proyecto: false,
			departamento: false,
		},
	})
	const [departamentoList, setDepartamentoList] = useState([])
	const [ciudadLoading, setCiudadLoading] = useState(false)
	const [ciudadList, setCiudadList] = useState([]);
	const [redirect2, setRedirect2] = useState(false);
	const [proyectoList, setProyectoList] = useState([]);
	const [redirect, setRedirect] = useState(false);
	const classes = useStyles({ variant });
	const MySwal = withReactContent(Swal);

	const hasError = (field) =>
    	formState.touched[field] && formState.errors[field] ? true : false;

	useEffect(() => {
		let valores = Object.assign({}, formState.values);
		valores.descripcion = valores.descripcion.replace(/<(.|\n)*?>/g, '').trim()
		const errors = validate(valores, mensajeHelper);
		setFormState((formState) => ({
		...formState,
		isValid: errors ? false : true,
		errors: errors || {},
		}));

	}, [formState.values]);

	useSingleton(async () => {
		const departamentos = await getDepartamentos()
			.then(response => {
				return sortDepartamentosAlphabetically(response.data)
		  	})
			.catch(err => {
				setRedirect(true)
				console.log(err)
			})
		setDepartamentoList(departamentos)
		const proyectos = await getProyectos()
			.then(response => response.data)
			.catch(err => err)
		setProyectoList(proyectos)
	});

	const handleDepartamento = async (e) => {
		if (e.target.value !== "") {
			setCiudadLoading(true);
			const ciudades = await getDepartamentosId(e.target.value)
				.then((res) => {
					return sortCiudadesAlphabetically(res.data.ciudades, false);
				}).catch((err) => {
					console.log(err);
				});

			setCiudadList(ciudades);
			setCiudadLoading(false)
		}
	}

	const onChange = (e) => {
		e.persist();
		const target = e.target;
		const value = target.value;
		const name = target.name;

		setFormState({
		...formState,
			values: {
				...formState.values,
				[name]: value,
			},
			touched: {
				...formState.touched,
				[name]: true,
			},
		});
	}

	const onChangeDescripcion = (e) =>{
		setFormState({
			...formState,
				values: {
					...formState.values,
					descripcion: e,
				},
				touched: {
					...formState.touched,
					descripcion: true,
				},
		});
	}

	const onSubmit = async (e) => {
		e.preventDefault()

		let data = JSON.stringify({
			nombre: formState.values.nombre,
			descripcion: formState.values.descripcion.replace(/&nbsp;/g, ' '),
			idCiudad: formState.values.ciudad,
			idProyecto: formState.values.proyecto,
			fechaCierre: formState.values.fechaCierre,
		})
		await postOfertas(data)
			.then(response => {
				if (response.status === 201) {
					MySwal.fire({
						icon: 'success',
						title: 'Oferta creada',
					});
					setRedirect2(true)
				}
			})
			.catch(err => {
				MySwal.fire({
					title: 'Error',
					text: 'Ocurrió un error al crear la oferta, por favor inténtelo de nuevo más tarde.',
					icon: 'error',
					confirmButtonText: 'Ok'
				})
			})
	}
	return (
		<AuthWrapper variant={wrapperVariant}>
			{variant === 'default' ? (
				<Box className={classes.authThumb}>
					<CmtImage src={'/images/auth/sign-up-img.png'} />
				</Box>
			) : null}
			<Box className={classes.authContent}>
				<Box mb={7}>
					<CmtImage src={'/images/logo.png'} />
				</Box>
				{redirect ? <Redirect push to={{ pathname: '/signin', state: { goBack: '/crear_oferta' } }} /> : null}
				{redirect2 ? <Redirect push to="tabla_oferta" /> : null}
				<Typography component="div" variant="h1" className={classes.titleRoot}>
					Crear una oferta
				</Typography>
				<Typography variant='body2' className={classes.subtitleObligatorio}>Los campos marcados con <span className={classes.color}>*</span> son obligatorios</Typography>
				<br></br>
				<form noValidate autoComplete="off" onSubmit={onSubmit}>
					<CustomTypeText titulo="Nombre de la oferta" id="nombre" formState={formState} setFormState={setFormState} classes={classes} inputProps={{maxRows:2}} multiline fullWidth/>
					<CustomTypeTextEditor titulo="Descripción" id="descripcion" formState={formState} setFormState={setFormState} onChange ={onChangeDescripcion} classes={classes}/>
					<CustomTypeText titulo="Fecha de cierre" id="fechaCierre" formState={formState} setFormState={setFormState} 
						classes={classes} inputProps={{min: formState.values.fechaCreacion}} fullWidth type="date" />
					<CustomDepartamento classes={classes} hasError={hasError} formState={formState} onChange={onChange} handleDepartamento={handleDepartamento} departamentoList={departamentoList} id="departamento"/>
					<CustomTypeForm titulo="Ciudad" id="ciudad" formState={formState} setFormState={setFormState} list={ciudadList} />
					<CustomTypeForm titulo="Proyecto" id="proyecto" formState={formState} setFormState={setFormState} list={proyectoList} />
					<Box align="center" justifyContent={{ sm: 'space-between' }} mb={3}>
						<Button type='button' className={classes.button} onClick={() => { setRedirect2(true) }} variant="contained" color="secondary">
							Cancelar
						</Button>
						<Button type='submit' className={classes.button} variant="contained" color="primary" disabled={!formState.isValid}>
							Crear Oferta
						</Button>
					</Box>
				</form>
				<ContentLoader />
			</Box>
			<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={ciudadLoading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</AuthWrapper>
	);
};

export default CrearOfertas