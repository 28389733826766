import React, { useState, useEffect } from 'react';
import { Typography, Button, Grid, Card, TableHead, TableRow, TableCell, TableBody, Accordion, AccordionSummary, AccordionDetails } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import MaUTable from "@material-ui/core/Table";
import { Redirect } from 'react-router';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { getFormacionesPreempleado } from '../../../helpers/formacionHelper/formacionHelper';
import { getExperienciasPreempleado } from '../../../helpers/experienciaHelper/experienciaHelper';
import { getDocumentoPreempleado } from '../../../helpers/fileHelper/downloadHelper';
import { errorAlert } from "../../../helpers/swalHelper/swalHelper"
import { dateFormat } from '../../../helpers/fechaHelper';
import { FORMACION_URL } from '../../../helpers/formacionUrl';
import { EXPERIENCIA_URL } from '../../../helpers/experienciaUrl';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(4),
    },
    root: {
      width: '20%',
    },
    buttonDownload: {
        marginTop: '9px',
        marginBottom: '9px'
    },
    boxInside: {
        width: "95%",
        border: '2px solid #3f51b5',
        marginTop: 20,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10
    },
    modal: {
        position: "absolute",
        width: "90%",
        height: "85%",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
        overflow: "auto",
    },
    internalCard: {
        margin: 25,
        padding: 15,
    },
    cell: {
        maxWidth: 100,
        overflowWrap: "anywhere",
    },
    hoverPointer: {
        cursor: "pointer",
        color: "#0000EE"
    },
    table: {
        minWidth: 700,
        boxShadow: theme.shadows[5],
    },
}));

function getModalStyle() {
    const top = 50;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const PreempleadoInfo = ({ preempleado, token, handleClose }) => {
    const [modalStyle] = React.useState(getModalStyle);
    const [redirect, setRedirect] = React.useState(false)
    useEffect(() => {
        getFormacionesPreempleado( preempleado.id)
            .then(res => res.data)
            .then(setFormaciones)
            .catch(err => {
                errorAlert();
                setRedirect(true)
            }
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getExperienciasPreempleado(preempleado.id)
            .then(res => res.data)
            .then(setExperiencias)
            .catch(err => errorAlert())
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [formaciones, setFormaciones] = useState([])
    const [experiencias, setExperiencias] = useState([])
    const handleDownload = (filename) => {
        getDocumentoPreempleado( preempleado.postulacion.id, preempleado.numeroDocumento, filename)
            .then(res => res.data)
            .then(data => {
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.setAttribute("type", "hidden");
                link.href = "data:text/plain;base64," + data;
                link.download = filename;
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => errorAlert())

    }

    const TablaCandidatoFormaciones = () => {
        return formaciones.length > 0
          ? formaciones.map((item) => {
            return (
              <TableRow key={item.id} className={classes.internalCard}>
                <TableCell className={classes.cell}>{item.tipo}</TableCell>
                <TableCell className={classes.cell}> {item.nombre}</TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {item.centroEducativo}
                </TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {dateFormat(item.fechaCulminacion)}
                </TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {item.tarjetaProfesional ? dateFormat(item.fechaExpedicionTarjetaProfesional) : "---"}
                </TableCell>
                <TableCell className={classes.root}>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header">
                     <Typography className={classes.heading}>Descargar</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div>
                        <Typography variant="h4" align="left" color="textPrimary">Diploma</Typography>
                        <Button variant="contained" size='small' color="secondary" onClick={() => handleDownload(FORMACION_URL + item.diploma)}
                          className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                        {item.tarjetaProfesional != null ?
                          <div>
                            <Typography variant="h4" align="left" color="textPrimary">Tarjeta profesional</Typography>
                            <Button variant="contained" size='small' color="secondary"
                              onClick={() => handleDownload(FORMACION_URL + item.tarjetaProfesional)}
                              className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                            <Typography variant="h4" align="left" color="textPrimary">Certificado de vigencia</Typography>
                            <Button variant="contained" size='small' color="secondary"
                              onClick={() => handleDownload(FORMACION_URL + item.certificadoVigenciaTarjetaProfesional)}
                              className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                          </div>
                          : null}
                      </div>
                    </AccordionDetails>
                </Accordion>
                </TableCell>
              </TableRow>
            );
        })
        : null;
    };
    
    const TablaCandidatoExperiencias = () => {
        return experiencias.length > 0
          ? experiencias.map((item) => {
            return (
              <TableRow key={item.id} className={classes.internalCard}>
                <TableCell className={classes.cell}> {item.empresa}</TableCell>
                <TableCell className={classes.cell}> {item.area}</TableCell>
                <TableCell className={classes.cell}> {item.cargo}</TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {item.descripcion}
                </TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {dateFormat(item.fechaInicio)}
                </TableCell>
                <TableCell className={classes.cell}>
                  {" "}
                  {dateFormat(item.fechaFinal)}
                </TableCell>
                <TableCell className={classes.root}>
                        <Button variant="contained" size='small' color="secondary"
                            onClick={() => handleDownload(EXPERIENCIA_URL + item.certificadoLaboral)}
                            className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>
                            Descargar
                        </Button>
                </TableCell>
              </TableRow>
            );
        })
        : null;
    };

    const classes = useStyles();

    return (
        <div style={modalStyle} className={classes.modal}>
            {redirect ? <Redirect push to={{ pathname:'/signin'}} /> : null}
            <Typography variant="h1" align="left" color="textPrimary">
              Detalles de {preempleado.nombres + " " + preempleado.apellidos}
            </Typography>
            <Card className={classes.internalCard}>
              <Typography variant="h4" align="left" color="textPrimary">
                Fecha de nacimiento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {dateFormat(preempleado.fechaNacimiento)}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Tipo de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.tipoDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Número de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.numeroDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Documento de identidad:
              </Typography>
              <Button variant="contained" size='small' color="secondary" 
              onClick={() => handleDownload(preempleado.documentoIdentidad)} 
              className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
              <Typography variant="h4" align="left" color="textPrimary">
                Ciudad de residencia:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.candidato.ciudad.nombre}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Dirección:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.direccion}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                RH:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.candidato.rh}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Celular:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.celular}
              </Typography>
              { preempleado.telefonoFijo ? (
                <div>
                    <Typography variant="h4" align="left" color="textPrimary">
                        Telefono fijo:
                    </Typography>
                    <Typography variant="body2" align="left" color="textPrimary">
                        {preempleado.candidato.rh}
                    </Typography>
                </div>
              ) : (
                null
              )}  
              <Typography variant="h4" align="left" color="textPrimary">
                Correo:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {preempleado.correo}
              </Typography>
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Formaciones
            </Typography>
            <Card className={classes.internalCard}>
              {formaciones.length ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Tipo de Formación</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Nombre del Programa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Centro educativo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de culminación </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de expedición tarjeta profesional </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Acciones </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoFormaciones()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El preempleado no tiene formaciones registradas
                </Typography>
              )}
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Experiencias
            </Typography>
            <Card className={classes.internalCard}>
              {experiencias.length ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Empresa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Área </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Cargo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Descripción </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Inicio </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Final </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Acciones </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoExperiencias()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El preempleado no tiene experiencias registradas
                </Typography>
              )}
            </Card>
            <Grid item xs={12} align="center">
              <Button
                onClick={() => handleClose()}
                variant="contained"
                color="secondary"
              >
                {" "}
                Cerrar
              </Button>
            </Grid>
          </div>
    )

}
const PreempleadoInfoRef = React.forwardRef((props, ref) => (<PreempleadoInfo innerRef={ref} {...props} />))


export default PreempleadoInfoRef;