import { Link } from 'react-router-dom';
import React, { useState, useRef } from 'react';
import { alpha, Button, Select, FormControl, Typography, Grid, MenuItem, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TypeText from '../../../../components/Campos/TypeText'
import TypeForm from '../../../../components/Campos/TypeForm'
import { IS_NOT_EMPTY, IS_NUMERIC, IS_VALID_EMAIL, LATEST_DATE } from '../../../../constants/MensajeHelper'
import { getDepartamentos, getDepartamentosId } from '../../../../helpers/departamentos/departamentosHelper'
import { patchCandidato } from '../../../../helpers/candidato/candidato'
import { patchContratado } from '../../../../helpers/preempleado/preempleadoHelper';
import moment from 'moment';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../../utils/orderHelper';

const useStyles = makeStyles((theme) => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: "5px",
    marginTop: "20px",
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: "40px",
    marginTop: "5px",
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  textFieldRoot: {
    width: "100%",
    height: "15%",
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.23),
    },
  },
  alerta: {
    width: "100%",
    padding: 40,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (
  callBack = () => {
    //Empty function
  }
) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const fechaActual = moment().subtract(1, "days").format("YYYY-MM-DD");

const mensajeHelper = {
  nombres: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  apellidos: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  tipoDocumento: {
    presence: IS_NOT_EMPTY,
  },
  numeroDocumento: {
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,
  },
  fileDocumento: {
    presence: IS_NOT_EMPTY,
  },
  direccion: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 80, message: "no puede sobrepasar los 80 caracteres" },
  },
  celular: {
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,
    length: { is: 10, message: "tiene que tener 10 dígitos" },
  },
  telefonoFijo: {
    presence: { allowEmpty: true },
    length: { maximum: 7, message: "tiene que tener 7 dígitos" },
  },
  fechaNacimiento: {
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  genero: {
    presence: IS_NOT_EMPTY,
  },
  rh: {
    presence: IS_NOT_EMPTY,
  },
  madreCabezaHogar: {
    presence: IS_NOT_EMPTY,
  },
  correo: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 50, message: "no puede sobrepasar los 50 caracteres" },
    email: IS_VALID_EMAIL,
  },
  idCiudad: {
    presence: IS_NOT_EMPTY,
  },
  departamento: {
    presence: IS_NOT_EMPTY,
  },
}

const CustomTypeText = ({ titulo, id, defaultValue, value, formState, setFormState, classes, ...props }) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeText id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          type={props?.type ? props.type : ""}
          InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : ""}
          inputProps={props?.inputProps ? props.inputProps : ""}
          defaultValue={value}
        />
      </Grid>
    </Grid>
  );
};

const CustomTypeSelect = ({ titulo, id, value, formState, setFormState, datos, classes }) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeForm
          id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          defaultValue={`${value}`}
        >
          <MenuItem value={value} />
          {datos.map(({ value, text }) => {
            return (
              <MenuItem key={value} value={value}>
                {text}
              </MenuItem>
            );
          })}
        </TypeForm>
      </Grid>
    </Grid>
  );
};

const CustomTypeDepartamento = ({
  handleDepartamento,
  departamentoList,
  classes,
  value,
  id
}) => {


  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant="body1" className={classes.typography}>
          Departamento<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <FormControl variant="outlined" className={classes.textFieldRoot}>
          <Select name="departamento" defaultValue={value} onChange={event => { handleDepartamento(event) }}>
            <MenuItem value="" />
            {departamentoList.length > 0 ? (
              departamentoList.map((departamento) => {
                return (
                  <MenuItem key={departamento.id} value={departamento.id}>
                    {" "}
                    {departamento.nombre}{" "}
                  </MenuItem>
                );
              })
            ) : (
              <MenuItem value="No se encontraron Departamento">
                No se encontraron departamentos
              </MenuItem>
            )}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

const CustomTypeCiudad = ({ id, formState, setFormState, ciudadList, classes, value }) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant="body1" className={classes.typography}>
          Ciudad<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeForm
          id="idCiudad"
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          defaultValue={value}
        >
          <MenuItem value="" />
          {ciudadList.length > 0 ? (
            ciudadList.map((ciudad) => {
              return (
                <MenuItem key={ciudad.id} value={ciudad.id}>
                  {" "}
                  {ciudad.nombre}{" "}
                </MenuItem>
              );
            })
          ) : (
            <MenuItem value="No se encontraron Ciudades">
              No se encontraron ciudades
            </MenuItem>
          )}
        </TypeForm>
      </Grid>
    </Grid>
  );
};

function IsNotEmpty(id) {
  const isEmpty = mensajeHelper[id.id]?.presence?.message;
  const style = { color: 'red' };
  if (isEmpty === "no debe estar vacío") {
    return <span style={style}>*</span>;
  }
  return <></>;
}

const documentType = [
  { value: "CC", text: "Cédula de ciudadanía" },
  { value: "CE", text: "Cédula de extranjería" },
  { value: "NIT", text: "NIT" },
  { value: "DE", text: "Tipo de documento extranjero" },
  { value: "PEP", text: "Permiso especial de permanencia" },
  { value: "PPT", text: "Permiso por Protección Temporal" },

];
const gender = [
  { value: "F", text: "Mujer" },
  { value: "M", text: "Hombre" },
  { value: "O", text: "Otros" },
];
const cabezaHogar = [
  { value: true, text: "Si" },
  { value: false, text: "No" },
];
const tipoRh = [
  { value: "O+", text: "O+" },
  { value: "O-", text: "O-" },
  { value: "A+", text: "A+" },
  { value: "A-", text: "A-" },
  { value: "B+", text: "B+" },
  { value: "B-", text: "B-" },
  { value: "AB+", text: "AB+" },
  { value: "AB-", text: "AB-" },
];

const MySwal = withReactContent(Swal);

const EditarCandidato = ({
  variant = "default",
  idCandidato,
  candidatoQuery,
  setInfo,
}) => {
  const classes = useStyles({ variant });
  const candidatoObtenido = candidatoQuery;
  const [departamentoList, setDepartamentoList] = useState([]);
  const [ciudadList, setCiudadList] = useState([]);
  useSingleton(async () => {
    const listaDep = await getDepartamentos()
      .then(response => {
        return sortDepartamentosAlphabetically(response.data)
      })
      .catch(err => {
        return err
      })
    setDepartamentoList(listaDep)
    const ciudades = await getDepartamentosId(candidatoQuery.departamento.id)
      .then((res) => {
        return sortCiudadesAlphabetically(res.data.ciudades, true);
      })
      .catch((err) => {
        console.log(err);
      });
    setCiudadList(ciudades)
  });

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      nombres: candidatoObtenido.nombres,
      apellidos: candidatoObtenido.apellidos,
      tipoDocumento: candidatoObtenido.tipoDocumento,
      numeroDocumento: candidatoObtenido.numeroDocumento,
      fileDocumento: '',
      direccion: candidatoObtenido.direccion,
      celular: candidatoObtenido.celular,
      telefonoFijo: candidatoObtenido.telefonoFijo,
      fechaNacimiento: candidatoObtenido.fechaNacimiento,
      genero: candidatoObtenido.genero,
      rh: candidatoObtenido.rh,
      madreCabezaHogar: candidatoObtenido.madreCabezaHogar,
      correo: candidatoObtenido.correo,
      departamento: candidatoObtenido.departamento.id,
      idCiudad: candidatoObtenido.ciudad.id,
    },
    touched: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
      departamento: false,
    },
    errors: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
      departamento: false,
    },
  });


  const handleDepartamento = async (e) => {
    if (e.target.value !== "") {
      const ciudades = await getDepartamentosId(e.target.value)
        .then((res) => {
          setFormState({
            ...formState,
            values: {
              ...formState.values,
              [e.target.name]: e.target.value,
            },
          });
          return sortCiudadesAlphabetically(res.data.ciudades, true);
        }).catch((err) => {
          console.log(err);
        });
      if (ciudades !== null) {
        setCiudadList(ciudades);
      }
    }
  };

  const actualizarPreempleados = (preempleados, candidatoActualizado) => {
    return preempleados.map((preempleado) => {
      return {
        id: preempleado.id,
        nombres: candidatoActualizado.nombres,
        apellidos: candidatoActualizado.apellidos,
        tipoDocumento: candidatoActualizado.tipoDocumento,
        numeroDocumento: candidatoActualizado.numeroDocumento,
        documentoIdentidad: null,
        direccion: candidatoActualizado.direccion,
        telefonoFijo: candidatoActualizado.telefonoFijo,
        celular: candidatoActualizado.celular,
        fechaNacimiento: candidatoActualizado.fechaNacimiento,
        genero: candidatoActualizado.genero,
        estado: preempleado.estado,
        correo: candidatoActualizado.correo,
        observacion: candidatoActualizado.observacion,
      };
    })
  };

  const AlertaSuccess = () => {
    MySwal.fire({
      icon: "success",
      title: "Candidato actualizado",
    });
  };

  const validateFileDocument = () => {
    if (formState.values.fileDocumento === "") {
      setFormState({
        ...formState,
        values: {
          ...formState.values,
          fileDocumento: "",
        },
        touched: {
          ...formState.touched,
          fileDocumento: true,
        },
        errors: {
          ...formState.errors,
          fileDocumento: true,
        },
      });
    }
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    validateFileDocument();
    if (!formState.errors.fileDocumento && formState.isValid) {
      const formData = new FormData();
      const candidato = {
        id: idCandidato,
        nombres: formState.values.nombres,
        apellidos: formState.values.apellidos,
        tipoDocumento: formState.values.tipoDocumento,
        numeroDocumento: formState.values.numeroDocumento,
        direccion: formState.values.direccion,
        celular: formState.values.celular,
        telefonoFijo: formState.values.telefonoFijo,
        fechaNacimiento: formState.values.fechaNacimiento,
        genero: formState.values.genero,
        rh: formState.values.rh,
        madreCabezaHogar: formState.values.madreCabezaHogar,
        correo: formState.values.correo,
        idCiudad: formState.values.idCiudad,
        versionTerminosYCondiciones: "1.0.0",
      };
      if (candidato.correo === candidatoQuery.correo) delete candidato.correo;

      formData.append(
        "candidato",
        new Blob([JSON.stringify(candidato)], { type: "application/json" })
      );
      formData.append("file", formState.values.fileDocumento);
      let preempleados = candidatoQuery.preempleados;
      let candidatoActualizado = {};
      await patchCandidato(formData, idCandidato)
        .then(function (response) {
          AlertaSuccess()
          candidatoActualizado = response.data;
          setInfo(1)
        })
        .catch((err) => console.log(err));
      if (preempleados.length > 0 && candidatoActualizado) {
        let preempleadosActualizados = actualizarPreempleados(preempleados, candidatoActualizado);
        for (const preempleado of preempleadosActualizados) {
          await patchContratado(preempleado.id, preempleado)
            .then((response) => console.log(response))
            .catch((err) => console.log(err));
        }
      }
    }
  };

  return (
    <CardContent>
      <Typography variant='h1'>Editar a {candidatoObtenido.nombres} {candidatoObtenido.apellidos}</Typography>
      <Typography variant='h6' className={classes.subtitle}>Edita la información básica del candidato</Typography>
      <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <CustomTypeText titulo="Nombres" id="nombres" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.nombres} />
        <CustomTypeText titulo="Apellidos" id="apellidos" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.apellidos} />
        <CustomTypeSelect titulo="Tipo de documento" id="tipoDocumento" formState={formState} setFormState={setFormState} datos={documentType} classes={classes} value={formState.values.tipoDocumento} />
        <CustomTypeText disabled titulo="Número de documento" id="numeroDocumento" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.numeroDocumento} />
        <CustomTypeText titulo="Adjunte su documento de identidad" id="fileDocumento" formState={formState} setFormState={setFormState} classes={classes} type="file" InputLabelProps={{ shrink: true }}
          inputProps={{ accept: "image/jpeg,application/pdf" }} value={formState.values.fileDocumento} />
        <CustomTypeDepartamento id="departamento" handleDepartamento={handleDepartamento} departamentoList={departamentoList} classes={classes} value={formState.values.departamento} />
        <CustomTypeCiudad id="idCiudad" formState={formState} setFormState={setFormState} ciudadList={ciudadList} classes={classes} value={formState.values.idCiudad} />
        <CustomTypeText titulo="Dirección" id="direccion" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.direccion} />
        <CustomTypeText titulo="Celular" id="celular" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.celular} />
        <CustomTypeText titulo="Teléfono" id="telefonoFijo" formState={formState} setFormState={setFormState} classes={classes} inputProps={{ maxLength: 10 }} value={formState.values.telefonoFijo} type="number" />
        <CustomTypeText titulo="Correo" id="correo" formState={formState} setFormState={setFormState} classes={classes} value={formState.values.correo} />
        <CustomTypeText titulo="Fecha de nacimiento" id="fechaNacimiento" formState={formState} setFormState={setFormState} type="date"
          inputProps={{ max: fechaActual }} classes={classes} value={formState.values.fechaNacimiento} />
        <CustomTypeSelect titulo="Género" id="genero" formState={formState} setFormState={setFormState} datos={gender} classes={classes} value={formState.values.genero} />
        <CustomTypeSelect titulo="¿Es madre cabeza de hogar?" id="madreCabezaHogar" formState={formState} setFormState={setFormState} datos={cabezaHogar}
          classes={classes} value={formState.values.madreCabezaHogar} />
        <CustomTypeSelect titulo="Tipo de sangre" id="rh" formState={formState} setFormState={setFormState} datos={tipoRh} classes={classes} value={formState.values.rh} />
        <Grid container item xs={12} spacing={3} className={classes.button}>
          {formState.values.telefonoFijo === null && formState.values.fileDocumento !== '' ? formState.isValid = true : null}
          <Grid item xs={6} className={classes.button}>
            <Link to={"/consulta_candidatos"}>
              <Button variant="contained" color="secondary">
               Salir
              </Button>
            </Link>
          </Grid>
          <Grid item xs={6} className={classes.button}>
            <Button variant="contained" color="primary" type="submit" >
              Actualizar Candidato
            </Button>
          </Grid>
        </Grid>
      </form>
    </CardContent>
  );
};

export default EditarCandidato;

