import React, { useState, useRef } from "react";
import { makeStyles, lighten } from "@material-ui/core/styles";
import {
  Button,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Input,
  Select,
  Card,
  CardContent,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import { useTable, useRowSelect, usePagination } from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import CreateIcon from "@material-ui/icons/Create";
import DeleteIcon from "@material-ui/icons/Delete";
import Tooltip from "@mui/material/Tooltip";
import EditarCandidato from "../EditarCandidato";
import CrearFormacion from "../IngresarFormacion";
import CrearExperiencia from "../IngresarExperiencia";
import {
  getCandidatoId,
  deleteFormacionExperiencia,
} from "../../../../helpers/candidato/candidato";
import { Redirect } from "react-router";
import { dateFormat } from "../../../../constants/AppConstants";
const useStyles = makeStyles((theme) => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
    marginBottom: 15,
  },
  title: {
    marginLeft: theme.spacing(3),
    display: "flex",
    alignItems: "center",
    marginBottom: "30px",
    marginTop: "20px",
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: "5px",
    marginTop: "20px",
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: "40px",
    marginTop: "5px",
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  Errortypography: {
    marginInline: 15,
  },
  left: {
    display: "flex",
    alignItems: "center",
  },
  centerItems: {
    width: "100%",
    alignItems: "center",
  },
  marginField: {
    marginBottom: 15,
  },
  button: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "20px",
    marginTop: "30px",
  },
  textFieldRoot: {
    width: "60%",
    height: "60px",
  },
  textFileRoot: {
    width: "80%",
    height: "15%",
  },
  alerta: {
    width: "100%",
    padding: 40,
    "& > * + *": {
      marginTop: theme.spacing(2),
    },
  },
  table: {
    maxWidth: "80%",
    margin: "0 auto",
    marginBottom: 15,
    overflow: "auto",
  },
  tableCell: {
    borderLeft: "1px solid rgba(224, 224, 224, 1)",
    overflowWrap: "anywhere"
  },
  tableCellTitle: {
    fontWeight: "bold",
  },
  containerNoData: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '50px',
  }
}));

const MySwal = withReactContent(Swal);

const EditLink = (row, formacionExperiencia, candidato) => {
  let state = {};
  let path = "";
  if (formacionExperiencia) {
    path = "/actualizar_formacion";
    state = { actualizar: true, formacion: row.original, candidato: candidato };
  } else {
    path = "/actualizar_experiencia";
    state = {
      actualizar: true,
      experiencia: row.original,
      candidato: candidato,
    };
  }
  return (
    <Tooltip title="Actualizar">
      <Link color="primary" to={{ pathname: path, state: state }}>
        <CreateIcon />
      </Link>
    </Tooltip>
  );
};

const DeleteLink = (row, formacionExperiencia, initialState, candidato) => {
  const Borrar = async (id, routeParam, formacionParam, initialStateParam) => {
    MySwal.fire({
      title: "Estas seguro?",
      text: "No podrás revertir esta acción!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true,
    }).then(async (result) => {
      if (result.value) {
        await deleteFormacionExperiencia(routeParam, id)
          .then((response) => {
            initialStateParam();
            MySwal.fire("Eliminado", "El registro fue eliminado.", "success");
          })
          .catch((err) => err);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        MySwal.fire("Cancelado", "", "error");
      }
    });
  };
  let route;
  let formacion;
  if (formacionExperiencia) {
    route = "formacions/";
    formacion = true;
  } else {
    route = "experiencias/";
    formacion = false;
  }
  return (
    <Tooltip title="Eliminar">
      <Button
        color="primary"
        onClick={() => Borrar(row.original.id, route, formacion, initialState)}
      >
        <DeleteIcon />
      </Button>
    </Tooltip>
  );
};
function Table({
  props,
  columns,
  data,
  formacionExperiencia,
  initialState,
  candidato,
}) {
  const classes = useStyles();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { pageSize: 5 },
    },
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHooked) => [
        ...columnsHooked,
        {
          id: "edit",
          Header: ({ getToggleAllPageRowsSelectedProps }) => <div></div>,
          Cell: ({ row }) => (
            <div>{EditLink(row, formacionExperiencia, candidato)}</div>
          ),
        },
        {
          id: "delete",
          Header: ({ getToggleAllPageRowsSelectedProps }) => <div></div>,
          Cell: ({ row }) => (
            <div>
              {DeleteLink(row, formacionExperiencia, initialState, candidato)}
            </div>
          ),
        },
      ]);
    }
  );

  return (
    <Grid container>
      <div style={{ width: "100%", overflow: "auto" }}>
        <MaUTable {...getTableProps()}>
          <TableHead>
            {headerGroups.map((headerGroup) => (
              <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <TableCell
                    className={classes.cell}
                    {...column.getHeaderProps()}
                  >
                    <strong>{column.render("Header")}</strong>
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableHead>
          <TableBody {...getTableBodyProps()}>
            {page.map((row, i) => {
              prepareRow(row);
              return (
                <TableRow {...row.getRowProps()}>
                  {row.cells.map((cell) => {
                    return (
                      <TableCell
                        className={classes.cell}
                        {...cell.getCellProps()}
                      >
                        {cell.render("Cell")}
                      </TableCell>
                    );
                  })}
                </TableRow>
              );
            })}
          </TableBody>
        </MaUTable>
      </div>
      <div className="pagination">
        <Button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {"<<"}
        </Button>{" "}
        <Button onClick={() => previousPage()} disabled={!canPreviousPage}>
          {"<"}
        </Button>{" "}
        <Button onClick={() => nextPage()} disabled={!canNextPage}>
          {">"}
        </Button>{" "}
        <Button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {">>"}
        </Button>{" "}
        <span>
          Página{" "}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{" "}
        </span>
        <span>
          | Ir a la página:{" "}
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const pageNumber = e.target.value
                ? Number(e.target.value) - 1
                : 0;
              gotoPage(pageNumber);
            }}
            style={{ width: "50px", height: " 20px" }}
          />
        </span>{" "}
        <Select
          value={pageSize}
          onChange={(e) => {
            setPageSize(Number(e.target.value));
          }}
        >
          {[5, 10, 15, 20, 30].map((pageSizeMaper) => (
            <option key={pageSizeMaper} value={pageSizeMaper}>
              Mostrar {pageSizeMaper}
            </option>
          ))}
        </Select>
      </div>
    </Grid>
  );
}
const FormacionesTable = ({ props, formaciones, initialState, candidato }) => {
  const columns = [
    {
      Header: "Nombre del programa",
      accessor: "nombre",
    },
    {
      Header: "Tipo de programa",
      accessor: "tipo",
    },
    {
      Header: "Centro educativo",
      accessor: "centroEducativo",
    },
    {
      Header: "Fecha de culminación",
      accessor: "fechaCulminacion",
    }, {
      Header: "Fecha de expedición tarjeta profesional",
      accessor: "fechaExpedicionTarjetaProfesional",
    },
  ];
  if (formaciones !== undefined && formaciones.length > 0) {
    for (const i in formaciones) {
      if (formaciones[i].fechaCulminacion !== undefined)
        formaciones[i].fechaCulminacion = dateFormat(formaciones[i].fechaCulminacion);
      if (formaciones[i].tarjetaProfesional) {
        formaciones[i].fechaExpedicionTarjetaProfesional = dateFormat(formaciones[i].fechaExpedicionTarjetaProfesional);
      } else {
        formaciones[i].fechaExpedicionTarjetaProfesional = "---";
      }
    }
    return (
      <div>
        <Table
          props={props}
          columns={columns}
          data={formaciones}
          formacionExperiencia={true}
          initialState={initialState}
          candidato={candidato}
        />
      </div>
    );
  } else {
    return null;
  }
};

const ExperienciasTable = ({
  props,
  experiencias,
  initialState,
  candidato,
}) => {
  const columns = [
    {
      Header: "Empresa",
      accessor: "empresa",
    },
    {
      Header: "Cargo",
      accessor: "cargo",
    },
    {
      Header: "Fecha de inicio",
      accessor: "fechaInicio",
    },
    {
      Header: "Fecha de finalización",
      accessor: "fechaFinal",
    },
  ];
  if (experiencias !== undefined && experiencias.length > 0) {
    for (const i in experiencias) {
      if (experiencias[i].fechaInicio !== undefined) {
        experiencias[i].fechaInicio = dateFormat(experiencias[i].fechaInicio);
      }
      if (experiencias[i].fechaFinal !== undefined) {
        experiencias[i].fechaFinal = dateFormat(experiencias[i].fechaFinal);
      }
    }
    return (
      <div>
        <Table
          props={props}
          columns={columns}
          data={experiencias}
          formacionExperiencia={false}
          initialState={initialState}
          candidato={candidato}
        />
      </div>
    );
  } else return null;
};

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

export default function DatosExperiencia(props) {
  const classes = useStyles();
  const fecha = new Date();
  fecha.setDate(fecha.getDate() - 1);
  const [info, setInfo] = useState(1); //Cambiar a Formación y Experiencia
  const [idCandidato, setIdCandidato] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [candidato, setCandidato] = useState({
    nombres: "",
    apellidos: "",
    tipoDocumento: "",
    numeroDocumento: "",
    direccion: "",
    celular: "",
    telefonoFijo: "",
    ciudad: "",
    fechaNacimiento: "",
    genero: "",
    rh: "",
    madreCabezaHogar: "",
    correo: "",
    idCiudad: null,
    versionTerminosYCondiciones: "1.0.0",
    experiencias: [],
    formaciones: [],
  });
  const initialState = async () => {
    if (!props.location.state) return setRedirect(true);
    const getCandidato = await getCandidatoId(props.location.state.props.id)
      .then((response) => {
        let responseCandidato = response.data;
        responseCandidato.departamento = responseCandidato.ciudad.departamento;
        return responseCandidato;
      })
      .catch((err) => {
        console.log(err);
        setRedirect(true);
        return { id: "" };
      });
    setCandidato(getCandidato);
    setIdCandidato(getCandidato.id);
  };
  useSingleton(async () => {
    await initialState();
  });

  React.useEffect(() => {
    initialState();
  }, [info]) // eslint-disable-line react-hooks/exhaustive-deps

  function createData(name, description) {
    return { name, description };
  }
  const rows = [
    createData("Nombres", candidato.nombres),
    createData("Apellidos", candidato.apellidos),
    createData("Tipo de documento", candidato.tipoDocumento),
    createData("Número de documento", candidato.numeroDocumento),
    createData("Dirección", candidato.direccion),
    createData("Celular", candidato.celular),
    createData("Teléfono", candidato.telefonoFijo),
    createData("Ciudad", candidato.ciudad.nombre),
    createData("Fecha de Nacimiento", dateFormat(candidato.fechaNacimiento)),
    createData("Género", candidato.genero),
    createData("RH", candidato.rh),
    createData("Correo", candidato.correo),
  ];
  const sweetAlertsProceso = () => {
    MySwal.fire({
      icon: "success",
      title: "Información actualizada",
    });
  };

  const renderExperiencias = () => {
    if (candidato.experiencias.length > 0) {
      return (
        <ExperienciasTable
          props={props}
          experiencias={candidato.experiencias}
          initialState={initialState}
          candidato={candidato}
        />
      );
    } else {
      return (
        <div className={classes.containerNoData}>
          <Typography variant="h4" color="textPrimary">
            {" "}
            No se encontraron experiencias registradas{" "}
          </Typography>
        </div>
      );
    }
  };

  const renderFormaciones = () => {
    if (candidato.formaciones.length > 0) {
      return (
        <FormacionesTable
          props={props}
          formaciones={candidato.formaciones}
          initialState={initialState}
          candidato={candidato}
        />
      );
    } else {
      return (
        <div className={classes.containerNoData}>
          <Typography variant="h4" color="textPrimary">
            {" "}
            No se encontraron formaciones registradas{" "}
          </Typography>
        </div>
      );
    }
  };
  return (
    <Card className={classes.root}>
      <CardContent>
        {redirect ? (
          <Redirect
            to={{ pathname: "/signin", state: { goBack: "/consulta_candidatos" } }}
          />
        ) : null}
        {info === 1 ? (
          <div className={classes.centerItems}>
            <React.Fragment>
              <Typography variant="h1" className={classes.title}>Información del candidato</Typography>
              <Card className={classes.root}>
                <Grid container item xs={12} spacing={3}>
                  <Grid item xs={8}>
                    <Typography variant="h3" className={classes.title}>
                      Información básica
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.gridContainer}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.title}
                      onClick={() => setInfo(2)}
                    >
                      Editar información
                    </Button>
                  </Grid>
                </Grid>
                <TableContainer component={Paper} className={classes.table}>
                  <MaUTable aria-label="simple table">
                    <TableBody>
                      {rows.map((row) => (
                        <TableRow key={row.name}>
                          <TableCell component="th" scope="row" size="small" className={classes.tableCellTitle}>
                            {row.name}:
                          </TableCell>
                          <TableCell align="left" size="small" className={classes.tableCell}>
                            {row.description}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </MaUTable>
                </TableContainer>
              </Card>
              <Card className={classes.marginField}>
                <Grid
                  container
                  className={classes.marginField}
                  item
                  xs={12}
                  spacing={3}
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      className={classes.title}
                    >
                      Formación
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.title}
                      onClick={(event) => setInfo(3)}
                    >
                      Añadir formación académica
                    </Button>
                  </Grid>
                </Grid>
                {renderFormaciones()}
              </Card>
              <Card className={classes.marginField}>
                <Grid
                  container
                  className={classes.marginField}
                  item
                  xs={12}
                  spacing={3}
                >
                  <Grid item xs={8}>
                    <Typography
                      variant="h3"
                      color="textPrimary"
                      className={classes.title}
                    >
                      Experiencia laboral
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <Button
                      type="button"
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.title}
                      onClick={(event) => setInfo(4)}
                    >
                      Añadir experiencia laboral
                    </Button>
                  </Grid>
                </Grid>
                {renderExperiencias()}
              </Card>
              <Grid
                container
                item
                xs={12}
                spacing={3}
              >
                <Grid item xs={6} className={classes.button}>
                  <Link to={"/consulta_candidatos"}>
                    <Button
                      variant="contained"
                      color="secondary"
                    >
                      Salir
                    </Button>
                  </Link>
                </Grid>
                <Grid item xs={6} className={classes.button}>
                  <Link to={"/consulta_candidatos"}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={(event) => sweetAlertsProceso()}
                    >
                      Guardar información
                    </Button>
                  </Link>
                </Grid>
              </Grid>
            </React.Fragment>
          </div>
        ) : info === 2 ? (
          <div className={classes.centerItems}>
            <EditarCandidato
              idCandidato={idCandidato}
              candidatoQuery={candidato}
              setCandidatoQuery={setCandidato}
              setInfo={setInfo}
            />
          </div>
        ) : info === 3 ? (
          <div className={classes.centerItems}>
            <CrearFormacion
              idCandidato={idCandidato}
              candidatoQuery={candidato}
              setInfo={setInfo}
            />
          </div>
        ) : (
          <div className={classes.centerItems}>
            <CrearExperiencia
              idCandidato={idCandidato}
              candidatoQuery={candidato}
              setInfo={setInfo}
            />
          </div>
        )}
      </CardContent>
    </Card>
  );
}
