import axios from 'axios'
import {BASE_URL} from '../baseUrl'
import {getToken} from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json';


export const getOfertasPage = async({page=0, pageSize=10}) => {
  const config = {
      method: 'get',
      url: BASE_URL+'ofertas-page',
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
      params:{
        pageNumber: page,
        pageSize: pageSize
      }
    }

    return await axios(config)
}

export const getOfertas = async()=>{
  const config = {
      method: 'get',
      url: BASE_URL + 'ofertas',
      headers:{
        'Authorization': token,
        'Content-Type': contentType
    },
    withCredentials: true,
  }

  return await axios(config)
}

export const postOfertas = async(data) => {
  const config = {
      method: 'post',
      url: BASE_URL+'ofertas',
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
      data:data
    }

    return await axios(config)
}

export const putOfertas = async(data, idOferta) => {
  const config = {
      method: 'put',
      url: BASE_URL + `ofertas/${idOferta}`,
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials: true,
      data:data
    }

    return await axios(config)
}

export const getOfertasFromContratante = async(id) => {
    const config = {
        method: 'get',
        url: BASE_URL+'oferta_contratante/'+id,
        headers: {
          'Authorization': token,
          'Content-Type': contentType
        },
        withCredentials: true,
      }

      return await axios(config)
}
