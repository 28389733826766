import axios from 'axios'
import {getToken} from '../../services/auth/jwt/getToken'
import {BASE_URL} from '../baseUrl'

const token = getToken()
const contentType = 'application/json'

export const getContratantes = async() => {
    const config = {
        method: 'get',
        url: BASE_URL+'contratantes',
        headers: {
          'Authorization': token,
          'Content-Type': contentType
        },
        withCredentials: true,
      }

      return await axios(config)
}
