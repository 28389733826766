import axios from 'axios'
import {BASE_URL} from '../baseUrl'
import {getToken} from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json';

export const getFormacionesCandidato = async(id) => {
  const config = {
    method: 'get',
    url: BASE_URL+'formacion_candidato/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
  }

  return await axios(config)
}

export const getFormacionesPreempleado = async(id) => {
  const config = {
    method: 'get',
    url: BASE_URL+'formacion_preempleado/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
  }

  return await axios(config)
}

export const postFormaciones = async(data) => {
  const config = {
    method: 'post',
    url: BASE_URL+'formacions',
    headers: {
      'Authorization': token,
      'Content-Type': contentType,

    },
    withCredentials: true,
    data:data
  }

  return await axios(config)
}

export const patchFormaciones = async(data, id) => {
  const config = {
    method: 'patch',
    url: BASE_URL+'formacions/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/merge-patch+json',
    },
    withCredentials: true,
    data:data
  }

  return await axios(config)
}

export const deleteFormacionContratado = async(id) => {
  const config = {
    method: 'delete',
    url: BASE_URL+'formacion-preempleados/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
  }

  return await axios(config)
}

export const postFormacionesContratado = async(data) => {
  const config = {
    method: 'post',
    url: BASE_URL+'formacion-preempleados',
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
    data:data
  }

  return await axios(config)
}

export const patchFormacionesContratado = async (data, id) => {
  const config = {
    method: 'patch',
    url: BASE_URL+'formacion-preempleados/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/merge-patch+json'
    },
    withCredentials: true,
    data:data
  }

  return await axios(config)
}