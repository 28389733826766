import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useDropzone } from 'react-dropzone';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Button, Card, CardContent, Grid, FormControl, Select, MenuItem, TextField } from '@material-ui/core'
import NumberFormat from 'react-number-format'
import { getPerfilesFromOferta } from '../../../helpers/perfilHelper/perfilHelper'
import { errorAlert } from "../../../helpers/swalHelper/swalHelper"
import { tiposContrato } from "../../../helpers/contratoHelper/tiposContrato"


const mensaje = ' Campo requerido '

const useStyles = makeStyles(theme => ({
    container: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: "50%",
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
        overflowY: 'scroll',
        height: '90%',
        '&::-webkit-scrollbar': {
            width: '0.4em'
        },
        '&::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
            webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(236,236,236,.8)',
            outline: '1px solid slategrey'
        }


    },
    textFieldRoot: {
        width: "100%",
        height: "15%",
    },
    errorHelper: {
        marginLeft: '14px',
        marginRight: '14px'
    },
    title: {
        textAlign: 'center',
    },
    form: {
        paddingLeft: '25%',
        paddingTop: 15
    },
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        marginBottom: '20px',
        marginTop: '30px',
    },
    formText: {
        margin: 15,
        paddingTop: 5,
        paddingLeft: theme.spacing(8),
        width: "70%",
        height: "15%",
    },
    inputPading: {
        padding: 5,
    },
    left: {
        display: 'flex',
        alignItems: 'center',
    },
    typography: {
        paddingLeft: theme.spacing(8),
    },

}));

const baseStyle = {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '40px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#000000',
    outline: 'none',
    transition: 'border .24s ease-in-out'
};

const activeStyle = {
    borderColor: '#2196f3'
};

const rejectStyle = {
    borderColor: '#ff1744',
    color: '#ed0930'
};

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(props, ref) {
    const { onChange, inputRef, ...other } = props;
    return (
        <NumberFormat
            {...other}
            getInputRef={ref}
            onValueChange={(values) => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator={'.'}
            decimalSeparator={','}
        />
    );
});
const extensionValidator = (file) => {
    return (['doc', 'docx', 'pdf'].includes(file.name.split('.')[1]))
}

const CustomDropzon = ({ name, nameHelper, setFormData, formData, setError, error}) => {


    const onDrop = useCallback( file => {
        if (file[0].size > 1000000) {
            setRejected(true)
            setFormData({
                ...formData,
                [name]: file[0]
            });
            setError({
                ...error,
                [name]: true,
                [nameHelper]: "No debe exceder 1 MB"
            });

        } else if (file.length > 0) {
            if (extensionValidator(file[0])) {
            setRejected(false)
            setFormData({
                ...formData,
                [name]: file[0]
            });
            setError({
                ...error,
                [name]: false
            });
            } else {
            setRejected(true)
            setFormData({
                ...formData,
                [name]: file[0]
            });
            setError({
                ...error,
                [name]: true
            });
            }
        } else {
            setRejected(true)
            setFormData({
                ...formData,
                [name]: file[0]
            });
            setError({
                ...error,
                [name]: true,
                [nameHelper]: mensaje
            });
        }
    }, [name, nameHelper, setFormData, formData, setError, error])
  
    const {
      getRootProps,
      getInputProps,
      isDragActive,
      acceptedFiles,
    } = useDropzone({ accept: '.docx, .doc, .pdf', maxFiles: 1, onDrop });
  
    const [rejected, setRejected] = useState(false);
    const classes = useStyles();
  
    const files = acceptedFiles.map(file => (
      <li key={file.path}>
        {file.path} - {file.size} bytes
      </li>
    ));
  
    const style = useMemo(() => ({
      ...baseStyle,
      ...(isDragActive ? activeStyle : {}),
      ...(error[name] ? rejectStyle : {}),
      ...(rejected ? rejectStyle : {})
    }), [
      isDragActive,
      rejected,
      error,
      name
    ]);

    return (
      <div className="container">
        <div {...getRootProps({ style })}>
          <input {...getInputProps({})} />
          <Typography color="textPrimary">Arrastra y suelta o selecciona el archivo</Typography>
          <em>(Solo documentos con extension *.pdf, *.doc o *.docx son aceptados y de tamaño máximo 1MB)</em>
  
        </div>
        {error[name] ? <Typography className={classes.errorHelper} color="error" variant="caption">{error[nameHelper]}</Typography> : null}
        <aside>
          <Typography component="h4" variant="inherit">
            Archivo
          </Typography>
          <ul>{files}</ul>
        </aside>
      </div>
    );
}

const FormContratacion = ({ classes, perfiles, oferta, handleForm, formData, setFormData , error, setError }) => {

  
    const handleError = (event, name, helper, message) => {
        let errorVar = {}
        if (event.target.value === "") {
            errorVar = { ...error, [name]: true, [helper]: message };
        }
        else if (Number(event.target.value) <= 0) {
            errorVar = { ...error, [name]: true, [helper]: 'No puede ser menor que 0' };
        }
        else {
            errorVar = { ...error, [name]: false, [helper]: '' };
        }
        setError(errorVar)
    }
    return (
        <Grid container item xs={12} spacing={3} style={{ marginTop: 20 }}>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Tipo de contrato: </Typography>
            </Grid>
            <Grid item xs={7}>
                <FormControl
                    required
                    variant="outlined"
                    className={classes.textFieldRoot}

                >
                    <Select
                        defaultValue=""
                        onChange={handleForm}
                        error={error.tipo}
                        name='tipoContrato'
                        required
                        onBlur={event => {
                            handleError(event, 'tipo', 'tipoHelper', mensaje)
                        }}
                    >
                        <MenuItem value="" />
                        {tiposContrato.map((tipo, index) => (<MenuItem key={index} value={tipo.tipo}> {tipo.placeholder} </MenuItem>))}
                    </Select>
                    {error.tipoHelper !== '' && (<Typography className={classes.errorHelper} color="error" variant="caption"> {error.tipoHelper} </Typography>)}
                </FormControl>
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Oferta: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    disabled
                    className={classes.textFieldRoot}
                    value={oferta ? oferta.nombre : ""}
                    variant="outlined"
                />
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Perfil: </Typography>
            </Grid>
            <Grid item xs={7}>
                <FormControl
                    required
                    variant="outlined"
                    className={classes.textFieldRoot}
                >
                    <Select
                        onChange={handleForm}
                        error={error.perfil}
                        name='idPerfil'
                        required
                        defaultValue=""
                        onBlur={event => {
                            handleError(event, 'perfil', 'perfilHelper', mensaje)
                        }
                        }
                    >
                        <MenuItem value="" />
                        {perfiles.length > 0 ?
                            perfiles.map((perfil) =>
                                (<MenuItem key={perfil.id} value={perfil.id}> {perfil.nombre} </MenuItem>)
                            )
                            : <MenuItem value="">No hay perfiles disponibles</MenuItem>}
                    </Select>
                    {error.perfilHelper !== '' && (<Typography className={classes.errorHelper} color="error" variant="caption"> {error.perfilHelper} </Typography>)}
                </FormControl>
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Fecha de inicio del contrato: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    className={classes.textFieldRoot}
                    variant="outlined"
                    type='date'
                    onChange={handleForm}
                    value={formData.fechaInicioContrato}
                    name='fechaInicioContrato'
                    error={error.fechaInicioContrato}
                    helperText={error.fechaInicioContratoHelper}
                    onBlur={event => {
                        handleError(event, 'fechaInicioContrato', 'fechaInicioContratoHelper', mensaje)
                    }}
                    />
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Fecha inicio de labores: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    className={classes.textFieldRoot}
                    variant="outlined"
                    type='date'
                    onChange={handleForm}
                    value={formData.fechaInicioLabores}
                    InputProps={{inputProps: { min: formData.fechaInicioContrato, max: formData.fechaTerminacion} }}
                    name='fechaInicioLabores'
                    error={error.fechaInicioLabores}
                    helperText={error.fechaInicioLaboresHelper}
                    onBlur={event => {
                        handleError(event, 'fechaInicioLabores', 'fechaInicioLaboresHelper', mensaje)
                    }}
                />

            </Grid>
            { formData.tipoContrato !== "TERMINO_INDEFINIDO" && (<><Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Fecha de terminación del contrato: </Typography>
            </Grid>
                <Grid item xs={7}>
                    <TextField
                        className={classes.textFieldRoot}
                        variant="outlined"
                        name="fechaTerminacion"
                        onChange={handleForm}
                        value={formData.fechaTerminacion}
                        InputProps={{inputProps: { min: formData.fechaInicioLabores} }}
                        type='date'
                        error={error.fechaTerminacion}
                        helperText={error.fechaTerminacionHelper}
                        onBlur={event => {
                            handleError(event, 'fechaTerminacion', 'fechaTerminacionHelper', mensaje)
                        }}
                    />
                </Grid></>)}
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Valor del contrato: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    className={classes.textFieldRoot}
                    variant="outlined"
                    type="text"
                    name='valorContrato'
                    onChange={handleForm}
                    error={error.valorContrato}
                    helperText={error.valorContratoHelper}
                    onBlur={(event) => {
                        handleError(event, 'valorContrato', 'valorContratoHelper', mensaje)
                    }
                    }
                    InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                />
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Número de pagos: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    className={classes.textFieldRoot}
                    type="number"
                    variant="outlined"
                    name='numeroPagos'
                    onChange={handleForm}
                    error={error.numeroPagos}
                    helperText={error.numeroPagosHelper}
                    onBlur={(event) => {
                        handleError(event, 'numeroPagos', 'numeroPagosHelper', mensaje)
                    }
                    }
                />
            </Grid>
            <Grid item xs={5} className={classes.left}>
                <Typography variant='body1' className={classes.typography}>Ciudad: </Typography>
            </Grid>
            <Grid item xs={7}>
                <TextField
                    disabled
                    className={classes.textFieldRoot}
                    value={oferta ? oferta.ciudad.nombre + " - " + oferta.ciudad.departamento.nombre : ""}
                    variant="outlined"
                />
            </Grid>
            
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Contrato: </Typography>
            </Grid>
            <Grid item xs={7}>
              <Box>
                <CustomDropzon name={'contrato'} nameHelper={'contratoHelper'} setFormData={setFormData} formData={formData} setError={setError} error={error} />
              </Box>
            </Grid>
        </Grid >
    )
}


const ModalContratacion = ({ oferta, toggleContratacion, contratar }) => {
    const classes = useStyles()
    const [error, setError] = useState({
        tipo: false,
        tipoHelper: '',
        perfil: false,
        perfilHelper: '',
        fechaInicioContrato: false,
        fechaInicioContratoHelper: '',
        fechaInicioLabores: false,
        fechaInicioLaboresHelper: '',
        fechaTerminacion: false,
        fechaTerminacionHelper: '',
        valorContrato: false,
        valorContratoHelper: '',
        numeroPagos: false,
        numeroPagosHelper: '',
        contrato: false,
        contratoHelper: ''
    })
    const [perfiles, setPerfiles] = useState([])
    const [formData, setFormData] = useState({
        tipoContrato: '',
        idPerfil: '',
        fechaInicioContrato: '',
        fechaInicioLabores: '',
        fechaTerminacion: '',
        valorContrato: '',
        numeroPagos: '',
        contrato: ''
    })

    const contrato = {
        tipoContrato: formData.tipoContrato,
        idPerfil: formData.idPerfil,
        fechaInicioContrato: formData.fechaInicioContrato,
        fechaInicioLabores: formData.fechaInicioLabores,
        fechaTerminacion: formData.fechaTerminacion,
        valorContrato: formData.valorContrato,
        numeroPagos: formData.numeroPagos,
    }

    useEffect(() => {
        getPerfilesFromOferta(oferta.id)
            .then(res => res.data)
            .then(setPerfiles)
            .catch(errorAlert)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const handleForm = (e) => {
        if (e.target.value === 'TERMINO_INDEFINIDO') {
            setFormData({ ...formData, fechaTerminacion: null, [e.target.name]: e.target.value });
            setError({ ...error, fechaTerminacion: false, fechaTerminacionHelper: '' })
        }else {
            setFormData(
                {
                    ...formData,
                    [e.target.name]: e.target.value,
                }
            )
        }
        perfiles.forEach((perfil)=>{
            if(perfil.id === e.target.value){
                setFormData({...formData, fechaInicioContrato: perfil.fechaCreacion, [e.target.name]: e.target.value })
        }})
    }

    const handleContratacion = () => {
        let errorVar = {}
        let formValid = true;
        if (formData.tipoContrato.length === 0) {
            errorVar.tipo = true;
            errorVar.tipoHelper = mensaje;
            formValid = false
        }
        if (formData.idPerfil.length === 0) {
            errorVar.perfil = true;
            errorVar.perfilHelper = mensaje
            formValid = false;
        }
        if (formData.fechaInicioContrato.length === 0) {
            errorVar.fechaInicioContrato= true;
            errorVar.fechaInicioContratoHelper = mensaje;
            formValid = false
        }
        if (formData.fechaInicioLabores.length === 0) {
            errorVar.fechaInicioLabores = true;
            errorVar.fechaInicioLaboresHelper = mensaje;
            formValid = false
        }
        if (!formData.fechaTerminacion && formData.tipoContrato !== 'TERMINO_INDEFINIDO') {
            errorVar.fechaTerminacion = true;
            errorVar.fechaTerminacionHelper = mensaje;
            formValid = false
        }
        if (formData.valorContrato.length === 0) {
            errorVar.valorContrato = true;
            errorVar.valorContratoHelper = mensaje;
            formValid = false
        }
        if (formData.numeroPagos.length === 0) {
            errorVar.numeroPagos = true;
            errorVar.numeroPagosHelper = mensaje;
            formValid = false
        }
        if (formData.contrato.length === 0) {
            errorVar.contrato = true;
            errorVar.contratoHelper = mensaje;
            formValid = false
        } else if (formData.contrato.size > 1000000) {
            errorVar.contrato = true;
            errorVar.contratoHelper = "No debe exceder 1 MB";
            formValid = false
        }
        if (Number(formData.valorContrato) <= 0) {
            errorVar.valorContrato = true;
            errorVar.valorContratoHelper = ' El valor del contrato debe ser mayor a 0 ';
            formValid = false
        }
        if (Number(formData.numeroPagos) <= 0) {
            errorVar.numeroPagos = true;
            errorVar.numeroPagosHelper = ' El número de pagos debe ser mayor a 0 ';
            formValid = false
        }
        if (formData.fechaInicioLabores < formData.fechaInicioContrato && formData.fechaInicioLabores.length !== 0) {
            errorVar.fechaInicioLabores = true;
            errorVar.fechaInicioLaboresHelper = ' La fecha de inicio de labores debe ser mayor o igual a la fecha de inicio del contrato ';
            formValid = false
        }
        if (formData.fechaInicioLabores > formData.fechaTerminacion && formData.fechaTerminacion.length !== 0) {
            errorVar.fechaInicioLabores = true;
            errorVar.fechaInicioLaboresHelper = ' La fecha de inicio de labores debe ser menor a la fecha de terminación del contrato ';
            formValid = false
        }
        if (formData.fechaInicioContrato > formData.fechaTerminacion && formData.fechaTerminacion.length !== 0) {
            errorVar.fechaTerminacion = true;
            errorVar.fechaTerminacionHelper = ' La fecha de terminación del contrato debe ser mayor a la de inicio del contrato ';
            formValid = false
        }
        if (formData.fechaInicioContrato > formData.fechaTerminacion && formData.fechaTerminacion.length !== 0) {
            errorVar.fechaInicioContrato = true;
            errorVar.fechaInicioContratoHelper = ' La fecha de inicio del contrato debe ser menor a la fecha de terminación del contrato ';
            formValid = false
        }
        if (!formValid) {
            setError(errorVar);
        }

        if (!(error.tipo
            || error.perfil
            || error.fechaInicioLabores
            || error.fechaInicioContrato
            || error.fechaTerminacion
            || error.valorContrato
            || error.numeroPagos
            || error.contrato) && formValid) {
            contratar(contrato, formData.contrato);
        }
    }
    return (
        <div className={classes.container}>
            <Card>
                <CardContent className={classes.cardContent}>
                    <Typography variant='h1'>Información de contratación</Typography>
                    <Typography variant='h6' style={{ marginTop: 15, fontWeight: "normal" }}>Ingresa la información del contrato del candidato</Typography>
                    <FormContratacion classes={classes} perfiles={perfiles} oferta={oferta} handleForm={handleForm} formData={formData} setFormData={setFormData} error={error} setError={setError} />

                    <Grid container item xs={12} spacing={3} className={classes.button}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={toggleContratacion}

                        >
                            Cancelar
                    </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleContratacion}
                        >
                            Contratar
                    </Button>
                    </Grid>
                </CardContent>
            </Card>
        </div >
    )
}


const ModalContratacionRef = React.forwardRef((props, ref) => (<ModalContratacion innerRef={ref} {...props} />))

export default ModalContratacionRef;
