import React, { useState } from 'react';
import { Typography, Button, Grid, TextField, Card } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { styled } from '@mui/material/styles';

const useStyles = makeStyles((theme) => ({
  boxInside: {
    width: "95%",
    border: '2px solid #3f51b5',
    marginTop: 20,
    paddingLeft: 10,
    paddingTop: 10,
    paddingBottom: 10
  },
  button: {
    margin: theme.spacing(4),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textField: {
    margin: 10,
    padding: 15
  },
  color: {
    color: 'red'
  },
  errorHelper: {
    marginLeft: '14px',
    marginRight: '14px'
  },
  modal: {
    position: "absolute",
    width: "90%",
    height: "85%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  internalCard: {
    margin: 25,
    padding: 15,
  },
  typography: {
    marginTop: 10
  },
  hoverPointer: {
    cursor: "pointer",
    color: "#0000EE"
  },
  motivo: {
    marginBottom: '30px',
  },
}));

const CssTextField = styled(TextField)({
  '& .MuiOutlinedInput-root': {
    '&:hover fieldset': {
      borderColor: 'grey',
    },
    '&.Mui-focused fieldset': {
      borderColor: 'grey',
    },
  },
})

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const mensaje = 'No debe estar vacío '

const CuentaCobroRejection = ({ cuentaCobro, motivo, setMotivo, handleClose, rechazar }) => {
  const numberFormat = new Intl.NumberFormat('es-CO');
  const [modalStyle] = React.useState(getModalStyle);

  const handleChageD = (e) => {
    setMotivo({
      ...motivo,
      [e.target.name]: e.target.value,
    })
  }

  const validacion = () => {
    if (motivo.motivo === '') {
      return true;
    } else {
      return false;
    }
  }

  const [error, setError] = useState({
    motivo:false,
    motivoHelper: '',
  })

  const handleError = (event, name, helper, message) => {
    let errorVar = {}
    if (event.target.value === "") {
        errorVar = { ...error, [name]: true, [helper]: message };
    }
    else if (Number(event.target.value) <= 0) {
        errorVar = { ...error, [name]: true, [helper]: 'No puede ser menor que 0' };
    }
    else {
        errorVar = { ...error, [name]: false, [helper]: '' };
    }
    setError(errorVar)
  } 

  

  const handleRejected = () => {
    rechazar(cuentaCobro.id, motivo.motivo)
  }
  const classes = useStyles();

  return (
    <div style={modalStyle} className={classes.modal}>
      <Typography variant="h1" align="left" color="textPrimary" className={classes.typography}>
        Rechazar Cuenta de Cobro {cuentaCobro.id} de {cuentaCobro.contrato.preempleado.nombres + " " + cuentaCobro.contrato.preempleado.apellidos}
      </Typography>
      <Typography style={{ marginTop: 10 }}>
        ¿Deseas rechazar la cuenta número {cuentaCobro.id} ?
      </Typography>
      <Typography style={{ marginTop: 5 }}>
      Los campos con <span className={classes.color}>*</span> son obligatorios
      </Typography>
      <Card className={classes.internalCard}>
        <Typography variant="h4" align="left" color="textPrimary">
          Tipo de documento:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.contrato.preempleado.tipoDocumento}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Número de documento:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.contrato.preempleado.numeroDocumento}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Número de contrato:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.contrato.numeroContrato}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Nombre:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.contrato.preempleado.nombres + " " + cuentaCobro.contrato.preempleado.apellidos}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Perfil:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.contrato.perfil.nombre}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Mes a cobrar
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.mesCobro}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Valor:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          $ {numberFormat.format(cuentaCobro.valorCobro)}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Concepto:
        </Typography>
        <Typography variant="body2" align="left" color="textPrimary">
          {cuentaCobro.concepto}
        </Typography>
        <Typography variant="h4" align="left" color="textPrimary">
          Obligación:
        </Typography>
        <Typography style={{ wordBreak: 'break-all' }} variant="body2" align="left" color="textPrimary">
          {cuentaCobro.obligacionesMes}
        </Typography>
      </Card>
      <Grid item xs={12} className={classes.textField}>
        <Grid item xs={12} className={classes.motivo}>
        <Typography id="modal-modal-description" variant="h3">Ingresa el motivo del rechazo de la cuenta de cobro<span className={classes.color}>*</span></Typography>
        </Grid>
        <CssTextField
          id="motivo"
          name="motivo"
          multiline
          rows={5}
          fullWidth
          defaultValue=""      
          variant="outlined"
          onChange={handleChageD}
          error={error.motivo}
          onBlur={event => {
            handleError(event, 'motivo', 'motivoHelper', mensaje)
          }}
        />
        {error.motivoHelper !== '' && (<Typography className={classes.errorHelper} color="error" variant="caption">{error.motivoHelper}</Typography>)}
      </Grid>
      <Grid container item xs={12} spacing={3} className={classes.button}>
        <Button
          onClick={handleClose}
          variant="contained"
          color="secondary"
        >
          {" "}
          Cancelar
        </Button>
        <Button
          onClick={handleRejected}
          disabled={validacion()}
          variant="contained"
          color="primary"
        >
          {" "}
          Rechazar
        </Button>
      </Grid>
    </div>
  )
}

const CuentaCobroRejectionRef = React.forwardRef((props, ref) => (<CuentaCobroRejection innerRef={ref} {...props} />))
export default CuentaCobroRejectionRef;
