import React from 'react'
import { Typography } from '@material-ui/core';
import MaterialTable from '@material-table/core';



const ContratacionTable = ({
	preempleados,
	loading,
	handleReject,
	handleOpenModalContratacion,
	classes,
	handleOpen}) => {

	const columns = [
		{ title: "Número de documento", field: "numeroDocumento" },
		{
			title: "Nombre", field: "nombres", render: rowData => {
				return (
					`${rowData.nombres} ${rowData.apellidos}`
				)
			}
		},
		{ title: "Celular", field: "celular" },
		{ title: "Correo", field: 'correo' },
		{
			title: "Detalle", render: rowData => {
				return (
					<div>
						<Typography 
							variant='inherit'
							color="primary"
							className={classes.hoverPointer} onClick={() => handleOpen(rowData)}
						>
							Ver detalle
						</Typography>
					</div>
				)
			}
		},

	]
	return (
		<div>
			{preempleados.length > 0
				?
				<MaterialTable
					title="Escoger candidatos"
					data={preempleados}
					columns={columns}
					isLoading={loading}
					actions={[
						{
							icon: 'close',
							tooltip: 'Descartar',
							onClick: (event, rowData) => handleReject(rowData.id)
						},
						{
							icon: 'done',
							tooltip: 'Contratar',
							onClick: (event, rowData) => {handleOpenModalContratacion(rowData, rowData.candidato.id)}

						}
					]}
					options={{
						actionsColumnIndex: -1,
						headerStyle: {
							fontWeight: 'bold'
						}
					}}
					localization={{
						header: {
							actions: 'Acciones'
						},
						pagination: {
							labelDisplayedRows: '{from}-{to} de {count}',
							labelRowsSelect: 'registros'
						},
						body: {
							emptyDataSourceMessage: 'No hay datos para mostrar',

						},
						toolbar:{
							searchPlaceholder: 'Buscar'
						}
					}}
				/>

				: <Typography variant="h5" color="textPrimary"> No se encontraron candidatos </Typography>
			}
		</div>
	)
}




export default ContratacionTable