import React, { useState, useRef } from 'react';
import { alpha, Button, Typography, Grid, FormHelperText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import TypeText from '../../../../components/Campos/TypeText';
import { IS_NOT_EMPTY, LATEST_DATE } from '../../../../constants/MensajeHelper'
import { getCandidatoId } from '../../../../helpers/candidato/candidato'
import { postExperienciasCandidato } from '../../../../helpers/experienciaHelper/experienciaHelper';
import moment from 'moment'
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const length = { maximum: 30, message: "no puede sobrepasar los 30 caracteres" }

const mensajeHelper = {
  empresa: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 100, message: "no puede sobrepasar los 100 caracteres" },
  },
  area: {
    presence: IS_NOT_EMPTY,
    length: length,
  },
  fechaInicio: {
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  fechaFinal: {
    presence: IS_NOT_EMPTY,
  },
  cargo: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 400, message: "no puede sobrepasar los 400 caracteres" },
  },
  descripcion: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 2000, message: "no puede sobrepasar los 2000 caracteres" },
  },
  fileExperiencia: {
    presence: IS_NOT_EMPTY,
  },
}

const CustomTypeText = ({ classes, titulo, id, formState, setFormState, helperText, ...props }) => {
  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={5} className={classes.left}>
        <Typography variant='body1' className={classes.typography}>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <TypeText
          id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          fullWidth
          inputProps={props?.inputProps ? props.inputProps : ""}
          InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : ""}
          type={props?.type ? props.type : ""}
          multiline={props?.multiline ? props.multiline : ""}
        />
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </Grid>
    </Grid>
  )
}

function IsNotEmpty(id) {
  const isEmpty = mensajeHelper[id.id]?.presence?.message;
  const style = { color: 'red' };
  if (isEmpty === "no debe estar vacío") {
    return <span style={style}>*</span>;
  }
  return <></>;
}

const fechaActual = moment().subtract(0, "days").format("YYYY-MM-DD");

const IngresarExperiencia = ({ variant = 'default', idCandidato, candidatoQuery, tokenGeneral, setInfo }) => {
  const classes = useStyles({ variant });
  const MySwal = withReactContent(Swal);
  const [redirect, setRedirect] = React.useState(false);
  const [candidatoObtenido, setCandidatoObtenido] = useState([])
  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      empresa: '',
      area: '',
      fechaInicio: '',
      fechaFinal: '',
      cargo: '',
      descripcion: '',
      fileExperiencia: '',
    },
    touched: {
      empresa: false,
      area: false,
      fechaInicio: false,
      fechaFinal: false,
      cargo: false,
      descripcion: false,
      fileExperiencia: false,
    },
    errors: {
      empresa: false,
      area: false,
      fechaInicio: false,
      fechaFinal: false,
      cargo: false,
      descripcion: false,
      fileExperiencia: false,
    },
  });

  useSingleton(async () => {
    const infoCandidato = await getCandidatoId(idCandidato)
      .then(response => response.data)
      .catch(() => setRedirect(true))
    if (infoCandidato !== undefined) {
      setCandidatoObtenido(infoCandidato)
    }
  });

  const onSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();
    const experiencia = {
      empresa: formState.values.empresa,
      area: formState.values.area,
      fechaInicio: formState.values.fechaInicio,
      fechaFinal: formState.values.fechaFinal,
      cargo: formState.values.cargo,
      descripcion: formState.values.descripcion,
      idCandidato: idCandidato,
    }
    formData.append('experiencia', new Blob([JSON.stringify(experiencia)], { type: "application/json" }));
    formData.append('file', formState.values.fileExperiencia);

    await postExperienciasCandidato(formData)
      .then(function (response) {
        MySwal.fire({
          icon: 'success',
          title: 'Experiencia creada'
        })
        setInfo(1)
      })
      .catch(function (response) {
        MySwal.fire({
          icon: 'error',
          title: 'Ocurrió un error',
          text: 'Ocurrió un error al procesar la solicitud, por favor intente más tarde'
        })
        setInfo(1)
      });
  }
  return (
    <form noValidate autoComplete="off" onSubmit={onSubmit}>
      {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
      <Typography variant='h1' className={classes.subtitle}>Crear Experiencia para {candidatoObtenido.nombres} {candidatoObtenido.apellidos}</Typography>
      <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
      <CustomTypeText classes={classes} titulo="Nombre de la empresa" id="empresa" formState={formState} setFormState={setFormState} />
      <CustomTypeText classes={classes} titulo="Área Encargada" id="area" formState={formState} setFormState={setFormState} />
      <CustomTypeText classes={classes} titulo="Fecha de Inicio" id="fechaInicio" formState={formState} setFormState={setFormState} type="date" inputProps={{ max: fechaActual }} />
      <CustomTypeText
        classes={classes}
        titulo="Fecha de Culminación"
        id="fechaFinal"
        formState={formState}
        setFormState={setFormState}
        type="date"
        inputProps={{ max: fechaActual }}
        helperText="En caso de que la vinculación del candidato siga vigente, 
        ingrese la fecha indicada en el certificado laboral."
      />
      <CustomTypeText classes={classes} titulo="Cargo" id="cargo" formState={formState} setFormState={setFormState} inputProps={{ maxRows: 8 }} multiline />
      <CustomTypeText classes={classes} titulo="Descripción del Cargo" id="descripcion" formState={formState} setFormState={setFormState} type="date" inputProps={{ maxLength: 2001 }} multiline />
      <CustomTypeText classes={classes} titulo="Adjunte Constancia de la Empresa" id="fileExperiencia"
        formState={formState} setFormState={setFormState} type="file"
        inputProps={{ accept: "image/jpeg,application/pdf" }} InputLabelProps={{ shrink: true }}
      />
      <Grid container item xs={12} spacing={3} >
        <Grid item xs={6} className={classes.button}>
          <Button variant="contained" color="secondary" onClick={event => setInfo(1)}>
            Cancelar
          </Button>
        </Grid>
        <Grid item xs={6} className={classes.button}>
          <Button variant="contained" color="primary" type="submit" disabled={!formState.isValid}>
            Crear Experiencia
          </Button>
        </Grid>
      </Grid>
    </form>
  )
};

export default IngresarExperiencia;