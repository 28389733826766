import axios from 'axios'
import { getToken } from '../../services/auth/jwt/getToken'
import { BASE_URL } from '../baseUrl'

const token = getToken()
const contentType = 'application/json'


export const filtrosDeBusqueda = ({filter, page=0, pageSize=10}) => {
    const {
        tipoPosgrado,
        formacionInicial,
        ciudad,
        tarjetaProfesional,
        nombrePregrado,
        fechaExpedicionTarjetaProfesional,
    } = filter
    let url = 'filtrosDeBusqueda/?pageSize='+pageSize+'&pageNumber='+page
    const isPregradoType = formacionInicial === 'Pregrado';
    if (tipoPosgrado !== "" && tipoPosgrado !== null && isPregradoType) {
        url = url + "&tipoFormacion=" + tipoPosgrado;
    }
    if (formacionInicial !== 'Todas') {
        url = url + "&formacionInicial=" + formacionInicial;
    }
    if (ciudad !== "" && ciudad !== null) url = url + "&id=" + ciudad;
    if (tarjetaProfesional !== "" && tarjetaProfesional !== null && isPregradoType) {
        url = url + "&tarjeta=" + tarjetaProfesional
    };
    if (nombrePregrado !== "" && nombrePregrado !== null && isPregradoType) {
        url = url + "&nombre=" + nombrePregrado
    };
    if (fechaExpedicionTarjetaProfesional !== "" && fechaExpedicionTarjetaProfesional !== null && isPregradoType) {
        url = url + "&fechaTarjeta=" + fechaExpedicionTarjetaProfesional;
    }
    let config = {
        method: 'get',
        url: BASE_URL + url,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const filtro = ({formaciones, ubicaciones, anios ,page=0, pageSize=10})=>{
    let formacion="";
    if(formaciones.length){
        formaciones.forEach((element,index) => {
            if(index === formaciones.length-1){
                formacion+=element
            }else{
                formacion+=element+","
            }
        });
    }
    let ubicacion="";
    if(ubicaciones.length){
        ubicaciones.forEach((element,index) => {
            ubicacion+=element+","
            if(index === ubicaciones.length-1){
                ubicacion+=element
            }
        });
    }
    let url = 'filtro?pageSize='+pageSize+'&pageNumber='+page+'&anios='+anios+'&formaciones='+encodeURIComponent(formacion)+'&ubicaciones='+ubicacion;
    let config = {
        method: 'get',
        url: BASE_URL + url,
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}