import React from 'react';
import { PostAdd } from '@material-ui/icons';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import ListIcon from '@material-ui/icons/List';
import PersonAddDisabledIcon from '@material-ui/icons/PersonAddDisabled';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import AssignmentIcon from '@material-ui/icons/Assignment';
import AssistantIcon from '@material-ui/icons/Assistant';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
import HomeIcon from '@material-ui/icons/Home';
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp';

const APROBAR_CUENTA_COBRO = 'Aprobar cuenta de cobro';
const URL_APROBAR_CUENTA_COBRO = '/aprobar_cuenta_cobro';

const roles = JSON.parse(sessionStorage.getItem('authorities'))
let sidebarNav;
if (!roles) {
  sidebarNav = [
    {
      name: 'Menú principal',
      type: 'section',
      children: [
        {
          name: 'Página Principal',
          type: 'item',
          icon: <HomeIcon />,
          link: '/',
        },
        {
          name: 'Iniciar sesión',
          type: 'item',
          icon: <AssignmentIndSharpIcon />,
          link: '/signin',
        },
      ],
    },
  ]
} else if (roles.some(rol => rol === "ROLE_ADMIN")) {
  sidebarNav = [
    {
      name: 'Menú principal',
      type: 'section',
      children: [
        {
          name: 'Ofertas',
          type: 'item',
          icon: <AssistantIcon />,
          link: '/tabla_oferta',
        },
        {
          name: 'Ingresar candidato',
          type: 'item',
          icon: <AccountBoxIcon />,
          link: '/ingresar_candidato',
        },
        {
          name: 'Consulta de candidatos',
          type: 'item',
          icon: <EditIcon />,
          link: '/consulta_candidatos',
        },
        {
          name: 'Buscar y vincular candidatos',
          type: 'item',
          icon: <SearchIcon />,
          link: '/buscar_y_vincular_candidato',
        },
        {
          name: 'Preseleccionar candidato',
          type: 'item',
          icon: <RecentActorsIcon />,
          link: '/preseleccionar_candidato',
        },
        {
          name: 'Establecer perfil contrato',
          type: 'item',
          icon: <AssignmentIcon />,
          link: '/perfil_contrato',
        },
        {
          name: 'Contratar candidato',
          type: 'item',
          icon: <ContactMailIcon />,
          link: 'contratacion'
        },
        {
          name: 'Desvincular un contratado',
          type: 'item',
          icon: <PersonAddDisabledIcon />,
          link: '/desvincular_contratado',
        },
        {
          name: 'Consultar lista restrictiva',
          type: 'item',
          icon: <ListIcon />,
          link: '/lista_restrictiva',
        },
      ],
    },
  ]
} else if (roles.some(rol => rol === "ROLE_TECNICO") || roles.some(rol => rol === "ROLE_FINANCIERO")) {
  sidebarNav = [
    {
      name: 'Menú principal',
      type: 'section',
      children: [
        {
          name: APROBAR_CUENTA_COBRO,
          type: 'item',
          icon: <AccountBalanceIcon />,
          link: URL_APROBAR_CUENTA_COBRO,
        },
        {
          name: 'Desvincular un contratado',
          type: 'item',
          icon: <PersonAddDisabledIcon />,
          link: '/desvincular_contratado',
        },
        {
          name: 'Consulta de candidatos',
          type: 'item',
          icon: <EditIcon />,
          link: '/consulta_candidatos',
        },
      ],
    },
  ]
}

export const sidebarNavs = sidebarNav;

export const horizontalDefaultNavs = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Página principal',
        type: 'item',
        icon: <PostAdd />,
        link: '/s',
      },
      {
        name: 'Iniciar sesión',
        type: 'item',
        icon: <PostAdd />,
        link: '/signin',
      },
      {
        name: 'Ofertas',
        type: 'item',
        icon: <PostAdd />,
        link: '/tabla_oferta',
      },
      {
        name: 'Ingresar candidato',
        type: 'item',
        icon: <PostAdd />,
        link: '/ingresar_candidato',
      },
      {
        name: 'Buscar y vincular',
        type: 'item',
        icon: <PostAdd />,
        link: '/buscar_y_vincular_candidato',
      },
      {
        name: 'Preseleccionar candidato',
        type: 'item',
        icon: <PostAdd />,
        link: '/preseleccionar_candidato',
      },
      {
        name: 'Establecer perfil contrato',
        type: 'item',
        icon: <PostAdd />,
        link: '/perfil_contrato',
      },
      {
        name: APROBAR_CUENTA_COBRO,
        type: 'item',
        icon: <PostAdd />,
        link: URL_APROBAR_CUENTA_COBRO,
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: 'Main',
    type: 'collapse',
    children: [
      {
        name: 'Página principal',
        type: 'item',
        icon: <PostAdd />,
        link: '/',
      },
      {
        name: 'Iniciar sesión',
        type: 'item',
        icon: <PostAdd />,
        link: '/signin',
      },
      {
        name: 'Ofertas',
        type: 'item',
        icon: <PostAdd />,
        link: '/tabla_oferta',
      },
      {
        name: 'Ingresar candidato',
        type: 'item',
        icon: <PostAdd />,
        link: '/ingresar_candidato',
      },
      {
        name: 'Buscar y vincular',
        type: 'item',
        icon: <PostAdd />,
        link: '/buscar_y_vincular_candidato',
      },
      {
        name: 'Preseleccionar candidato',
        type: 'item',
        icon: <PostAdd />,
        link: '/preseleccionar_candidato',
      },
      {
        name: 'Establecer perfil contrato',
        type: 'item',
        icon: <PostAdd />,
        link: '/perfil_contrato',
      },
      {
        name: APROBAR_CUENTA_COBRO,
        type: 'item',
        icon: <PostAdd />,
        link: URL_APROBAR_CUENTA_COBRO,
      },
    ],
  },
];
