import React, { useState, useEffect } from 'react';
import { Box, Typography, Button, Accordion, AccordionDetails, AccordionSummary, Grid } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Swal from "sweetalert2"
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { getFormacionesPreempleado } from '../../../helpers/formacionHelper/formacionHelper';
import { getExperienciasPreempleado } from '../../../helpers/experienciaHelper/experienciaHelper';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import { getDocumentoPreempleado } from '../../../helpers/fileHelper/downloadHelper';
import { Redirect } from 'react-router';
import { FORMACION_URL } from '../../../helpers/formacionUrl';
import { EXPERIENCIA_URL } from '../../../helpers/experienciaUrl';

const useStyles = makeStyles((theme) => ({
    button: {
        margin: theme.spacing(4),
    },
    boxInside: {
        width: "95%",
        border: '2px solid #3f51b5',

        marginTop: 20,
        paddingLeft: 10,
        paddingTop: 10,
        paddingBottom: 10
    }
}));
const swalErr = (err) => {
    Swal.fire(
        'Error',
        'Ocurrió un error realizando esta operación, por favor inténtelo de nuevo más tarde.',
        'error'
    )
}
const CandidatoInfo = ({ candidato, token, handleClose }) => {
    const [redirect, setRedirect] = React.useState(false)
    const [formaciones, setFormaciones] = useState([])
    const [experiencias, setExperiencias] = useState([])
    useEffect(() => {
        getFormacionesPreempleado(candidato.id)
            .then(res => res.data)
            .then(setFormaciones)
            .catch(err => {
                swalErr(err)
                setRedirect(true)
            }
            )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        getExperienciasPreempleado(candidato.id)
            .then(res => res.data)
            .then(setExperiencias)
            .catch(err => swalErr(err))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [expanded, setExpanded] = React.useState('panel1')
    const handleDownload = (filename) => {
        getDocumentoPreempleado(candidato.postulacion.id , candidato.numeroDocumento, filename)
            .then(res => res.data)
            .then(data => {
                var link = document.createElement("a");
                document.body.appendChild(link);
                link.setAttribute("type", "hidden");
                link.href = "data:text/plain;base64," + data;
                link.download = filename;
                link.click();
                document.body.removeChild(link);
            })
            .catch(err => swalErr(err))
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const classes = useStyles();

    const genero = (generoString) => {
        if (generoString === 'M') return 'Masculino';
        else if (generoString === 'F') return 'Femenino';
        else if (generoString === 'o') return 'Otro';
        else return '';
    }

    return (
        <Box sx={{
            position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)',
            width: "80%", bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24,
            p: 4, overflow: 'scroll', display: 'block', height: '90%', overflowX: 'hidden',

        }}> <Typography id="modal-modal-title" variant="h1">{candidato.nombres} {candidato.apellidos}</Typography>
            {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
            <Box sx={{ marginTop: 20 }} >
                <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header">
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Información personal del candidato
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className={classes.boxInside}>
                            <Typography id="modal-modal-description" variant="h3">Género</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">
                                {genero(candidato.genero)}
                            </Typography>
                            <Typography id="modal-modal-description" variant="h3">Fecha de nacimiento</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.fechaNacimiento}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Tipo de documento</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.tipoDocumento}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Número de documento</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.numeroDocumento}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Documento de identidad</Typography>
                            <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleDownload(candidato.documentoIdentidad)} className={classes.button}
                                endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                            <Typography id="modal-modal-description" variant="h3">RH</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.rh}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Ciudad de residencia</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">
                                {candidato.ciudad.nombre + " - " + candidato.ciudad.departamento.nombre}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Dirección</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.direccion}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Celular</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.celular}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Teléfono fijo</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.telefonoFijo}</Typography>
                            <Typography id="modal-modal-description" variant="h3">Correo</Typography>
                            <Typography id="modal-modal-description" variant="subtitle1">{candidato.correo}</Typography>
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header" >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Formaciones del candidato
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className={classes.boxInside}>
                            <Typography id="modal-modal-description" variant="h2">Formación</Typography>
                            {formaciones.length > 0
                                ? formaciones.map((formacion) => (
                                    <Box className={classes.boxInside} key={formacion.id}>
                                        <Typography id="modal-modal-description" variant="h3">Nombre programa</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{formacion.nombre}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Centro educativo</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{formacion.centroEducativo}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Tipo de formación</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{formacion.tipo}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Diploma</Typography>
                                        <Button variant="contained" size='small' color="secondary"
                                            onClick={() => handleDownload(FORMACION_URL + formacion.diploma)} className={classes.button}
                                            endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                         {formacion.tarjeta === true ? 
                                            <div>
                                                <Typography id="modal-modal-description" variant="h3">Tarjeta profesional</Typography>
                                                <Button variant="contained" size='small' color="secondary"
                                                    onClick={() => handleDownload(FORMACION_URL + formacion.tarjetaProfesional)} className={classes.button}
                                                    endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                                <Typography id="modal-modal-description" variant="h3">Certificado de vigencia</Typography>
                                                <Button variant="contained" size='small' color="secondary"
                                                    onClick={() => handleDownload(FORMACION_URL + formacion.certificadoVigenciaTarjetaProfesional)} className={classes.button}
                                                    endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                            </div>
                                        : null}
                                    </Box>
                                )) : <Typography id="modal-modal-description" variant="subtitle1">No se encontraron formaciones</Typography>}
                        </Box>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header" >
                        <Typography sx={{ width: '33%', flexShrink: 0 }}>
                            Experiencias del candidato
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Box className={classes.boxInside}>
                            <Typography id="modal-modal-description" variant="h2">Experiencias</Typography>
                            {experiencias.length > 0
                                ? experiencias.map((experiencia) => (
                                    <Box className={classes.boxInside} key={experiencia.id}>
                                        <Typography id="modal-modal-description" variant="h3">Empresa</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.empresa}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Área</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.area}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Cargo</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.cargo}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Descripción</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.descripcion}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Fecha Inicio</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.fechaInicio}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Fecha Final</Typography>
                                        <Typography id="modal-modal-description" variant="subtitle1">{experiencia.fechaFinal}</Typography>
                                        <Typography id="modal-modal-description" variant="h3">Certificado laboral</Typography>
                                        <Button variant="contained" size='small' color="secondary"
                                            onClick={() => handleDownload(EXPERIENCIA_URL + experiencia.certificadoLaboral)} className={classes.button}
                                            endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                    </Box>
                                )) : <Typography id="modal-modal-description" variant="subtitle1">No se encontraron experiencias</Typography>}
                        </Box>
                    </AccordionDetails>
                </Accordion>
            </Box>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                style={{ marginTop: 20 }}
            >   <Grid item xs={3}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleClose}>Salir</Button>
                </Grid>
            </Grid>
        </Box >
    )

}
const CandidatoInfoRef = React.forwardRef((props, ref) => (<CandidatoInfo innerRef={ref} {...props} />))


export default CandidatoInfoRef;
