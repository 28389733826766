import React, { useState } from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/// For the CandidatosSearchTable component
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  usePagination,
} from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Card from "@material-ui/core/Card";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import IconButton from "@material-ui/core/IconButton";
import TableRow from "@material-ui/core/TableRow";
import {
  MenuItem,
  Select,
  Modal,
  TextField,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@material-ui/core";
import Input from "@material-ui/core/Input";
import TypeText from "../../../components/Campos/TypeText";
import { dateFormat } from "../../../constants/AppConstants";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  getCandidatoBlackListPage,
  getCandidatoNumeroDocumento,
  putAgregarCandidatoListaNegra,
  putRemoverCandidatoListaNegra} from "../../../helpers/candidato/candidato";
import { downloadDocumentsCandidato } from "../../../helpers/fileHelper/downloadHelper";
import Tooltip from "@mui/material/Tooltip";
import { Link } from "react-router-dom";
import { FORMACION_URL } from "../../../helpers/formacionUrl";
import { IS_NOT_EMPTY } from "../../../constants/MensajeHelper";
import { EXPERIENCIA_URL } from "../../../helpers/experienciaUrl";
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme) => ({
  internalCard: {
    margin: 25,
    padding: 15,
  },
  margin: {
    margin: 15,
  },
  table: {
    minWidth: 700,
  },
  cell: {
    maxWidth: 100,
    overflowWrap: "anywhere",
  },
  centralButton: {
    margin: 15,
  },
  hoverPointer: {
    cursor: "pointer",
    color: "#0000EE",
  },
  notDataText: {
    margin: 15,
  },
  formText: {
    margin: 15,
    paddingTop: 5,
  },
  inputPading: {
    padding: 5,
  },
  paper: {
    position: "absolute",
    width: "70%",
    height: "80%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  paperAgregar: {
    position: "absolute",
    width: "50%",
    minWidth: "450px",
    maxHeight: "450px",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
  },
  typography: {
    paddingLeft: theme.spacing(8),
    marginTop:25,
    paddingTop:5
  },
  buttons:{
    display: "flex",
    justifyContent: "center",
    alignContent:"center"
  },
  edit:{
    display:"flex",
    color:"#3f51b5"
  },
  color: {
    color: 'red'
  },
  textFieldRoot: {
    width: "305px",
  },
  inputs: {
		display: 'flex',
		flexWrap: 'wrap'
	},
  filters: {
    maxWidth: '534px',
		display: 'flex',
		flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '@media screen and (max-width:644px)' :{
      justifyContent: 'flex-start'
    }
	},
  marginButtons:{
    margin: '5px 15px'
  }
}));


const VerDetalleCandidato = (row, modalOpen) => {
  const classes = useStyles();
  return (
    <div>
      <Tooltip title="Ver detalle">
        <Typography
          variant="inherit"
          className={classes.hoverPointer}
          onClick={() => {
            modalOpen(row.original);
          }}
        >
          Ver detalle
        </Typography>
      </Tooltip>
    </div>
  );
};

const Actualizar = (row) => {
  const classes = useStyles();
  return (
    <IconButton aria-label="Actualizar">
      <Tooltip title="Actualizar">
        <Link color="primary" className={classes.edit}
          to={{
            pathname: "/postulacion/update",
            state: { props: row.original },
          }}
        >
          <EditIcon />
        </Link>
      </Tooltip>
    </IconButton>
  );
};

const Eliminar = (row, initialState) => {
  const eliminarDeListaNegra = (candidato) =>{
    const MySwal = withReactContent(Swal);
    MySwal.fire({
      title: "¿Está seguro de remover el candidato?",
      text: `Está seguro de eliminar a ${candidato.nombres}  ${candidato.apellidos} de la lista restrictiva`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Si",
      cancelButtonText: "No",
      reverseButtons: true
    }).then(async (result) => {
      if(result.isConfirmed){
        await putRemoverCandidatoListaNegra(candidato.id, candidato).then((res)=>{
          MySwal.fire("Candidato removido","El candidato ya no pertenece a la lista restrictiva","info")
          initialState({})
        }).catch((err)=>{
            MySwal.fire("Hubo un problema","El candidato no se encuentra en lista restrictiva","error")
        })
      }else{
        MySwal.fire('',"No se eliminó el candidato de la lista restrictiva","info")
      }
    })
  }
  return (
    <IconButton aria-label="Eliminar">
      <Tooltip title="Eliminar">
        <Button
          color="primary"
          onClick={()=>{
            eliminarDeListaNegra(row.original)
          }}>
          <DeleteIcon />
        </Button>
      </Tooltip>
    </IconButton>
  );
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Table({
  props,
  onReloadTable,
  columns,
  data,
  initialState,
  dataQuery,
  editable = false,
}) {
  const classes = useStyles();
  const [candidatoModal, setCandidatoModal] = React.useState({
    nombre: "",
    fechaNacimiento: "",
    tipoDocumento: "",
    numeroDocumento: "",
    direccion: "",
    ciudad: "",
    rh: "",
    celular: "",
    correo: "",
    observacion: "",
    formaciones: [],
    experiencias: [],
  });
  const formInit = {
    isValid: true,
    values: {
      numeroDocumento: '',
      observacionListaRestriccion: '',
    },
    touched:{
      numeroDocumento: false,
      observacionListaRestriccion: false
    },errors:{
      numeroDocumento: false,
      observacionListaRestriccion: false,
    }
  }
  const [open, setOpen] = React.useState(false);
  const [openAgregar, setOpenAgregar] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [formState, setFormState] = useState(formInit);
  const [numeroDocumento, setNumeroDocumento] = React.useState("");

  const mensajeHelper = {
    numeroDocumento: {
      presence: IS_NOT_EMPTY,
    },
    observacionListaRestriccion: {
      presence: IS_NOT_EMPTY,
      length: {
        maximum: 255,
        message: "no puede sobrepasar los 255 caracteres",
      },
    },
  };


  const handleDownload = (filename) => {
    const MySwal = withReactContent(Swal);
    downloadDocumentsCandidato(candidatoModal.numeroDocumento, filename)
      .then((res) => res.data)
      .then((fileBits) => {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = "data:text/plain;base64," + fileBits;
        link.download = filename;
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) =>
        MySwal.fire("Error", "Ocurrió un error generando la consulta", "error")
      );
  };

  const TablaCandidatoFormaciones = () => {
    return candidatoModal.formaciones.length > 0
      ? candidatoModal.formaciones.map((item) => {
          return (
            <TableRow key={item.id} className={classes.internalCard}>
              <TableCell className={classes.cell}>{item.tipo}</TableCell>
              <TableCell className={classes.cell}> {item.nombre}</TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.centroEducativo}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {dateFormat(item.fechaCulminacion)}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.tarjetaProfesional ? dateFormat(item.fechaExpedicionTarjetaProfesional) : "---"}
              </TableCell>
              <TableCell>
                <Accordion>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                    >
                        <Typography className={classes.heading}>Descargar</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div>
                            <Typography variant="h4" align="left" color="textPrimary">Diploma</Typography>
                            <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleDownload(FORMACION_URL + item.diploma)}
                                className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                {item.tarjeta === true ?
                                <div>
                                    <Typography variant="h4" align="left" color="textPrimary">Tarjeta profesional</Typography>
                                    <Button variant="contained" size='small' color="secondary"
                                        onClick={() => handleDownload(FORMACION_URL + item.tarjetaProfesional)}
                                        className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                    <Typography variant="h4" align="left" color="textPrimary">Certificado de vigencia</Typography>
                                    <Button variant="contained" size='small' color="secondary"
                                        onClick={() => handleDownload(FORMACION_URL + item.certificadoVigenciaTarjetaProfesional)}
                                        className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                                </div>
                                : null}
                        </div>
                    </AccordionDetails>
                </Accordion>
            </TableCell>
            </TableRow>
          );
        })
      : null;
  };

  const TablaCandidatoExperiencias = () => {
    return candidatoModal.experiencias.length > 0
      ? candidatoModal.experiencias.map((item) => {
          return (
            <TableRow key={item.id} className={classes.internalCard}>
              <TableCell className={classes.cell}> {item.empresa}</TableCell>
              <TableCell className={classes.cell}> {item.area}</TableCell>
              <TableCell className={classes.cell}> {item.cargo}</TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {item.descripcion}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {dateFormat(item.fechaInicio)}
              </TableCell>
              <TableCell className={classes.cell}>
                {" "}
                {dateFormat(item.fechaFinal)}
              </TableCell>
              <TableCell
                className={classes.hoverPointer}
                onClick={() => handleDownload(EXPERIENCIA_URL + item.certificadoLaboral)}
              >
                {" "}
                Descargar{" "}
              </TableCell>
            </TableRow>
          );
        })
      : null;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseAgregar = () =>{
    setFormState(formInit)
    setOpenAgregar(false);
  }

  const modalOpen = (candidato) => {
    setCandidatoModal({
      ...candidatoModal,
      nombre: candidato.nombres + " " + candidato.apellidos,
      fechaNacimiento: candidato.fechaNacimiento,
      tipoDocumento: candidato.tipoDocumento,
      formaciones: candidato.formaciones,
      experiencias: candidato.experiencias,
      numeroDocumento: candidato.numeroDocumento,
      ciudad: candidato.ciudad,
      direccion: candidato.direccion,
      rh: candidato.rh,
      celular: candidato.celular,
      correo: candidato.correo,
      observacion: candidato.observacionListaRestriccion
    });
    setOpen(true);
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { numeroDocumento, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHooked) => [
        // Let's make a column for selection
        ...columnsHooked,
        {
          id: "detail",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Typography variant="subtitle2"> Detalle </Typography>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => <div>{VerDetalleCandidato(row, modalOpen)}</div>,
        },
        {
          id: "update",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Typography variant="subtitle2"> Acciones </Typography>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => {
            return (
                <div className={classes.buttons}>
                  {Actualizar(row)}
                  {Eliminar(row,initialState)}
                </div>
            );
          },
        },
      ]);
    }
  );

  const modalOpenAgregar = () =>{
    setOpenAgregar(true);
  }

  const onSubmit = async (e) =>{
    e.preventDefault();
    const MySwal = withReactContent(Swal);
    await getCandidatoNumeroDocumento(formState.values.numeroDocumento).then((res)=>{
      handleCloseAgregar();
      MySwal.fire({
        title: "Lista restrictiva",
            text: `¿Estás seguro de añadir a ${res.data.nombres} ${res.data.apellidos} con
                   número de identificación ${res.data.numeroDocumento} a la lista restrictiva?,
                   una vez añadido, el usuario no podrá ser parte de los procesos de grupo IS.`,
            showCancelButton: true,
            confirmButtonText: "Agregar a lista restrictiva",
            cancelButtonText: "Cancelar",
            reverseButtons: true,
      }).then(async (result)=>{
        if(result.isConfirmed){
          res.data.observacionListaRestriccion =
            formState.values.observacionListaRestriccion;
          await putAgregarCandidatoListaNegra(res.data.id, res.data).then((res)=>{
            MySwal.fire('',`Se ha añadido al candidato ${res.data.nombres} ${res.data.apellidos} con
                         con número de identificación ${res.data.numeroDocumento} a la lista restrictiva,
                         puedes deshacer está acción desde la pantalla de gestión de lista restrictiva.`
                         ,"info")
            initialState({})
          }).catch((err)=>{
            if(err.response.data.title === "Candidato is already blacklisted"){
              MySwal.fire(
                "Hubo un problema",
                "El candidato ya se encuentra en lista restrictiva.",
                "error"
              );
            }else{
              MySwal.fire(
                "¡Es necesario validar!",
                "El candidato no se puede agregar a lista restrictiva porque se encuentra vinculado a un proceso u oferta.",
                "error"
              );
            }
          })
        }
        if(result.isDismissed){
          MySwal.fire("Cancelado","No se agrego el candidato a lista restrictiva.","info");
        }
        setFormState(formInit)
      })
    }).catch((err)=>{
      handleCloseAgregar()
      console.log(err)
      MySwal.fire("Error", "El candidato no fue encontrado.", "error")
      setFormState(formInit);
    })
  }

  const buscarCandidatos = () =>{
    initialState({numeroDocumento})
  }

  return (
    <div>
      <Modal open={open} onClose={handleClose}>
          <div style={modalStyle} className={classes.paper}>
            <Typography variant="h1" align="left" color="textPrimary">
              {candidatoModal.nombre}
            </Typography>
            <Card className={classes.internalCard}>
              <Typography variant="h4" align="left" color="textPrimary">
                Fecha nacimiento
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {dateFormat(candidatoModal.fechaNacimiento)}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Tipo de documento
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.tipoDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Número de documento
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.numeroDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Ciudad de residencia
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.ciudad.nombre}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Dirección
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.direccion}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                RH
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.rh}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Celular
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.celular}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Correo
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.correo}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Observación de lista restrictiva
              </Typography>
              <Typography style={{wordBreak: 'break-all'}} variant="body2" align="left" color="textPrimary">
                {candidatoModal.observacion}
              </Typography>
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Formaciones
            </Typography>
            <Card className={classes.internalCard}>
              {candidatoModal.formaciones.length ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          <strong>Tipo Formación</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Nombre del Programa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Centro educativo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de culminación </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de expedición tarjeta profesional </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Diploma </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoFormaciones()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El candidato no tiene formaciones registradas
                </Typography>
              )}
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Experiencias
            </Typography>
            <Card className={classes.internalCard}>
              {candidatoModal.experiencias.length ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Empresa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Área </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Cargo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Descripción </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Inicio </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Final </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Acciones
                             </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoExperiencias()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El candidato no tiene experiencias registradas
                </Typography>
              )}
            </Card>
            <Grid item xs={12} align="center">
              <Button
                onClick={() => handleClose()}
                variant="contained"
                color="secondary"
              >
                {" "}
                Cerrar
              </Button>
            </Grid>
          </div>
        </Modal>
        <Modal open={openAgregar} onClose={handleCloseAgregar}>
        <div style={modalStyle} className={classes.paperAgregar}>
            <form noValidate autoComplete="off" onSubmit={onSubmit}>
              <Grid container item xs={12}>
                <Grid item xs={5}>
                <Typography variant='body1' className={classes.typography}>Documento de identidad<span className={classes.color}>*</span></Typography>
                </Grid>
                <Grid item xs={6}>
                  <TypeText
                  id='numeroDocumento'
                  formState={formState}
                  setFormState={setFormState}
                  helperText={mensajeHelper}
                  fullWidth
                  InputProps={{
                    className:classes.input
                  }}/>
                </Grid>
              </Grid>
              <Typography variant='body1' className={classes.typography}>Motivo por el cual incluye al candidato a la lista restrictiva<span className={classes.color}>*</span></Typography>
              <Grid container item xs={12}>
                  <TypeText
                  id='observacionListaRestriccion'
                  formState={formState}
                  setFormState={setFormState}
                  helperText={mensajeHelper}
                  multiline maxRows={4}
                  fullWidth
                  className={classes.input}/>
              </Grid>
              <Grid align="center" item xs={12}>
                  <Button className={classes.centralButton} onClick={() => handleCloseAgregar()} variant="contained" color="secondary"> Cerrar</Button>
                  <Button className={classes.centralButton} type="submit" disabled={!formState.isValid} variant="contained" color="primary"> Continuar</Button>
              </Grid>
            </form>
          </div>
        </Modal>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography
              variant="h1"
              fontSize={20}
              align="left"
              color="textPrimary"
            >
              Lista restrictiva
            </Typography>
          </Grid>
          <Grid item xs={6} align="right">
            <Link to={"lista_restrictiva"}>
              <Button type="button" variant="contained" color="primary" onClick={modalOpenAgregar}>
                Agregar candidatos a lista restrictiva
              </Button>
            </Link>
          </Grid>
        </Grid>
        <Typography variant="subtitle1" align="left" color="textPrimary">
          Agrega o quita personas de la lista restrictiva de Grupo IS.
        </Typography>
        <br></br>
        <div className={classes.inputs}>
            <Typography
              className={classes.formText} color="textPrimary">
              <label htmlFor="listaNegra">
                Documento de identidad
              </label>
            </Typography>
            <TextField
              id="editarUsuario"
              variant="outlined"
              className={classes.textFieldRoot}
              value={numeroDocumento || ""}
              placeholder={' Inserta el número de documento'}
              onChange={(e) => {
                setNumeroDocumento(e.target.value);
              }}
            />
        </div>
        <div className={classes.filters}>
            <Button className={classes.marginButtons} size="small" variant="text"
              onClick={() => {
                  initialState({});
                  setNumeroDocumento("");
              }} > Limpiar
            </Button>
            <Button className={classes.marginButtons} onClick={() => buscarCandidatos()} variant="contained" color="primary">
                Buscar
            </Button>
        </div>
        <br />
        {page.length === 0 ? "No hay candidatos en lista restrictiva" :
        <div>
        <div style={{ overflow: "auto", margin: "20px 0" }}>
          <MaUTable className={classes.table} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      className={classes.cell}
                      {...column.getHeaderProps()}
                    >
                      <strong>{column.render("Header")}</strong>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={classes.cell}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaUTable>
        </div>
        <div className="pagination">
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: 0 });
            }}
            disabled={dataQuery.first}
          >
            {"<<"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.number - 1 });
            }}
            disabled={dataQuery.first}
          >
            {"<"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.number + 1 });
            }}
            disabled={dataQuery.last}
          >
            {">"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.totalPages - 1 });
            }}
            disabled={dataQuery.last}
          >
            {">>"}
          </Button>{" "}
          <span>
            Página{" "}
            <strong>
              {dataQuery.number + 1} de {dataQuery.totalPages}
            </strong>{" "}
          </span>
          <span>
            | Ir a la página:{" "}
            <Input
              type="number"
              defaultValue={dataQuery.number + 1}
              onChange={(e) => {
                if (e.target.value < dataQuery.totalPages) {
                  initialState({ numeroDocumento, page: Number(e.target.value) });
                }
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              initialState({
                numeroDocumento,
                page: dataQuery.number,
                pageSize: Number(e.target.value),
              });
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSizeMaper) => (
              <MenuItem key={pageSizeMaper} value={pageSizeMaper}>
                Mostrar {pageSizeMaper}
              </MenuItem>
            ))}
          </Select>
        </div>
      </div>}
    </div>
  );
}

const CandidatosTable = (props, candidatos, dataQuery, initialState) => {
  const classes = useStyles();
  const columns = [
    {
      Header: "Número de documento",
      accessor: "numeroDocumento",
    },
    {
      Header: "Nombre",
      accessor: "nombreCompleto",
    },
  ];
  if (candidatos !== undefined) {
    if (candidatos.length > 0) {
      for (const i in candidatos) {
        candidatos[i].nombreCompleto =
          candidatos[i].nombres + " " + candidatos[i].apellidos;
      }
    }
  }

  return (
    <div>
      <Card className={classes.internalCard}>
        {candidatos ? (
          <Table
            props={props}
            columns={columns}
            data={candidatos}
            dataQuery={dataQuery}
            initialState={initialState}
          />
        ) : (
          <Typography variant="h2" color="textPrimary">
            {" "}
            No se encontraron candidatos en lista restrictiva{" "}
          </Typography>
        )}
      </Card>
    </div>
  );
};

const useSingleton = (
  callBack = () => {
    //Empty callback
  }
) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const TablaCandidatos = (props) => {
  const [candidatos, setCandidatos] = React.useState({ content: [] });
  const [redirect, setRedirect] = React.useState(false);
  const initialState = async ({ numeroDocumento = "", page = 0, pageSize = 10 }) => {
    const candidatoQuery = await getCandidatoBlackListPage({
      numeroDocumento: numeroDocumento,
      page: page,
      pageSize: pageSize,
    })
      .then((response) => {
        return response.data;
      })
      .catch((err) => setRedirect(true));
    setCandidatos(candidatoQuery);
  };
  useSingleton(async () => {
    await initialState({});
  });
  return (
    <div>
      {redirect ? (
        <Redirect
          push
          to={{ pathname: "/signin", state: { goBack: "/lista_restrictiva" } }}
        />
      ) : null}
      {CandidatosTable(props, candidatos.content, candidatos, initialState)}
    </div>
  );
};

export default TablaCandidatos;
