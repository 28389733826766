import React, { useState } from 'react';
import { Box, alpha, Button, Select, FormControl, Typography, Grid, MenuItem, CardContent } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TypeText from '../../../../components/Campos/TypeText'
import TypeForm from '../../../../components/Campos/TypeForm'
import { getDepartamentos, getDepartamentosId } from '../../../../helpers/departamentos/departamentosHelper';
import { candidatoAdmin } from '../../../../helpers/candidato/candidato';
import { IS_NOT_EMPTY, IS_NUMERIC, IS_VALID_EMAIL, LATEST_DATE } from '../../../../constants/MensajeHelper'
import { Redirect } from 'react-router';
import moment from 'moment';
import { sortCiudadesAlphabetically, sortDepartamentosAlphabetically } from '../../../../utils/orderHelper';

const useStyles = makeStyles(theme => ({

  subtitleObligatorio: {
    marginBottom: '10px',
    marginTop: '10px',
    paddingLeft: theme.spacing(8),
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
    'MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
    width: "100%",
    height: "15%",
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  inputStyle: {
    wordBreak: 'break-word'
  },
  color: {
    color: 'red'
  }
}));

const useSingleton = (callBack = () => { }) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
}

const fechaActual = moment().subtract(1, "days").format("YYYY-MM-DD");

const mensajeHelper = {
  nombres: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  apellidos: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 25, message: "no puede sobrepasar los 25 caracteres" },
  },
  tipoDocumento: {
    presence: IS_NOT_EMPTY,
  },
  numeroDocumento: {
    presence: IS_NOT_EMPTY,
  },
  fileDocumento: {
    presence: IS_NOT_EMPTY,
  },
  direccion: {
    presence: IS_NOT_EMPTY,
    length: { minimum: 5, maximum: 80, tooShort: "tiene que tener 5 caracteres o más", tooLong: "no puede sobrepasar los 80 caracteres" },
  },
  celular: {
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,
    length: { is: 10, message: "tiene que tener 10 dígitos" },
  },
  telefonoFijo: {
    presence: { allowEmpty: true },
    length: { maximum: 7, message: "tiene que tener 7 dígitos" },
  },
  fechaNacimiento: {
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  genero: {
    presence: IS_NOT_EMPTY,
  },
  rh: {
    presence: IS_NOT_EMPTY,
  },
  madreCabezaHogar: {
    presence: IS_NOT_EMPTY,
  },
  correo: {
    presence: IS_NOT_EMPTY,
    length: { maximum: 50, message: "no puede sobrepasar los 50 caracteres" },
    email: IS_VALID_EMAIL,
  },
  idCiudad: {
    presence: IS_NOT_EMPTY,
  },
  departamento: {
    presence: IS_NOT_EMPTY,
  },
};

const CustomTypeText = ({ titulo, id, formState, setFormState, classes, ...props }) => {
  return (
    <Box mb={2}>
      <Grid container item xs={12}>
        <Typography>{titulo}<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <FormControl fullWidth className={classes.textFieldRoot}>
        <TypeText
          type={props?.type ? props.type : ""}
          id={id}
          formState={formState}
          setFormState={setFormState}
          helperText={mensajeHelper}
          fullWidth
          multiline={props?.multiline ? true : false}
          InputLabelProps={props?.InputLabelProps ? props.InputLabelProps : {}}
          onInput={props?.onInput ? props.onInput : undefined}
          inputProps={props?.inputProps ? props.inputProps : ""}
        />
      </FormControl>
    </Box>
  )
}

const CustomTypeSelect = ({ titulo, id, formState, setFormState, datos, classes }) => {
  return (
    <Box mb={2}>
      <Grid containter="true" item xs={12}>
        <Typography> {titulo} <IsNotEmpty id={id} /></Typography>
      </Grid>
      <TypeForm id={id} formState={formState} setFormState={setFormState} helperText={mensajeHelper}>
        <MenuItem value="" />
        {datos.map(({ value, text }) => {
          return (<MenuItem key={value} value={value}>{text}</MenuItem>)
        })}
      </TypeForm>
    </Box>

  )
}

const CustomTypeDepartamento = ({ handleDepartamento, departamentoList, classes, id }) => {
  return (
    <Box mb={2}>
      <Grid container item xs={12}>
        <Typography>Departamento<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <FormControl variant="outlined" className={classes.textFieldRoot}>
          <Select name="departamento" defaultValue="" onChange={event => { handleDepartamento(event) }}>
            <MenuItem value="" />
            {departamentoList.length > 0 ?
              departamentoList.map((departamento) => {
                return (<MenuItem key={departamento.id} value={departamento.id}> {departamento.nombre} </MenuItem>)
              })
              : <MenuItem value="No se encontraron Departamento">No se encontraron departamentos</MenuItem>}
          </Select>
        </FormControl>
      </Grid>
    </Box>
  )
}

const CustomTypeCiudad = ({ formState, setFormState, ciudadList, classes, id }) => {
  return (
    <Box mb={2}>
      <Grid container item xs={12}>
        <Typography>Ciudad<IsNotEmpty id={id} />
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <TypeForm id="idCiudad" formState={formState} setFormState={setFormState} helperText={mensajeHelper}>
          <MenuItem value="" />
          {ciudadList.length > 0 ?
            ciudadList.map((ciudad) => {
              return (<MenuItem key={ciudad.id} value={ciudad.id}> {ciudad.nombre} </MenuItem>)
            })
            : <MenuItem value="No se encontraron Ciudades">Selecciona un departamento</MenuItem>}
        </TypeForm>
      </Grid>
    </Box>
  )
}

function IsNotEmpty(id) {
  const isEmpty = mensajeHelper[id.id]?.presence?.message;
  const style = { color: 'red' };
  if (isEmpty === "no debe estar vacío") {
    return <span style={style}>*</span>;
  }
  return <></>;
}

const documentType = [
  { value: "CC", text: "Cédula de ciudadanía" },
  { value: "CE", text: "Cédula de extranjería" },
  { value: "NIT", text: "NIT" },
  { value: "DE", text: "Tipo de documento extranjero" },
  { value: "PEP", text: "Permiso especial de permanencia" },
  { value: "PPT", text: "Permiso por Protección Temporal" },

];
const gender = [
  { value: "F", text: "Mujer" },
  { value: "M", text: "Hombre" },
  { value: "O", text: "Otro" },
]
const cabezaHogar = [
  { value: true, text: "Si" },
  { value: false, text: "No" },
]
const tipoRh = [
  { value: "O+", text: "O+" },
  { value: "O-", text: "O-" },
  { value: "A+", text: "A+" },
  { value: "A-", text: "A-" },
  { value: "B+", text: "B+" },
  { value: "B-", text: "B-" },
  { value: "AB+", text: "AB+" },
  { value: "AB-", text: "AB-" },
]

const MySwal = withReactContent(Swal);;

const CrearCandidato = ({ variant = 'default', setIdCandidato, setInfo }) => {
  const classes = useStyles({ variant });
  const [departamentoList, setDepartamentoList] = useState([]);
  const [ciudadList, setCiudadList] = useState([]);
  const [redirect, setRedirect] = useState(false)

  useSingleton(async () => {
    await getDepartamentos()
      .then(response => response.data)
      .then(data => {
        const departamentos = sortDepartamentosAlphabetically(data);
        return setDepartamentoList(departamentos)
      })
      .catch(err => {
        setRedirect(true)
        return err
      })
  });

  const handleDepartamento = async (e) => {
    if (e.target.value !== "") {
      const ciudades = await getDepartamentosId(e.target.value)
        .then((res) => {
          setFormState({
            ...formState,
            values: {
              ...formState.values,
              [e.target.name]: e.target.value,
            },
          });
          return sortCiudadesAlphabetically(res.data.ciudades, true);
        })
        .catch((err) => {
          console.log(err);
        });
      if (ciudades !== null) {
        setCiudadList(ciudades);
      }
    }
  };

  function onInputTel(e) {
    if (!isNaN(e.target.value)) {
      e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, 15)
    } else {
      e.target.value = ""
    }
  }

  const [formState, setFormState] = useState({
    isValid: false,
    values: {
      nombres: '',
      apellidos: '',
      tipoDocumento: '',
      numeroDocumento: '',
      fileDocumento: '',
      direccion: '',
      celular: '',
      telefonoFijo: '',
      fechaNacimiento: '',
      genero: '',
      rh: '',
      madreCabezaHogar: '',
      correo: '',
      idCiudad: null,
      departamento: null,
    },
    touched: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
      departamento: false,
    },
    errors: {
      nombres: false,
      apellidos: false,
      tipoDocumento: false,
      numeroDocumento: false,
      fileDocumento: false,
      direccion: false,
      celular: false,
      telefonoFijo: false,
      fechaNacimiento: false,
      genero: false,
      rh: false,
      madreCabezaHogar: false,
      correo: false,
      idCiudad: false,
      departamento: false,
    },
  });

  const onSubmit = async (e) => {
    e.preventDefault()

    const formData = new FormData();
    const candidato = Object.assign({}, formState.values);
    const doc = formState.values.fileDocumento;

    delete candidato.fileDocumento
    delete candidato.departamento
    candidato.versionTerminosYCondiciones = '1.0.0'
    if (candidato.telefonoFijo === "") {
      delete candidato.telefonoFijo
    }
    formData.append('candidato', new Blob([JSON.stringify(candidato)], { type: "application/json" }));
    formData.append('file', doc)

    await candidatoAdmin(formData)
      .then(function (response) {
        setIdCandidato(response.data.id);
        MySwal.fire({
          icon: 'success',
          title: 'Candidato creado'
        });
        setInfo(1)
      })
      .catch(function (response) {
        if (response.response.data.errorKey === 'emailexists') {
          MySwal.fire({
            icon: 'error',
            title: 'Este email ya está en uso'
          });
        } else if (response.response.data.detail === 'Document is already being used') {
          MySwal.fire({
            icon: 'error',
            title: 'Este número de documento ya está en uso'
          });
        }
      });
  };
  return (
    <CardContent>
      {redirect ? <Redirect push to={{ pathname: '/signin', state: { goBack: '/ingresar_candidato' } }} /> : null}
      <Typography component="div" variant="h1" className={classes.titleRoot} >Crear candidato</Typography>
      <br></br>
      <Typography className={classes.subtitle}>Ingresa la información básica del candidato</Typography>
      <Typography className={classes.subtitle}>Los campos marcados con <span className={classes.color}>*</span> son obligatorios</Typography>
      <br></br>
      <form noValidate autoComplete="off" onSubmit={onSubmit}>
        <CustomTypeText titulo="Nombres" id="nombres" formState={formState} setFormState={setFormState} classes={classes} />
        <CustomTypeText titulo="Apellidos" id="apellidos" formState={formState} setFormState={setFormState} classes={classes} />
        <CustomTypeSelect titulo="Tipo de documento" id="tipoDocumento" formState={formState} setFormState={setFormState} datos={documentType} classes={classes} />
        <CustomTypeText titulo="Número de documento" id="numeroDocumento" formState={formState} setFormState={setFormState} classes={classes} />
        <CustomTypeText titulo="Adjunte el documento de identidad" id="fileDocumento" formState={formState} setFormState={setFormState} classes={classes} type="file" InputLabelProps={{ shrink: true }} inputProps={{ accept: "image/jpeg,application/pdf" }} />
        <CustomTypeDepartamento handleDepartamento={handleDepartamento} departamentoList={departamentoList} classes={classes} id="departamento" />
        <CustomTypeCiudad formState={formState} setFormState={setFormState} ciudadList={ciudadList} classes={classes} id="idCiudad" />
        <CustomTypeText titulo="Dirección" id="direccion" formState={formState} setFormState={setFormState} classes={classes} />
        <CustomTypeText titulo="Celular" id="celular" formState={formState} setFormState={setFormState} classes={classes}
          onInput={event => onInputTel(event)} />
        <CustomTypeText titulo="Teléfono" id="telefonoFijo" formState={formState} setFormState={setFormState} classes={classes} type="number"
          onInput={event => onInputTel(event)} />
        <CustomTypeText titulo="Correo" id="correo" formState={formState} setFormState={setFormState} classes={classes} />
        <CustomTypeText titulo="Fecha de nacimiento" id="fechaNacimiento" formState={formState} setFormState={setFormState} type="date" classes={classes} inputProps={{ max: fechaActual }} />
        <CustomTypeSelect titulo="Género" id="genero" formState={formState} setFormState={setFormState} datos={gender} classes={classes} />
        <CustomTypeSelect titulo="¿Es madre cabeza de hogar?" id="madreCabezaHogar" formState={formState} setFormState={setFormState} datos={cabezaHogar} classes={classes} />
        <CustomTypeSelect titulo="Tipo de sangre" id="rh" formState={formState} setFormState={setFormState} datos={tipoRh} classes={classes} />
        <Grid container item xs={12} spacing={3} className={classes.button}>
          <Button variant="contained" color="primary" type="submit" disabled={!formState.isValid}>
            Crear Candidato
          </Button>
        </Grid>
      </form>
    </CardContent>
  );
};

export default CrearCandidato;