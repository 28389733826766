import axios from 'axios'
import { BASE_URL } from '../baseUrl'
import { getToken } from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json';

export const getCuentaCobro = (id) => {
    let config = {
        method: 'GET',
        url: BASE_URL + `cuenta-cobros/${id}`,
        headers: {
            'Authorization': token,
            'Conten-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getDocumentosCuentaCobro = ({ idContrato, fileName }) => {
    let config = {
        method: 'GET',
        params: {
            id: idContrato,
            fileName: fileName
        },
        url: BASE_URL + 'download_documentos_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Conten-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const updateCuentaCobro = (data) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'cuenta-cobros-vb',
        headers: {
            'Autorization': token,
            'Content-Type': contentType,
        },
        data: data,
        withCredentials: true,
    }
    return axios(config)
}

export const getCuentasCobro = () => {
    let config = {
        method: 'get',
        url: BASE_URL + 'cuenta-cobros',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getAllCuentaCobroPage = ({ page = 0, pageSize = 10 }) => {
    let config = {
        method: 'get',
        url: BASE_URL + 'cuentas-cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            pageNumber: page,
            pageSize: pageSize,
        }
    }
    return axios(config)
}

export const cuentaCobroByContrato = ({ id }) => {
    let config = {
        method: 'GET',
        url: BASE_URL + 'cuentas_cobro_contrato',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id
        }
    }
    return axios(config)
}

export const cuentaCobroByContratoTecnico = ({ id }) => {
    let config = {
        method: 'GET',
        url: BASE_URL + 'cuenta_cobro_contrato_tecnico',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id
        }
    }
    return axios(config)
}

export const cuentasCobroByContratoFinanciero = ({ id }) => {
    let config = {
        method: 'GET',
        url: BASE_URL + 'cuenta_cobro_contrato_financiero',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id
        }
    }
    return axios(config)
}

export const rechazoTecnicoCuentaCobro = ({ id, motivo }) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'rechazo_tecnico_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id,
            motivo: motivo
        }
    }
    return axios(config)
}

export const rechazoFinancieroCuentaCobro = ({ id, motivo }) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'rechazo_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id,
            motivo: motivo
        }
    }
    return axios(config)
}

export const preAprobarCuetaCobro = ({ id }) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'pre_aprobar_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id
        }
    }
    return axios(config)
}

export const aprobarCuentaCobro = ({ id }) => {
    let config = {
        method: 'POST',
        url: BASE_URL + 'aprobar_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
        params: {
            id: id
        }
    }
    return axios(config)
}

export const getCuentaCobroFinanciero = () => {
    let config = {
        method: 'GET',
        url: BASE_URL + 'financiero_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

export const getCuentaCobroTecnico = () => {
    let config = {
        method: 'GET',
        url: BASE_URL + 'tecnico_cuenta_cobro',
        headers: {
            'Authorization': token,
            'Content-Type': contentType
        },
        withCredentials: true,
    }
    return axios(config)
}

