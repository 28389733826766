import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Modal, Card, CardContent, Grid, Typography, Collapse } from '@material-ui/core';
import { getPreempleadosContratacion, rechazarContratacion, getPreempleadoContratado } from '../../../helpers/preempleado/preempleadoHelper';
import Swal from "sweetalert2"
import withReactContent from 'sweetalert2-react-content';
import ContratacionTable from './TablaContratacionComponent';
import PreempleadoInfoRef from './PreempleadoInfo';
import ModalContratacionRef from './ModalContratacionRef'
import { errorAlert } from "../../../helpers/swalHelper/swalHelper"
import { getToken } from '../../../services/auth/jwt/getToken'
import { postContratacion } from "../../../helpers/contratoHelper/contratoHelper"
import { FiltroOferta } from '../../../components/FiltroOferta';

const MySwal = withReactContent(Swal);

const useStyles = makeStyles(theme => ({
  internalCard: {
    margin: 25,
  },
  table: {
    width: "100%",
    alignContent: 'center',
    justifyContent: 'center',
    overflowX: 'auto'
  },
  textFieldRoot: {
    width: "70%",
    height: "15%",
  },
  title: {
    textAlign: 'center',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginTop: '20px'
  },
  form: {
    paddingLeft: '25%',
    paddingTop: 15
  },
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
    marginBottom: '20px',
    marginTop: '30px',
  },
  formText: {
    margin: 15,
    paddingTop: 5,
    paddingLeft: theme.spacing(8),
    width: "70%",
    height: "15%",
  },
  inputPading: {
    padding: 5,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  ol: {
    paddingLeft: theme.spacing(8),
    marginBottom: '40px',
  },
  hoverPointer: {
    cursor: "pointer",
  },
  color: {
    color: 'red'
  }
}));

const token = getToken()


const ModalContratacion = ({ oferta, openModalContratacion, toggleContratacion, classes, contratar }) => (
  <Modal
    open={openModalContratacion}
    onClose={toggleContratacion}
    style={{ zIndex: 1000 }}
  >
    <ModalContratacionRef
      token={token}
      oferta={oferta}
      toggleContratacion={toggleContratacion}
      classes={classes}
      contratar={contratar}
    />
  </Modal>
)

export default function Contratacion() {
  const classes = useStyles();
  const [oferta, setOferta] = useState(null)
  const [mostrarTabla, setMostrarTabla] = useState(false)
  const [preempleados, setPreempleados] = useState([])
  const [detailsPreempleado, setDetailsPreempleado] = useState(null);
  const [idPreempleado, setIdPreempleado] = useState(null);
  const [numeroDocumento, setNumeroDocumento] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [openModalContratacion, setOpenModalContratacion] = useState(false);
  const toggleContratacion = () => setOpenModalContratacion(state => !state);
  const handleOpen = (preempleado) => {
    setDetailsPreempleado(preempleado);
    setOpen(true)
  };
  const handleClose = () => setOpen(false);
  const handleSubmit = () => {
    if (oferta) {
      getPreempleadosContratacion(token, oferta.id)
        .then(res => res.data)
        .then(data => {
          setPreempleados(data);
        })
        .catch(errorAlert)
      setMostrarTabla(true);
    }
  }
  const handleOpenModalContratacion = async (data, candidatoId) => {
    const preempleadoContratado = await getPreempleadoContratado(candidatoId)
      .then(res => res.data);
    if (preempleadoContratado) { 
      Swal.fire({
        icon: 'error',
        title: 'Candidato ya contratado',
        text: 'El candidato ya se encuentra vinculado a un contrato.'
      })
    } else {
      setNumeroDocumento(data.numeroDocumento)
      setIdPreempleado(data.id);
      setOpenModalContratacion(true);
    }
    
  }
  const contratar = (contrato, file) => {
    contrato = {
      ...contrato,
      idPreempleado: idPreempleado
    }
    const newFormData = new FormData();
    newFormData.append('contrato', new Blob([JSON.stringify(contrato)], { type: "application/json" }));
    newFormData.append('file', file);
    newFormData.append('numeroDocumento', numeroDocumento);
    
    Swal.fire({
      title: '¿Contratar candidato?',
      showCancelButton: true,
      reverseButtons: true,
      cancelButtonColor: '#f50057',
      confirmButtonText: 'Contratar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        postContratacion(newFormData)
          .then(res => {
            toggleContratacion();
            MySwal.fire('El candidato se ha contratado', '', 'success');
            handleSubmit();
          })
          .catch(errorAlert)
      }
    })
  }
  const handleReject = async (id) => {
    MySwal.fire({
      title: 'Motivo del rechazo',
      input: 'textarea',
      inputPlaceholder: 'Ingrese el motivo de rechazo...',
      inputAttributes: {
        autocapitalize: 'off',
        style: "font-family: Roboto,sans-serif"
      },
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Rechazar',
      showLoaderOnConfirm: true,
      preConfirm: (observacion) => {
        if (!observacion) {
          Swal.showValidationMessage('No puede estar vacío')
        } else {
          rechazarContratacion(token, { id, observacion })
            .then(res => {
              MySwal.fire('El candidato se ha rechazado', '', 'success')
              handleSubmit()
            }
            )
            .catch(
              errorAlert
            )
        }
      },
      allowOutsideClick: () => !MySwal.isLoading()
    })
  }
  return (
    <div>
      <Card className={classes.internalCard}>
        <CardContent>
          <Typography variant='h1'>Contratar candidato</Typography>
          <Typography  className={classes.subtitle}>Aprueba o rechaza candidatos preseleccionados</Typography>
          <Typography  className={classes.subtitle}>Los campos con <span className={classes.color}>*</span> son obligatorios</Typography>
          <br></br>
          <FiltroOferta setOferta={setOferta} setMostrarTabla={setMostrarTabla} />

          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleSubmit}
              disabled={!oferta}>
              Buscar candidatos
            </Button>
          </Grid>
          <Grid container item xs={12} className={classes.table}>
            <Collapse in={mostrarTabla}>
              {mostrarTabla &&
                <ContratacionTable
                  preempleados={preempleados}
                  classes={classes}
                  setLoading={setLoading}
                  loading={loading}
                  handleOpen={handleOpen}
                  handleReject={handleReject}
                  handleOpenModalContratacion={handleOpenModalContratacion}
                  setIdPreempleado={setIdPreempleado} />}
            </Collapse>
          </Grid>
        </CardContent>
      </Card>
      <ModalInfo preempleado={detailsPreempleado} handleClose={handleClose} open={open} />
      <ModalContratacion
        oferta={oferta}
        openModalContratacion={openModalContratacion}
        toggleContratacion={toggleContratacion}
        classes={classes}
        contratar={contratar} />
    </div>
  );
}

const ModalInfo = ({ preempleado, handleClose, open }) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >

    <PreempleadoInfoRef preempleado={preempleado} token={token} handleClose={handleClose} />


  </Modal>
)




