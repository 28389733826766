import React from "react";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
/// For the CandidatosSearchTable component
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useRowSelect,
  usePagination,
} from "react-table";
import MaUTable from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import Card from "@material-ui/core/Card";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@mui/material/Tooltip";
import TableRow from "@material-ui/core/TableRow";
import { MenuItem, Select, Modal, TextField, Typography, Accordion, AccordionSummary, AccordionDetails } from "@material-ui/core";
import Input from "@material-ui/core/Input";
import { dateFormat } from "../../../../constants/AppConstants";
import { makeStyles } from "@material-ui/core/styles";
import { Redirect } from "react-router";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { getCandidatoPageNumeroDocumento } from "../../../../helpers/candidato/candidato";
import { Link } from "react-router-dom";
import { getPreempleado } from "../../../../helpers/preempleado/preempleadoHelper";
import { getContratoById } from "../../../../helpers/contratoHelper/contratoHelper";
import { FORMACION_URL } from "../../../../helpers/formacionUrl";
import { EXPERIENCIA_URL } from "../../../../helpers/experienciaUrl";
import {
    downloadDocumentsCandidato,
    getSignedContractFiles,
    getSignedContractFilesExtensions
} from "../../../../helpers/fileHelper/downloadHelper";
import { CONTRATO_FIRMADO_URL, CONTRATO_SIN_FIRMAR_URL, DOCUMENTOS_CONTRATO_URL } from "../../../../helpers/contratosUrl";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward'
import JSZip from "jszip";
import { saveAs } from 'file-saver'

const useStyles = makeStyles(theme => ({
  internalCard: {
    margin: 25,
    padding: 15,
  },
  margin: {
    margin: 15,
  },
  table: {
    minWidth: 700,
    boxShadow: theme.shadows[5],
  },
  cell: {
    maxWidth: 100,
    overflowWrap: "anywhere",
  },
  centralButton: {
    margin: 15,
  },
  hoverPointer: {
    cursor: "pointer",
    color: "#0000EE"
  },
  notDataText: {
    margin: 15,
  },
  formText: {
    margin: 15,
    paddingTop: 5,
  },
  inputPading: {
    padding: 5,
  },
  modal: {
    position: "absolute",
    width: "90%",
    height: "85%",
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 8, 6),
    overflow: "auto",
  },
  textFieldRoot: {
    width: "305px",
  },
  inputs: {
		display: 'flex',
		flexWrap: 'wrap'
	},
  filters: {
    maxWidth: '534px',
		display: 'flex',
		flexWrap: 'wrap',
    justifyContent: 'flex-end',
    '@media screen and (max-width:644px)' :{
      justifyContent: 'flex-start'
    }
	},
  marginButtons:{
    margin: '5px 15px'
  }
}));


const VerDetalleCandidato = (row, modalOpen) => {
  const classes = useStyles();
  return (
    <div>
      <Typography
        variant="inherit"
        className={classes.hoverPointer}
        onClick={() => {
          modalOpen(row.original);
        }}
      >
        Ver detalle
      </Typography>
    </div>
  );
};

const Actualizar = (row) => {
  return (
    <IconButton aria-label="Actualizar">
      <Tooltip title="Actualizar">
        <Link
          to={{
            pathname: "/postulacion/update",
            state: { props: row.original },
          }}
        >
          <EditIcon />
        </Link>
      </Tooltip>
    </IconButton>
  );
};

function getModalStyle() {
  const top = 50;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

function Table({props, onReloadTable, columns, data, initialState, dataQuery, editable = false,}) {
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const [candidatoModal, setCandidatoModal] = React.useState({
    nombre: "",
    fechaNacimiento: "",
    tipoDocumento: "",
    numeroDocumento: "",
    direccion: "",
    ciudad: "",
    rh: "",
    celular: "",
    correo: "",
    formaciones: [],
    experiencias: [],
    postulaciones: [],
    preempleados: [],
  });
  const [open, setOpen] = React.useState(false);
  const [modalStyle] = React.useState(getModalStyle);
  const [numeroDocumento, setNumeroDocumento] = React.useState("");

  const handleDownload = (filename) => {
    const MySwal = withReactContent(Swal);
    downloadDocumentsCandidato(candidatoModal.numeroDocumento, filename)
      .then((res) => res.data)
      .then((fileBits) => {
        var link = document.createElement("a");
        document.body.appendChild(link);
        link.setAttribute("type", "hidden");
        link.href = "data:text/plain;base64," + fileBits;
        link.download = filename;
        link.click();
        document.body.removeChild(link);
      })
      .catch((err) =>
        MySwal.fire("Error", "Ocurrió un error generando la consulta", "error")
      );
  };

  const handleSignedContractFilesZip = (idContrato) => {
    const MySwal = withReactContent(Swal);
    const zip = new JSZip();
    let variable = [];
      getSignedContractFilesExtensions(idContrato)
        .then(res => res.data)
        .then(file => {
          variable.push(file);
        })
      getSignedContractFiles(idContrato)
        .then(res => res.data)
        .then(fileBits => {
          if (fileBits.length === 0) {
            MySwal.fire('Error','No existen archivos para descargar','error')
          } else {
              var zipResult = zip.folder("DocsContratoFirmado");
              zipResult.file("CertificadoBancario" + variable.map(list => list[0]), fileBits[0], { base64: true })
              zipResult.file("AfiliacionEPS" + variable.map(list => list[1]), fileBits[1], { base64: true })
              zipResult.file("AfiliacionARL" + variable.map(list => list[2]), fileBits[2], { base64: true })
              zipResult.file("AfiliacionPension" + variable.map(list => list[3]), fileBits[3], { base64: true })
              zipResult.file("RUT" + variable.map(list => list[4]), fileBits[4], { base64: true })
              zipResult.file("DeclaracionJuramentada" + variable.map(list => list[5]), fileBits[5], { base64: true })
              zipResult.file("ContratoFirmado" + variable.map(list => list[6]), fileBits[6], { base64: true })

              zip.generateAsync({ type: "blob" })
                  .then(function (content) {
                      // see FileSaver.js
                      saveAs(content, "DocsContratoFirmado_" + idContrato + ".zip");
                  });
          }})

        .catch(err => MySwal.fire(
            'Error',
            'Ocurrió un error generando la consulta',
            'error'
        ))
  }

  const alertContratoSinFirmar = (postulaciones) => {
    
    if( postulaciones.length > 0 ) {
      postulaciones.forEach((item) => {
        if (item.estado === "CONTRATADO" && item.preempleado?.contrato?.contratoFirmado === null && item.preempleado?.contrato?.contratoSinFirmar !== null) { 
          MySwal.fire({
            icon: 'info',
            title: 'Contrato sin firmar',
            text: 'El candidato no ha firmado el contrato de la oferta ' + item.oferta.nombre
          })
        }
      })
    }
  }

  const asignarPreempleados = () => {
    for (const postulacion of candidatoModal.postulaciones) {
      postulacion.preempleado = candidatoModal.preempleados.find(preempleado => preempleado.postulacion.id === postulacion.id);
    }
  }
  
  const TablaCandidatoFormaciones = () => {
    return candidatoModal.formaciones.length > 0
      ? candidatoModal.formaciones.map((item) => {
        return (
          <TableRow key={item.id} className={classes.internalCard}>
            <TableCell className={classes.cell}>{item.tipo}</TableCell>
            <TableCell className={classes.cell}> {item.nombre}</TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {item.centroEducativo}
            </TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {dateFormat(item.fechaCulminacion)}
            </TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {item.tarjetaProfesional ? dateFormat(item.fechaExpedicionTarjetaProfesional) : "---"}
            </TableCell>
            <TableCell>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography className={classes.heading}>Descargar</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <div>
                      <Typography variant="h4" align="left" color="textPrimary">Diploma</Typography>
                        <Button variant="contained" size='small' color="secondary"
                          onClick={() => handleDownload(FORMACION_URL + item.diploma)}
                          className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                          {item.tarjeta === true ?
                            <div>
                              <Typography variant="h4" align="left" color="textPrimary">Tarjeta profesional</Typography>
                                <Button variant="contained" size='small' color="secondary"
                                  onClick={() => handleDownload(FORMACION_URL + item.tarjetaProfesional)}
                                  className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                              <Typography variant="h4" align="left" color="textPrimary">Certificado de vigencia</Typography>
                                  <Button variant="contained" size='small' color="secondary"
                                    onClick={() => handleDownload(FORMACION_URL + item.certificadoVigenciaTarjetaProfesional)}
                                    className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button>
                            </div>
                          : null}
                  </div>
                </AccordionDetails>
              </Accordion>
            </TableCell>
          </TableRow>
        );
      })
      : null;
  };

  const TablaCandidatoExperiencias = () => {
    return candidatoModal.experiencias.length > 0
      ? candidatoModal.experiencias.map((item) => {
        return (
          <TableRow key={item.id} className={classes.internalCard}>
            <TableCell className={classes.cell}> {item.empresa}</TableCell>
            <TableCell className={classes.cell}> {item.area}</TableCell>
            <TableCell className={classes.cell}> {item.cargo}</TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {item.descripcion}
            </TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {dateFormat(item.fechaInicio)}
            </TableCell>
            <TableCell className={classes.cell}>
              {" "}
              {dateFormat(item.fechaFinal)}
            </TableCell>
            <TableCell
              className={classes.hoverPointer}
              onClick={() => handleDownload(EXPERIENCIA_URL + item.certificadoLaboral)}
            >
              {" "}
              Descargar{" "}
            </TableCell>
          </TableRow>
        );
      })
      : null;
  };

  const TablaCandidatoPostulaciones = () => {
    asignarPreempleados();
    if (open) {
      alertContratoSinFirmar(candidatoModal.postulaciones);
    }
    return candidatoModal.postulaciones.length > 0
      ? candidatoModal.postulaciones.map((item) => {
        return (
          <TableRow key={item.id} className={classes.internalCard}>
            <TableCell className={classes.cell}> {dateFormat(item.fechaPostulacion)} </TableCell>
            <TableCell className={classes.cell}> {item.oferta.nombre} </TableCell>
            <TableCell className={classes.cell}> {item.oferta.descripcion.replace(/<(.|\n)*?>/g, ' ').trim()} </TableCell>
            <TableCell className={classes.cell}> {item.oferta.proyecto.nombre} </TableCell>
            <TableCell className={classes.cell}> {item.estado.slice(0,1).toUpperCase() + item.estado.substring(1).toLowerCase()} </TableCell>
            <TableCell className={classes.cell}>
                {item.estado === 'CONTRATADO' || item.estado === 'DESVINCULADO' ?
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>Descargar</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div>
                          {item.preempleado.contrato.contratoSinFirmar ?
                            <><Typography variant="h4" align="left" color="textPrimary">Contrato sin firmar</Typography>
                              <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleDownload(CONTRATO_SIN_FIRMAR_URL + item.preempleado.contrato.contratoSinFirmar)}
                                className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button></>
                            : null}
                          {item.preempleado.contrato.contratoFirmado ?
                            <><Typography variant="h4" align="left" color="textPrimary">Paquete Contrato Firmado</Typography>
                              <Button variant="contained" size='small' color="secondary"
                                onClick={() => handleSignedContractFilesZip(item.preempleado.contrato.id)}
                                className={classes.buttonDownload} endIcon={<ArrowDownwardIcon />}>Descargar</Button></>
                            : null}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                : "..."}
              </TableCell>
          </TableRow>
        );
      })
      : null;
  };
 const handleClose = () => {
    setOpen(false);
  };

  const modalOpen = (candidato) => {
    setCandidatoModal({
      ...candidatoModal,
      nombre: candidato.nombres + " " + candidato.apellidos,
      fechaNacimiento: candidato.fechaNacimiento,
      tipoDocumento: candidato.tipoDocumento,
      formaciones: candidato.formaciones,
      experiencias: candidato.experiencias,
      postulaciones : candidato.postulaciones,
      preempleados: candidato.preempleados,
      numeroDocumento: candidato.numeroDocumento,
      ciudad: candidato.ciudad,
      direccion: candidato.direccion,
      rh: candidato.rh,
      celular: candidato.celular,
      correo: candidato.correo,
    });
    setOpen(true);
  };
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setPageSize,
    state: { pageSize },
  } = useTable(
    {
      columns,
      data,
      initialState: { numeroDocumento, pageSize: 10 },
    },
    
    useFilters,
    useGlobalFilter, // useGlobalFilter!
    usePagination,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columnsHooked) => [
        // Let's make a column for selection
        ...columnsHooked,
        {
          id: "detail",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Typography variant="subtitle2"> Detalle </Typography>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => <div>{VerDetalleCandidato(row, modalOpen)}</div>,
        },
        {
          id: "update",
          // The header can use the table's getToggleAllRowsSelectedProps method
          // to render a checkbox
          Header: ({ getToggleAllPageRowsSelectedProps }) => (
            <div>
              <Typography variant="subtitle2"> Acciones </Typography>
            </div>
          ),
          // The cell can use the individual row's getToggleRowSelectedProps method
          // to the render a checkbox
          Cell: ({ row }) => <div>{Actualizar(row)}</div>,
        },
      ]);
    }
  );

  const buscarCandidatos = () =>{
    initialState({numeroDocumento})
  }

  return (
    <div>
      <div>
        <Modal open={open} onClose={handleClose} style={{zIndex: 100}}>
          <div style={modalStyle} className={classes.modal}>
            <Typography variant="h1" align="left" color="textPrimary">
              Detalle de {candidatoModal.nombre}
            </Typography>
            <Card className={classes.internalCard}>
              <Typography variant="h4" align="left" color="textPrimary">
                Fecha nacimiento
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {dateFormat(candidatoModal.fechaNacimiento)}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Tipo de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.tipoDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Número de documento:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.numeroDocumento}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Ciudad de residencia:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.ciudad.nombre}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Dirección:
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.direccion}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                RH
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.rh}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Celular
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.celular}
              </Typography>
              <Typography variant="h4" align="left" color="textPrimary">
                Correo
              </Typography>
              <Typography variant="body2" align="left" color="textPrimary">
                {candidatoModal.correo}
              </Typography>
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Formaciones
            </Typography>
            <Card className={classes.internalCard}>
              {candidatoModal.formaciones.length > 0 ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Tipo de Formación</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Nombre del Programa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Centro educativo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de culminación </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de expedición tarjeta profesional </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Acciones </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoFormaciones()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El candidato no tiene formaciones registradas
                </Typography>
              )}
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Experiencias
            </Typography>
            <Card className={classes.internalCard}>
              {candidatoModal.experiencias.length > 0 ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Empresa </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Área </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Cargo </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Descripción </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Inicio </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Fecha Final </strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong> Acciones </strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoExperiencias()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El candidato no tiene experiencias registradas
                </Typography>
              )}
            </Card>
            <Typography variant="h3" align="left" color="textPrimary">
              Postulaciones a ofertas
            </Typography>
            <Card className={classes.internalCard}>
              {candidatoModal.postulaciones.length > 0 ? (
                <div style={{ overflow: "auto" }}>
                  <MaUTable className={classes.table}>
                    <TableHead>
                      <TableRow>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Fecha de postulación</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Nombre de la oferta</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Descripción de la oferta</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Proyecto</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Estado</strong>
                        </TableCell>
                        <TableCell className={classes.cell}>
                          {" "}
                          <strong>Contratos</strong>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>{TablaCandidatoPostulaciones()}</TableBody>
                  </MaUTable>
                </div>
              ) : (
                <Typography variant="h4" align="left" color="textPrimary">
                  El candidato no se ha postulado a ninguna oferta.
                </Typography>
              )}
            </Card>
            <Grid item xs={12} align="center">
              <Button
                onClick={() => handleClose()}
                variant="contained"
                color="secondary"
              >
                {" "}
                Cerrar
              </Button>
            </Grid>
          </div>
        </Modal>
        <Typography variant="h1" fontSize={20} align="left" color="textPrimary">
          Consulta de candidatos
        </Typography>
        <Typography variant="subtitle1" align="left" color="textPrimary">
            En esta página usted podrá buscar por número de documento los candidatos para ver su información y editarlos.
        </Typography>
        <br></br>
        <div className={classes.inputs}>
            <Typography className={classes.formText} color="textPrimary">
              <label htmlFor="editarUsuario">
                Documento de identidad
              </label>
            </Typography>
            <TextField
              id="editarUsuario"
              variant="outlined"
              className={classes.textFieldRoot}
              value={numeroDocumento || ""}
              placeholder={' Inserta el número de documento'}
              onChange={(e) => {
                setNumeroDocumento(e.target.value);
              }}
            />
        </div>
        <div className={classes.filters}>
            <Button className={classes.marginButtons} size="small" variant="text"
            onClick={() => {
                initialState({});
                setNumeroDocumento("");
            }} > Limpiar
            </Button>
          <Button className={classes.marginButtons} onClick={() => buscarCandidatos()} variant="contained" color="primary">
              Buscar
          </Button>
        </div>
        {page.length === 0 ? "No hay candidatos para editar" :
        <div>
          <div style={{ overflow: "auto", margin: "20px 0" }}>
          <MaUTable className={classes.table} {...getTableProps()}>
            <TableHead>
              {headerGroups.map((headerGroup) => (
                <TableRow {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <TableCell
                      className={classes.cell}
                      {...column.getHeaderProps()}
                    >
                      <strong>{column.render("Header")}</strong>
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody {...getTableBodyProps()}>
              {page.map((row, i) => {
                prepareRow(row);
                return (
                  <TableRow {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <TableCell
                          className={classes.cell}
                          {...cell.getCellProps()}
                        >
                          {cell.render("Cell")}
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })}
            </TableBody>
          </MaUTable>
          </div>
          <div className="pagination">
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: 0 });
            }}
            disabled={dataQuery.first}
          >
            {"<<"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.number - 1 });
            }}
            disabled={dataQuery.first}
          >
            {"<"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.number + 1 });
            }}
            disabled={dataQuery.last}
          >
            {">"}
          </Button>{" "}
          <Button
            onClick={() => {
              initialState({ numeroDocumento, page: dataQuery.totalPages - 1 });
            }}
            disabled={dataQuery.last}
          >
            {">>"}
          </Button>{" "}
          <span>
            Página{" "}
            <strong>
              {dataQuery.number + 1} de {dataQuery.totalPages}
            </strong>{" "}
          </span>
          <span>
            | Ir a la página:{" "}
            <Input
              type="number"
              defaultValue={dataQuery.number + 1}
              onChange={(e) => {
                if (e.target.value < dataQuery.totalPages) {
                  initialState({ numeroDocumento, page: Number(e.target.value) });
                }
              }}
              style={{ width: "100px" }}
            />
          </span>{" "}
          <Select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
              initialState({
                numeroDocumento: numeroDocumento,
                page: dataQuery.number,
                pageSize: Number(e.target.value),
              });
            }}
          >
            {[10, 20, 30, 40, 50].map((pageSizeMaper) => (
              <MenuItem key={pageSizeMaper} value={pageSizeMaper}>
                Mostrar {pageSizeMaper}
              </MenuItem>
            ))}
          </Select>
        </div>
        </div>}
      </div>
    </div>
  );
}

const CandidatosTable = (props, candidatos, dataQuery, initialState) => {
  const classes = useStyles();
  const desvinculado = "DESVINCULADO";
  const contratado = "CONTRATADO";
  const rechazado = "RECHAZADO";
  const postulado = "POSTULADO";
  const documentosConfirmados = "DOCUMENTOS CONFIRMADOS";
  const enContratacion = "PRESELECCIONADO";
  const columns = [
    {
      Header: "Número de documento",
      accessor: "numeroDocumento",
    },
    {
      Header: "Nombre",
      accessor: "nombreCompleto",
    },
    {
      Header: "Número de contrato",
      accessor: "numeroContrato",
    },
    {
      Header: "Perfil",
      accessor: "perfil",
    },
    {
      Header: "Estado",
      accessor: "estado",
    },
  ];
  if (candidatos !== undefined) {
    if (candidatos.length > 0) {
      for (const i in candidatos) {
        candidatos[i].nombreCompleto =
          candidatos[i].nombres + " " + candidatos[i].apellidos;
        var formacion = {
          tarjetaProfesional: "No",
          posgrado: "xxx",
          expedicionTarjeta: "",
        };
        if(candidatos[i].postulaciones.length){
          for ( const j in candidatos[i].postulaciones){
            /*candidatos[i].postulaciones[j].estado =
              candidatos[i].postulaciones[j].estado.substring(0,1).toUpperCase() + 
              candidatos[i].postulaciones[j].estado.substring(1).toLowerCase();*/

            if (candidatos[i].postulaciones[j].estado === desvinculado){
              candidatos[i].desvinculado = true;
            }
            if (candidatos[i].postulaciones[j].estado === contratado){
              candidatos[i].contratado = true;
            }
            if (candidatos[i].postulaciones[j].estado === rechazado){
                candidatos[i].rechazado = true;
            }
            if (candidatos[i].postulaciones[j].estado === postulado){
              candidatos[i].postulado = true;
            }
            if (candidatos[i].postulaciones[j].estado === documentosConfirmados){
              candidatos[i].documentosConfirmados = true;
            }
            if (candidatos[i].postulaciones[j].estado === enContratacion){
              candidatos[i].enContratacion = true;
            }
          }
        }
        if (candidatos[i].desvinculado !== undefined) {
          candidatos[i].estado = desvinculado;
        }else if (candidatos[i].contratado !== undefined) {
            candidatos[i].estado = contratado;
        } else if (candidatos[i].enContratacion !== undefined) {
          candidatos[i].estado = enContratacion;
        } else if (candidatos[i].documentosConfirmados !== undefined) {
            candidatos[i].estado = documentosConfirmados;
        } else if (candidatos[i].postulado !== undefined) {
          candidatos[i].estado = postulado;
        } else if (candidatos[i].rechazado !== undefined) {
            candidatos[i].estado = rechazado;
        }

        if (candidatos[i].formaciones.length > 0) {
          for (const j in candidatos[i].formaciones) {
            if (
              candidatos[i].formaciones[j].tipo === "Bachiller" &&
              formacion.formacionInicial !== "Pregrado" &&
              formacion.formacionInicial !== "Tecnico" &&
              formacion.formacionInicial !== "Tecnologo"
            )
              formacion.formacionInicial = candidatos[i].formaciones[j].tipo;
            if (
              candidatos[i].formaciones[j].tipo === "Tecnico" &&
              formacion.formacionInicial !== "Pregrado" &&
              formacion.formacionInicial !== "Tecnologo"
            )
              formacion.formacionInicial = candidatos[i].formaciones[j].tipo;
            if (
              candidatos[i].formaciones[j].tipo === "Tecnologo" &&
              formacion.formacionInicial !== "Pregrado"
            )
              formacion.formacionInicial = candidatos[i].formaciones[j].tipo;
            if (candidatos[i].formaciones[j].tipo === "Pregrado")
              formacion.formacionInicial = candidatos[i].formaciones[j].tipo;
            if (
              candidatos[i].formaciones[j].tipo === "Especializacion" &&
              formacion.posgrado !== "Maestria" &&
              formacion.posgrado !== "Doctorado" &&
              formacion.posgrado !== "Posdoctorado"
            )
              formacion.posgrado = candidatos[i].formaciones[j].tipo;
            if (
              candidatos[i].formaciones[j].tipo === "Maestria" &&
              formacion.posgrado !== "Doctorado" &&
              formacion.posgrado !== "Posdoctorado"
            )
              formacion.posgrado = candidatos[i].formaciones[j].tipo;
            if (
              candidatos[i].formaciones[j].tipo === "Doctorado" &&
              formacion.posgrado !== "Posdoctorado"
            )
              formacion.posgrado = candidatos[i].formaciones[j].tipo;
            if (candidatos[i].formaciones[j].tipo === "Posdoctorado")
              formacion.posgrado = candidatos.formaciones[j].tipo;
            if (candidatos[i].formaciones[j].tarjeta)
              formacion.tarjetaProfesional = "Si";
            if (
              candidatos[i].formaciones[j].fechaExpedicionTarjetaProfesional !==
              null
            )
              formacion.expedicionTarjeta =
                candidatos[i].formaciones[j].fechaExpedicionTarjetaProfesional;
          }
        }
        candidatos[i].formacionInicial = formacion.formacionInicial;
        candidatos[i].posgrado = formacion.posgrado;
        candidatos[i].tarjetaProfesional = formacion.tarjetaProfesional;
        candidatos[i].expedicionTarjeta = formacion.expedicionTarjeta;
      }
    }
  }

  return (
    <div>
      <Card className={classes.internalCard}>
        {candidatos ? (
          <Table
            props={props}
            columns={columns}
            data={candidatos}
            dataQuery={dataQuery}
            initialState={initialState}
          />
        ) : (
          <Typography variant="h2" color="textPrimary">
            {" "}
            No se encontraron candidatos{" "}
          </Typography>
        )}
      </Card>
    </div>
  );
};

const useSingleton = (
  callBack = () => {
    //Empty callback
  }
) => {
  const hasBeenCalled = React.useRef(false);
  if (hasBeenCalled.current) return;
  callBack();
  hasBeenCalled.current = true;
};

const TablaCandidatos = (props) => {
  const [candidatos, setCandidatos] = React.useState({ content: [] });
  const [redirect, setRedirect] = React.useState(false);
  const initialState = async ({ numeroDocumento="" ,page = 0, pageSize = 10 }) => {
    const candidatoQuery = await getCandidatoPageNumeroDocumento({
      numeroDocumento: numeroDocumento,
      page: page,
      pageSize: pageSize,
    })
      .then((response) => {
        return response.data
      })
      .catch((err) => setRedirect(true));
      if (candidatoQuery!==undefined && candidatoQuery.content.length > 0) {
        for (let i in candidatoQuery.content) {
          if (candidatoQuery.content[i].preempleados.length) {
            for (let j in candidatoQuery.content[i].preempleados) {
                candidatoQuery.content[i].numeroContrato = await getPreempleado(
                  candidatoQuery.content[i].preempleados[j].id
                )
                  .then((res) => {
                    candidatoQuery.content[i].idContrato=res.data.contrato.id
                    return res.data.contrato.numeroContrato
                  })
                  .catch((err) => console.log(err));
              if (candidatoQuery.content[i].numeroContrato) {
                candidatoQuery.content[i].perfil = await getContratoById(
                  candidatoQuery.content[i].idContrato
                )
                  .then((res) => {
                    return res.data.perfil.nombre
                  })
                  .catch((err) => console.log(err));
              }
            }
          }
        }
      }
    setCandidatos(candidatoQuery);
  };
  useSingleton(async () => {
    await initialState({});
  });
  return (
    <div>
      {redirect ? (
        <Redirect
          push
          to={{ pathname: "/signin", state: { goBack: "/consulta_candidatos" } }}
        />
      ) : null}
      {CandidatosTable(props, candidatos.content, candidatos, initialState)}
    </div>
  );
};

export default TablaCandidatos;
