import React, { useState } from "react";
import { makeStyles, lighten } from '@material-ui/core/styles';
import { alpha, Button, Card, CardContent, Grid, Typography, MenuItem } from '@material-ui/core';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import TypeText from '../components/Campos/TypeText';
import TypeForm from '../components/Campos/TypeForm';
import { LATEST_DATE, IS_NUMERIC, IS_VALID_EMAIL, IS_NOT_EMPTY } from '../constants/MensajeHelper';
import moment from "moment";

const useStyles = makeStyles(theme => ({
  root: {
    maxWidth: "100%",
    minWidth: "100%",
    margin: '0 auto',
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  title:{
    marginLeft: theme.spacing(3),
    display: 'flex',
    alignItems: 'center',
    marginBottom: '30px',
    marginTop: '20px',
  },
  subtitle: {
    marginLeft: theme.spacing(3),
    marginBottom: '5px',
    marginTop: '20px'
  },
  subtitleObligatorio: {
    marginLeft: theme.spacing(3),
    marginBottom: '40px',
    marginTop: '5px'
  },
  typography: {
    paddingLeft: theme.spacing(8),
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '20px',
    marginTop: '30px',
  },
  textFieldRoot: {
		'& .MuiOutlinedInput-notchedOutline': {
			borderColor: alpha(theme.palette.common.dark, 0.12),
		},
	},
  alerta: {
    width: '100%',
    padding: 40,
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

const mensajeHelper = {           //Mensajes que mostrar en el HelperText de cada espacio, predeterminados en /components/Campos
  texto:{
    presence: IS_NOT_EMPTY,                                                             
    length: { maximum: 50, message: "no puede sobrepasar los 50 caracteres" },      
  },
  fecha:{
    presence: IS_NOT_EMPTY,
    datetime: LATEST_DATE,
  },
  numero:{
    presence: IS_NOT_EMPTY,
    numericality: IS_NUMERIC,                                                      
  },
  correo:{
    presence: IS_NOT_EMPTY,
    email: IS_VALID_EMAIL,                                                                    
  },
  archivo:{
    presence: IS_NOT_EMPTY,
  },
};

export default function Formularios() {
  
  const classes = useStyles();
  const MySwal = withReactContent(Swal);
  const fechaActual = moment().subtract(1, "days").format("YYYY-MM-DD");

  const [formState, setFormState] = useState({                                      
    isValid: false,   
    values: {         
      texto: '',
      select: '',
      fecha: '',
      numero: '',
      correo: '',
      archivo: '',
    },
    touched: {       
      texto: false,
      select: false,
      fecha: false,
      numero: false,
      correo: false,
      archivo: false,
    },
    errors: {        
      texto: false,
      select: false,
      fecha: false,
      numero: false,
      correo: false,
      archivo: false,
    },
  });

  const opcionesForm = [
    { value: "OpcionUno", text: "Opción 1" },
    { value: "OpcionDos", text: "Opción 2" },
    { value: "OpcionTres", text: "Opción 3" },
    { value: "OpcionCuatro", text: "Opción 4" },
    { value: "OpcionCinco", text: "Opción 5" },
  ];

  const onSubmit = async (e) => {
    e.preventDefault()
    //COMPLETAR EL ONSUBMIT)

    MySwal.fire({
      icon: 'success',
      title: 'Uso correcto del formulario'
    });
  };

  return(
    <Card className={classes.root}>
      <CardContent>
        <form noValidate autoComplete="off" onSubmit={onSubmit}>
          <Typography variant='h1'>Estándar de formularios</Typography>
          <Typography variant='h6' className={classes.subtitle}>Descripción del formulario</Typography>
          <Typography variant='body2' className={classes.subtitleObligatorio}>Los campos con * son obligatorios</Typography>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Textos*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText fullWidth id="texto" formState={formState} setFormState={setFormState} helperText={mensajeHelper} />
              {/*
                Se le pasa un id con el nombre de la variable, el formState y setFormState para hacer los respectivos cambios y el mensajeHelper para poner los textos de error
              */}
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Select*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeForm fullWidth id="select" formState={formState} setFormState={setFormState} helperText={mensajeHelper}>
                <MenuItem value=""/>
                {opcionesForm.map(({value, text}) => {
                  return (<MenuItem key={value} value={value}>{text}</MenuItem>)
                })}
              </TypeForm>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Fecha*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText fullWidth id="fecha" formState={formState} setFormState={setFormState} helperText={mensajeHelper} type="date" inputProps={{max: fechaActual}} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Número*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText fullWidth id="numero" formState={formState} setFormState={setFormState} helperText={mensajeHelper} inputProps={{maxLength: 10}}/>
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Correo*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText fullWidth id="correo" formState={formState} setFormState={setFormState} helperText={mensajeHelper} />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3}>
            <Grid item xs={5} className={classes.left}>
              <Typography variant='body1' className={classes.typography}>Archivo*</Typography>
            </Grid>
            <Grid item xs={7}>
              <TypeText 
                fullWidth
                id="archivo"
                formState={formState}
                setFormState={setFormState}
                helperText={mensajeHelper}
                type="file"
                InputLabelProps={{shrink: true}}
                inputProps={{ accept: "image/jpeg,application/pdf" }}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12} spacing={3} className={classes.button}>
            <Button 
              variant="contained"
              color="primary"
              type="submit"
              disabled={!formState.isValid}
            >
              Crear Candidato
            </Button>
          </Grid>
        </form>
      </CardContent>
    </Card>
  );
}
