import axios from 'axios'
import { BASE_URL } from '../baseUrl'
import { getToken } from '../../services/auth/jwt/getToken'
const token = getToken()
const contentType = 'application/json'
export const postPostulacion = (data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'postulacions',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    data: JSON.stringify(data)
  }
  return axios(config)
}

export const getPostulacionesCandidatos= async ({ idOferta, page = 0, pageSize = 10 }) => {
  const config = {
    method: 'get',
    url: BASE_URL + `postulaciones_candidatos`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    params: {
      id: idOferta,
      pageNumber: page,
      pageSize: pageSize,
    }
  }

  return await axios(config)
}

export const getPostulacionesConDocumentosActualizados = async ({ idOferta, page = 0, pageSize = 10, formaciones, ubicaciones, anios}) => {
  let formacion="";
  if(formaciones.length){
      formaciones.forEach((element,index) => {
          if(index === formaciones.length-1){
              formacion+=element
          }else{
              formacion+=element+","
          }
      });
  }
  let ubicacion="";
  if(ubicaciones.length){
      ubicaciones.forEach((element,index) => {
          ubicacion+=element+","
          if(index === ubicaciones.length-1){
              ubicacion+=element
          }
      });
  }
  let url = 'postulaciones_documentos_confirmados?pageSize='+pageSize+'&pageNumber='+page+'&anios='+anios+'&formaciones='+encodeURIComponent(formacion)+'&ubicaciones='+ubicacion;
  const config = {
    method: 'get',
    url: BASE_URL + url,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    params: {
      id: idOferta,
      pageNumber: page,
      pageSize: pageSize,
    }
  }

  return await axios(config)
}
export const getPostulacionesPreempleadoContratado = async ({ idOferta, page = 0, pageSize = 10 }) => {
  const config = {
    method: 'get',
    url: BASE_URL + `postulaciones_preempleado_contratado`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    params: {
      id: idOferta,
      pageNumber: page,
      pageSize: pageSize,
    }
  }

  return await axios(config)
}


export const getPostulacionesConPreselecion = async ({  idOferta, page = 0, pageSize = 10 }) => {
  const config = {
    method: 'get',
    url: BASE_URL + `postulaciones_preempleado_preseleccionados`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    params: {
      id: idOferta,
      pageNumber: page,
      pageSize: pageSize,
    }
  }

  return await axios(config)
}
export const rechazarCandidato = async (data) => {
  const config = {
    method: 'post',
    url: BASE_URL + `rechazar_candidatos`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    data
  }

  return await axios(config)
}

export const rechazarContratado = async (data) => {
  const config = {
    method: 'post',
    url: BASE_URL + `rechazar_preempleado`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true,
    data
  }

  return await axios(config)
}
