export const tiposContrato = [
    {
        tipo:"TERMINO_FIJO",
        placeholder:"Término fijo"
    },
    {
        tipo:"TERMINO_INDEFINIDO",
        placeholder:"Término indefinido"
    },
    {
        tipo:"PRESTACION_DE_SERVICIOS",
        placeholder:"Prestación de servicios"
    },
    {
        tipo:"OBRA_LABOR",
        placeholder:"Obra labor"
    }

]
