import axios from 'axios'
import { BASE_URL } from '../baseUrl'
import { getToken } from '../../services/auth/jwt/getToken'
const token = getToken()

const contentType = "application/json"

export const confirmarPreseleccion = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'preempleados',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const aprobarPreseleccion = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'aprobar_preempleados',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const getPreempleado = async (id) => {
  const config = {
    method: 'get',
    url: BASE_URL + 'preempleados/' + id,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials: true
  }

  return await axios(config)
}

export const rechazarPreseleccion = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'rechazar_preempleados',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const getPreempleadosContratacion = async (token, idOferta) => {
  const config = {
    method: 'get',
    url: BASE_URL + `preempleados_contratacion`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    params: {
      id: idOferta
    },
    withCredentials: true
  }

  return await axios(config)
}

export const rechazarContratacion = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'rechazar_preempleado',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const aprobarContratado = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'aprobar_contratado',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const validarContratado = async (token, data) => {
  const config = {
    method: 'post',
    url: BASE_URL + 'validar_contratado',
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}

export const patchContratado = async(id, data) => {
  const config = {
    method: 'patch',
    url: BASE_URL+'preempleados/'+id,
    headers: {
      'Authorization': token,
      'Content-Type': 'application/merge-patch+json',
    },
    data,
    withCredentials: true,
  }

  return await axios(config)
}


export const getPreempleadoByIdContrato = (data) => {
  const config = {
    method: 'POST',
    url: BASE_URL + 'preempleado_contrato',
    headers: {
      'Authorization': token,
      'Conten-Type': contentType,
    },
    data: data,
    withCredentials: true,
  }
  return axios(config)
}

export const getPreempleadoByNumeroDocumento = ({ numeroDocumento }) => {
  const config = {
    method: 'GET',
    url: BASE_URL + 'preempleado_numero_documento',
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
    params: {
      numeroDocumento: numeroDocumento,
    }
  }
  return axios(config)
}

export const getAllPreempleadoPage = async ({ page = 0, pageSize = 10 }) => {
  const config = {
    method: 'GET',
    url: BASE_URL + 'preempleados',
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
    params: {
      pageNumber: page,
      pageSize: pageSize
    }
  }
  return axios(config)
}

export const getPreempleados = async (pageSize, pageNumber) =>{
  const config = {
      method: 'get',
      url: BASE_URL+`preempleados`,
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      params:{
        pageSize,
        pageNumber
      },
      withCredentials:true
  }

  return await axios(config)
}

export const getPreempleadosContratados = async () =>{
  const config = {
      method: 'get',
      url: BASE_URL+`preempleados_contratados`,
      headers: {
        'Authorization': token,
        'Content-Type': contentType
      },
      withCredentials:true
  }

  return await axios(config)
}
export const getAllPreempleados = () => {
  const config = {
    method: 'GET',
    url: BASE_URL + 'preempleados-all',
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
  }
  return axios(config)
}

export const getPreempleadoContratado = async (id) => {
  const config = {
    method: 'get',
    url: BASE_URL+`preempleado-candidatoid-estado/${id}`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType
    },
    withCredentials:true
  }
  return await axios(config)
}


export const desvincularCandidato = async (id) => {
  const config = {
    method: 'post',
    url: BASE_URL + `desvincular-contratado/${id}`,
    headers: {
      'Authorization': token,
      'Content-Type': contentType,
    },
    withCredentials: true,
  };
  return await axios(config);
};