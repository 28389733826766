import React from 'react';
import Card from '@material-ui/core/Card';
import Alert from '@material-ui/lab/Alert';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { Redirect } from 'react-router';
import MaterialTable from '@material-table/core';
import { Typography, Grid, Button, MenuItem, Select, Input, Box } from '@material-ui/core';
import { spanishTextTable } from '../../../../constants/AppConstants'
import { getPostulacionesCandidatos } from '../../../../helpers/postulacion/postulacionHelper';
import ReactQuill from 'react-quill';

const useStyles = makeStyles({
	internalCard: {
		margin: 15,
		padding: 15,
	},

	textCard: {
		marginBottom: 10,
	},
	table: {
		minWidth: 700,
	},
	cell: {
		maxWidth: 100,
		overflowX: 'auto',
	},
	pagination: {
		marginTop: 15,
	},
});


const useSingleton = (callBack = () => {
	//Empty function no need to call the function more than once
}) => {
	const hasBeenCalled = React.useRef(false);
	if (hasBeenCalled.current) return;
	callBack();
	hasBeenCalled.current = true;
}

const Candidatos = ({ props }) => {
	const [alert, setAlert] = React.useState([])
	const [alertError, setAlertError] = React.useState([])
	const [redirect, setRedirect] = React.useState(false)
	const [candidatos, setCandidatos] = React.useState([])
	const [dataQuery, setDataQeury] = React.useState({ content: [], size: 10, number: 0 })
	const [pageNumer, setPageNumber] = React.useState(dataQuery.number + 1)
	const classes = useStyles();
	const initial_state = async ({ page = 0, pageSize = 10 }) => {
		await getPostulacionesCandidatos({idOferta: props.location.state.oferta.id,
			page:page, pageSize: pageSize})
			.then(response => response.data)
			.then(data => {
				let candidatosAux = []
				data.content.map(postulacion => {
					let candidatoEstado = postulacion.candidato
					candidatoEstado.estado = postulacion.estado
					candidatosAux.push(candidatoEstado)
					return null
				})
				setCandidatos(candidatosAux)
				setDataQeury(data)
			})
			.catch(err => setRedirect(false));
	};
	useSingleton(async () => {
		await initial_state({})
	})
	const columns = [
		{ title: "Cédula", field: "numeroDocumento" },
		{
			title: "Nombre", field: "nombres", render: rowData => {
				return (
					`${rowData.nombres} ${rowData.apellidos}`
				)
			}
		},
		{ title: "Género", field: "genero" },
		{ title: "Ciudad", field: 'ciudad.nombre' },
		{ title: "Estado", field: 'estado' },
	]
	return (
		<div>
			{alert.length > 0 ? alert.map((alerta, index) => {
				return (
					<Alert severity="success"
						onClose={(e) => {
							let alertas = alert;
							alertas.splice(index, 1)
							setAlert(alertas)
						}}>
						{alerta}
					</Alert>
				)
			}) : null}
			{redirect ? <Redirect push to={{ pathname: '/signin', state: { goBack: '/tabla_oferta' } }} /> : null}
			{alertError.length > 0 ? alertError.map((alerta, index) => {
				return (
					<Alert severity="error"
						onClose={(e) => {
							let alertas = alertError;
							alertas.splice(index, 1)
							setAlertError(alertas)
						}}>
						{alerta}
					</Alert>
				)
			}) : null}
			{candidatos.length > 0 ?
				<div>

					<MaterialTable
						title=""
						data={candidatos}
						columns={columns}
						isLoading={!candidatos.length > 0}
						options={{ 
							paging: false,
							headerStyle:{
								fontWeight: 'bold',
							}
						
						}}
						localization={spanishTextTable}
					/>
					<div className={classes.pagination}>
						<Button onClick={() => { initial_state({ page: 0 }) }} disabled={dataQuery.first}>
							{'<<'}
						</Button>{' '}
						<Button onClick={() => { initial_state({ page: dataQuery.number - 1 }) }} disabled={dataQuery.first}>
							{'<'}
						</Button>{' '}
						<Button onClick={() => { initial_state({ page: dataQuery.number + 1 }) }} disabled={dataQuery.last}>
							{'>'}
						</Button>{' '}
						<Button onClick={() => { initial_state({ page: dataQuery.totalPages - 1 }) }} disabled={dataQuery.last}>
							{'>>'}
						</Button>{' '}
						<span>
							Página{' '}
							<strong>
								{dataQuery.number + 1} de {dataQuery.totalPages}
							</strong>{' '}
						</span>
						<span>
							| Ir a la página:{' '}
							<Input
								type="number"
								value={pageNumer}
								onChange={e => {
									if (e.target.value < dataQuery.totalPages && e.target.value > 0) {
										setPageNumber(e.target.value)
										initial_state({ page: Number(e.target.value) })
									}
								}}
								style={{ width: '100px' }}
								inputProps={{ min: 0 }}
								error={pageNumer < 0}

							/>
						</span>{' '}
						<Select
							value={dataQuery.size}
							onChange={e => {
								initial_state({ page: dataQuery.number, pageSize: Number(e.target.value) })
							}}
						>
							{[10, 20, 30, 40, 50].map(pageSizeMaper => (
								<MenuItem key={pageSizeMaper} value={pageSizeMaper}>
									Mostrar {pageSizeMaper}
								</MenuItem>
							))}
						</Select>
					</div>
				</div>
				: <Typography variant="h2" color="textPrimary"> No hay candidatos vinculados </Typography>}
		</div>

	)

}

const VerDetalleOferta = (props) => {
	const classes = useStyles();
	if (props.location.state === undefined) window.location.href = '/signin'
	return (
		<div>
			<Breadcrumbs className={classes.margin} aria-label="breadcrumb">
				<Link color="inherit" to="/tabla_oferta">
					Ofertas
				</Link>
				<Link color="inherit" to={{ pathname: "/detalle_oferta", state: props.location.state }}>
					{props.location.state.oferta.nombre}
				</Link>
			</Breadcrumbs>

			<Card className={classes.internalCard}>
					<Box align="end" justifyContent={{ sm: 'end' }} mb={3}>
						<Link to={{ pathname: '/editar_oferta', state: { oferta: props.location.state.oferta }}}>
							<Button className={classes.title} 
							variant="contained" color="primary">
							Editar oferta</Button>
						</Link>
					</Box>
				<Typography style={{ overflowWrap: 'anywhere' }} className={classes.textCard} variant="h1" color="textPrimary">
					{props.location.state.oferta.nombre}
				</Typography>
				<Grid className={classes.textCard} item xs={12}>
					<Typography style={{ overflowWrap: 'anywhere' }} variant="h5" color="textPrimary">
						Descripción:
					</Typography>
					<ReactQuill
						className='readOnly'
						defaultValue={props.location.state.oferta.descripcionFormato}
						readOnly={true}
						theme={"bubble"}
					/>
				</Grid>
				<Grid className={classes.textCard} item xs={12}>
					<Typography variant="h5" color="textPrimary">
						Proyecto: {props.location.state.oferta.proyecto}
					</Typography>
				</Grid>
				<Grid className={classes.textCard} item xs={12}>
					<Typography style={{ overflowWrap: 'anywhere' }} variant="h5" color="textPrimary">
						Ciudad: {props.location.state.oferta.ciudad}
					</Typography>
				</Grid>
				<Grid className={classes.textCard} item xs={12}>
					<Typography style={{ overflowWrap: 'anywhere' }} variant="h5" color="textPrimary">
						Fecha de cierre: {props.location.state.oferta.fechaCierre}
					</Typography>
				</Grid>
				<Candidatos {...props} props={props} />
			</Card>
		</div>
	);
};

export default VerDetalleOferta;


