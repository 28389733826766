import { Button } from "@material-ui/core";
import React from "react";
import Swal from "sweetalert2";
import { desvincularCandidato } from "../../../../helpers/preempleado/preempleadoHelper";

export default function BotonDesvincular(idContratado){
    const desvincular = (id) => {
        Swal.fire({
            title: "Esta seguro de desvincular al contratado?",
            text: "Esta acción no se puede revertir",
            icon: "warning",
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            reverseButtons: true,
        })
        .then(result => {
            if(result.isConfirmed){
                desvincularCandidato(id)
            .then(res => {
                Swal.fire('El candidato se ha desvinculado', '', 'success')
                .then(
                    response => {
                        if(response.isConfirmed){
                            window.location.reload();
                        }
                    }
                )
            })
            .catch( e => console.log(e))}
        })
    }

    return (
        <Button sx={{ flexGrow: 1 }} variant="outlined" color="primary" size="small" onClick={() => desvincular(idContratado.id)}>
            Desvincular
        </Button>
    )
};
