
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Box, FormControl, MenuItem,  Typography,Select } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {getContratantes } from '../../helpers/contratante/contratanteHelper'
import { ofertaByProyecto, proyectosContratante } from '../../helpers/filtros/filtroOfertaHelper';
import { Redirect } from 'react-router';

const useStyles = makeStyles(theme => ({
    internalCard: {
        margin: 15,
        padding: 15,
    },
    centralButton: {
        margin: 15,
    },
    hoverPointer: {
        cursor: "pointer",
    },
    notDataText: {
        margin: 15,
    },
    table: {
        minWidth: 700,
    },
    cell: {
        maxWidth: 100,
        overflowX: 'auto',
    },
    formText: {
        margin: 15,
        paddingTop: 5,
        paddingLeft: theme.spacing(8),
        width: "70%",
        height: "15%",
    },
    direction:{
        flexDirection: "column",
    },
    inputPading: {
        padding: 3,
        width: "190%",
        paddingLeft: "42px",
    },
    paper: {
        position: 'absolute',
        width: '60%',
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(4, 8, 6),
    },
    color: {
        color: 'red'
    }
}));

const useSingleton = (callBack = () => {
    //Blank function no need to do anything
}) => {
    const hasBeenCalled = React.useRef(false);
    if (hasBeenCalled.current) return;
    callBack();
    hasBeenCalled.current = true;
}

const CustomContratante = ({ titulo, value, classes, handleChange, list }) => {
    return (
        <Box mb={2}>
            <Grid container item xs={12} spacing={2}>
                <Typography className={classes.formText}>{titulo}<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                    <Select value={value} onChange={handleChange} name='contratante'>
                        <MenuItem value="" />
                        {list.length > 0 ?
                            list.map((contratanteMapper) => {
                                return (
                                    <MenuItem key={contratanteMapper.id} value={contratanteMapper.id}>
                                        {contratanteMapper.nombre} </MenuItem>)
                            })
                            : <Typography value="" className={classes.formText} variant="body1" color="textPrimary">
                                No se encontraron entidades
                            </Typography>}
                    </Select>
                </FormControl>
            </Grid>
        </Box>
    )
}

const CustomProyecto = ({ titulo, value, classes, handleChange, list }) => {
    return (
        <Box mb={2}>
            <Grid item xs={4}>
                <Typography className={classes.formText} >{titulo}<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                    <Select value={value} onChange={handleChange} name='proyecto'>
                        <MenuItem value="" />
                        {list.length > 0 ?
                            list.map((proyectoMapper) => {
                                return (
                                    <MenuItem key={proyectoMapper.id} value={proyectoMapper.id}>
                                        {proyectoMapper.nombre} </MenuItem>)
                            })
                            : <Typography value="" className={classes.formText} variant="body1" color="textPrimary">
                                Selecciona una entidad para ver los proyectos disponibles
                            </Typography>}
                    </Select>
                </FormControl>
            </Grid>
        </Box>
    )
}

const CustomOferta = ({ titulo, classes, handleChange, list }) => {
    return (
        <Box mb={2}>
            <Grid item xs={4}>
                <Typography className={classes.formText}>{titulo}<span className={classes.color}>*</span></Typography>
            </Grid>
            <Grid item xs={6}>
                <FormControl fullWidth variant="outlined" className={classes.inputPading}>
                    <Select defaultValue="" onChange={handleChange} name='oferta'>
                        <MenuItem value="" />
                        {list.length > 0 ?
                            list.map((ofertaMapper) => {
                                return (
                                    <MenuItem key={ofertaMapper.id} value={ofertaMapper}>
                                        {ofertaMapper.nombre} </MenuItem>)
                            })
                            : <Typography value="" className={classes.formText} variant="body1" color="textPrimary">
                                Selecciona un proyecto para visualizar las ofertas disponibles
                            </Typography>}
                    </Select>
                </FormControl>
            </Grid>
        </Box>
    )
}

export const FiltroOferta = ({ setOferta, setAlertModal=(()=>{return null}), setMostrarTabla=(()=>{return null}) , setTexto}) => {
    const classes = useStyles();
    const [ofertas, setOfertas] = React.useState([])
    const [contratantes, setContratantes] = React.useState([])
    const [contratante, setContratante] = React.useState('')
    const [proyectos, setProyectos] = React.useState([])
    const [proyecto, setProyecto] = React.useState('')
    const [redirect, setRedirect] = React.useState(false)
    const initialState = async () => {
        await getContratantes()
        .then(result => result.data)
        .then( data => setContratantes(data))
        .catch(()=>setRedirect(true)
        )
    }

    useSingleton(async () => {
        await initialState()
    });
    const handleChange = async (e) => { // contratante, proyecto, perfil, oferta
        if (e.target.name === 'contratante') {
            if(e.target.value !== ""){
                await proyectosContratante(e.target.value)
                .then(async response => {
                    setProyectos(response.data)
                })
                }else{
                    await setProyectos([]);
                }
                setMostrarTabla(false)
                setOfertas([]);
                setOferta('')
                setProyecto('')
                setContratante(e.target.value)
                if(setTexto){
                    setTexto(prev => ({...prev, entidad: contratantes.filter(c=>c.id===e.target.value)[0].nombre}))
                }
            }
        if (e.target.name === 'proyecto') {
            if(e.target.value !== ""){
                await ofertaByProyecto(e.target.value)
                    .then(async response => {
                        setOfertas(response.data)
                    })
            }else{
                await setOfertas([]);
            }
            setMostrarTabla(false)
            setOferta('')
            setProyecto(e.target.value)
            if(setTexto){
                setTexto((prev)=>({...prev, proyecto: proyectos.filter(p=>p.id===e.target.value)[0].nombre}))
            }
        }
        if (e.target.name === 'oferta') {
            setOferta(e.target.value)
            setAlertModal('')
            setMostrarTabla(false)
            if(setTexto){
                setTexto((prev)=>({...prev, oferta: e.target.value.nombre}))
            }
        }
    }    
    return (
        <Grid container item xs={12} className={classes.direction}>
            {redirect ? <Redirect push to={{ pathname: '/signin' }} /> : null}
            <CustomContratante titulo="Entidad" classes={classes} value={contratante} handleChange={handleChange} list={contratantes} />
            <CustomProyecto titulo="Proyecto" classes={classes} value={proyecto} handleChange={handleChange} list={proyectos} />
            <CustomOferta titulo="Oferta" classes={classes} handleChange={handleChange} list={ofertas} />
        </Grid>
    )
}