import React from 'react';
import clsx from 'clsx';
import CmtDropdownMenu from '../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../@coremat/CmtAvatar';
import { Box, alpha, makeStyles } from '@material-ui/core';
import AssignmentIndSharpIcon from '@material-ui/icons/AssignmentIndSharp';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import { useDispatch } from 'react-redux';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
}));

const auth = sessionStorage.getItem('token');

const actionsList = [];

if (!auth) {
  actionsList.push({
    icon: <AssignmentIndSharpIcon />,
    label: "Iniciar sesión",
  });
} else {
  actionsList.push({
    icon: <ExitToAppIcon />,
    label: "Cerrar sesión",
  });
}

const UserDropDown = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const onItemClick = item => {
    if (item.label === 'Iniciar sesión') {
      window.location.href="/signin";
    }else if (item.label === 'Cerrar sesión') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      window.location.href="/signin";
    }
  };

  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <CmtDropdownMenu
        onItemClick={onItemClick}
        TriggerComponent={<CmtAvatar size="small" src={'/images/avatar/avatar13.jpg'} />}
        items={actionsList}
      />
    </Box>
  );
};

export default UserDropDown;
